<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des gateways</h3>
        <b-row>
            <b-form class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group id="input-id" label="ID :" label-for="id">
                    <b-form-input
                        id="id"
                        v-model="filter.id"
                        @keydown.enter="applyFilter"
                        class="id-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-name" label="Nom :" label-for="name">
                    <b-form-input
                        id="name"
                        v-model="filter.name"
                        @keydown.enter="applyFilter"
                        class="name-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <div>
                    <b-button-group class="filter-form-input">
                        <b-button @click="clearFilter" variant="info">
                            <b-icon-x-circle-fill />
                        </b-button>
                        <b-button variant="info" @click="applyFilter">
                            Filtrer
                        </b-button>
                    </b-button-group>
                </div>
            </b-form>
            <div class="text-right col-xs-12 col-md-6">
                <b-button
                    variant="success"
                    @click="openNewModal"
                    class="button-form-input ml-2"
                    >Ajouter
                </b-button>
            </div>
        </b-row>
        <b-row no-gutters>
            <b-table
                id="gateway-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getGateways"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                sticky-header="calc(100vh - 380px)"
                striped
                sort-icon-left
            >
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openUpdateModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="warning"
                        @click="confirmDelete(row.item)"
                    >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalGateway"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-row>
                        <b-form-group
                            class="col-8"
                            id="input-name"
                            label="Nom :"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            class="col-4"
                            id="input-isActive"
                            label="Actif :"
                            label-for="isActive"
                        >
                            <b-form-checkbox
                                id="isActive"
                                v-model="form.isActive"
                                name="check-button"
                                switch
                            >
                                {{ form.isActive ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-xs-12 col-md-4"
                            id="input-account"
                            label="Compte :"
                            label-for="account"
                        >
                            <b-form-select
                                v-model="form.account.id"
                                :options="accounts"
                                value-field="id"
                                text-field="name"
                                @change="changeAccount"
                            ></b-form-select>
                        </b-form-group>

                        <b-form-group
                            class="col-xs-12 col-md-8"
                            id="input-park"
                            label="Parc :"
                            label-for="park"
                        >
                            <b-form-select
                                id="park"
                                v-model="form.park.id"
                                :options="parks"
                                value-field="id"
                                text-field="name"
                                required
                            ></b-form-select>
                        </b-form-group>
                    </b-row>

                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-version"
                            label="Version :"
                            label-for="version"
                        >
                            <b-form-input
                                id="version"
                                v-model="form.version"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            class="col-6"
                            id="input-target-version"
                            label="Version Cible :"
                            label-for="target-version"
                        >
                            <b-form-input
                                id="target-version"
                                v-model="form.targetVersion"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>

                    <b-form-group
                        id="input-traceability"
                        label="Traçabilité :"
                        label-for="traceability"
                    >
                        <b-form-textarea
                            id="traceability"
                            v-model="form.traceability"
                            rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $bvModal.hide('modalGateway');
                            applyFilter();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewGatewayForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateGatewayForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import AccountApi from "@/apis/AdminAccount.js";
import ParkApi from "@/apis/AdminPark.js";
import GatewayApi from "@/apis/AdminGateway.js";
export default {
    data() {
        return {
            accounts: [],
            parks: [],
            gateways: [],
            form: {
                id: 0,
                name: "",
                isActive: true,
                version: 0,
                targetVersion: 0,
                traceability: "",
                park: { id: 0, name: "" },
                account: { id: 0, name: "" },
            },
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "name", sortable: true, label: "Nom" },
                {
                    key: "lastPolling",
                    sortable: true,
                    label: "Dernière présence",
                    formatter: (value) => {
                        return this.$moment(value).format(
                            "DD/MM/YYYY HH:mm:ss"
                        );
                    },
                },
                { key: "version", sortable: true, label: "Version" },
                {
                    key: "targetVersion",
                    sortable: true,
                    label: "Version Cible",
                },

                { key: "park.name", label: "Parc", sortable: false },
                { key: "account.name", label: "Account", sortable: false },
                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
            filter: { name: "", id: null },
            previousOrder: {},
        };
    },
    computed: {},
    async beforeMount() {
        await this.getAccounts();
    },
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "gateway-table");
        },
        clearFilter() {
            this.filter = { name: "", id: null };
        },
        async getGateways(ctx) {
            try {
                let filter = {};
                if (this.filter.name) filter.name = this.filter.name;
                if (this.filter.id) filter.id = this.filter.id;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const gateways = await GatewayApi.findGateways(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );
                if (gateways.status === 201) {
                    this.gateways = gateways.data.items;
                    this.totalRows = gateways.data.meta.totalItems;
                    this.currentPage = gateways.data.meta.currentPage;
                    return gateways.data.items;
                } else {
                    this.toast("Erreur!", gateways.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getParks() {
            try {
                const parks = await ParkApi.getAccountParks(
                    this.form.account.id
                );
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getAccounts() {
            try {
                const accounts = await AccountApi.getAccounts();
                this.accounts = accounts.data;
            } catch (err) {
                this.toast("Erreur!", err, true);
            }
        },
        async changeAccount() {
            await this.getParks();
            this.form.park.id = 0;
            this.form.park.name = "";
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openNewModal() {
            this.modalTitle = "Nouvelle Gateway";
            this.form = {
                id: 0,
                name: "Gateway",
                isActive: true,
                version: 0,
                targetVersion: 0,
                traceability: "",
                park: { id: 0, name: "" },
                account: { id: 0, name: "" },
            };
            this.$bvModal.show("modalGateway");
        },
        async openUpdateModal(gateway) {
            console.log(gateway);

            this.form = gateway;
            await this.getParks();
            this.modalTitle = "Editer Gateway";
            this.$bvModal.show("modalGateway");
        },
        async confirmDelete(gateway) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression de la gateway : " +
                    gateway.name,
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await GatewayApi.deleteGateway(gateway.id);
                    this.toast("Message", "Gateway supprimée.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }

                this.$root.$emit("bv::refresh::table", "gateway-table");
            }
        },

        async submitNewGatewayForm() {
            if (
                this.form.name &&
                this.form.version != null &&
                this.form.targetVersion != null
            ) {
                try {
                    this.form.parkId = this.form.park.id;
                    this.form.accountId = this.form.account.id;
                    const response = await GatewayApi.createGateway(this.form);
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$root.$emit("bv::refresh::table", "gateway-table");
                        this.$bvModal.hide("modalGateway");
                        this.toast("Message", "Gateway créée !", false);
                        this.form = {
                            id: 0,
                            name: "",
                            isActive: true,
                            version: 0,
                            targetVersion: 0,
                            traceability: "",
                            park: { id: 0, name: "" },
                            account: { id: 0, name: "" },
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom', 'version, 'version cible' sont obligatoires",
                    true
                );
            }
        },

        async submitUpdateGatewayForm() {
            if (
                this.form.name &&
                this.form.version != null &&
                this.form.targetVersion != null
            ) {
                try {
                    this.form.parkId = this.form.park.id;
                    this.form.accountId = this.form.account.id;
                    const response = await GatewayApi.updateGateway(this.form);

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalGateway");
                        this.toast("Message", "Gateway modifiée !", false);
                        this.$root.$emit("bv::refresh::table", "gateway-table"); // TODO not necessary
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'Nom' 'Version' et 'Version Cible' sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
