import Api from "./Api";
export default {
    login(form) {
        return Api().post("/auth/login", form);
    },
    auth() {
        return Api().get("/profile");
    },
    saveUser(id, user) {
        return Api().patch("/users/" + id, user);
    },
    resetPassword(id, pass) {
        return Api().patch("/users/" + id, pass);
    },
    getAccounts() {
        return Api().get("/accounts/list");
    },
};
