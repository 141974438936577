<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2 font-italic">Logs</h3>
        <b-row>
            <b-col md="10" xs="12" class="d-flex flex-row toggle-wrap">
                <b-form-group id="park-name" label="Parc :" label-for="park">
                    <b-form-select
                        v-model="filter.park"
                        :options="parks"
                        value-field="id"
                        text-field="name"
                        id="park"
                        class="log-list-form-input mr-2"
                    ></b-form-select>
                </b-form-group>
                <b-form-group id="rack-name" label="Arceau :" label-for="rack">
                    <b-form-input
                        id="rack"
                        v-model="filter.rack"
                        class="log-text-form-input mr-2"
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="code-name" label="Code :" label-for="code">
                    <b-form-input
                        id="code"
                        v-model="filter.code"
                        class="code-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-hideStatusUpdate"
                    label="Status Updates :"
                    label-for="hideStatusUpdate"
                >
                    <b-form-checkbox
                        id="hideStatusUpdate"
                        v-model="filter.text"
                        name="check-button"
                        switch
                    >
                        {{ filter.text ? "Oui" : "Non" }}
                    </b-form-checkbox>
                </b-form-group>
                <b-button-group class="filter-form-input">
                    <b-button @click="clearFilter">
                        <b-icon-x-circle-fill />
                    </b-button>
                    <b-button @click="applyFilter">
                        Filtrer
                    </b-button>
                </b-button-group>
            </b-col>
            <b-col md="2" xs="12" class="text-right">
                <b-button
                    @click="showMore = !showMore"
                    class="filter-form-input mr-2"
                >
                    <span v-if="showMore">Simple</span>
                    <span v-else>Détaillé</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row no-gutters>
            <b-table
                id="log-table"
                class="my-3 mb-4 w-100"
                :style="{ 'max-width': maxWidth }"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getLogs"
                :fields="
                    (showMore ? fields.concat(moreFields) : fields).concat(
                        actionFields
                    )
                "
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive
                striped
                sort-icon-left
                sticky-header="calc(100vh - 380px)"
            >
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        size="sm"
                        @click="openLogModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-exclamation-circle />
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <b-form-group
                class="d-flex flex-row flex-nowrap"
                id="input-pagenumber"
                label="Aller à la page :"
                label-for="page-number"
                label-class="inline-label-text"
                ><b-form-input
                    id="page-number"
                    type="number"
                    v-model="manualPage"
                    class="page-form-input"
                    @keydown.enter="currentPage = manualPage"
                    @change="currentPage = manualPage"
                ></b-form-input
            ></b-form-group>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalLog"
            :title="modalTitle"
            size="lg"
            centered
            hide-backdrop
            content-class="shadow-lg"
            :header-class="'modal-header-' + $store.getters.account"
        >
            <b-container fluid>
                <table stripped id="log-table" class="w-100">
                    <tr>
                        <td>Date:</td>
                        <td>
                            {{
                                log.created_at
                                    | moment(" DD/MM/YYYY  HH:mm:ss.SSS")
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>Log:</td>
                        <td>{{ log.text }}</td>
                    </tr>
                    <tr>
                        <td>Requête:</td>
                        <td>{{ log.request }}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{{ log.error }}</td>
                    </tr>
                    <tr>
                        <td>Compte:</td>
                        <td v-if="log.account">
                            {{ log.account.name }} ({{ log.account.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Parc:</td>
                        <td v-if="log.park">
                            {{ log.park.name }} ({{ log.park.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Arceau:</td>
                        <td v-if="log.rack">
                            {{ log.rack.name }} ({{ log.rack.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Serrure:</td>
                        <td v-if="log.lock">
                            {{ log.lock.name }} ({{ log.lock.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Gateway:</td>
                        <td v-if="log.gateway">
                            {{ log.gateway.name }} ({{ log.gateway.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Code:</td>
                        <td v-if="log.code">
                            {{ log.code.codeNumber }} ({{ log.code.id }}) ({{
                                log.code.isRfid ? "RFID" : "Borne"
                            }}) -
                            {{
                                log.code.arrivalDate
                                    | moment(" DD/MM/YYYY  HH:mm")
                            }}
                            -
                            {{
                                log.code.departureDate
                                    | moment(" DD/MM/YYYY  HH:mm")
                            }}
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                </table>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button @click="$bvModal.hide('modalLog')"
                        >Fermer</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import ParkApi from "@/apis/AdminPark.js";
import LogApi from "@/apis/AdminLog.js";

export default {
    data() {
        return {
            log: {},
            logs: [],
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                {
                    key: "created_at",
                    sortable: true,
                    label: "Date",
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                },
                { key: "text", sortable: true, label: "Log" },
                { key: "request", sortable: true, label: "Request" },
                {
                    key: "error",
                    label: "Status",
                    formatter: (value) => {
                        return value ? "KO: " + value : "OK";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
            ],
            moreFields: [
                {
                    key: "park.name",
                    label: "Parc",
                    filterByFormatted: true,
                },
                {
                    key: "rack.name",
                    label: "Arceau",
                    filterByFormatted: true,
                },
                /* {
                    key: "lock.name",
                    label: "Serrure",
                    filterByFormatted: true,
                }, */
                {
                    key: "gateway.name",
                    label: "Gateway",
                    filterByFormatted: true,
                },
                {
                    key: "code",
                    label: "Code",
                    formatter: (value) => {
                        return value
                            ? value["codeNumber"] + " (" + value["id"] + ")"
                            : "";
                    },
                    filterByFormatted: true,
                },
            ],
            actionFields: [
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 50,
            totalRows: null,
            filter: { text: false },
            previousOrder: {},
            showMore: false,
            account: {},
            parks: [],
            windowWidth: window.innerWidth,
            manualPage: null,
        };
    },
    computed: {
        maxWidth() {
            return this.windowWidth > 575
                ? `${this.windowWidth - 262}px`
                : "100%";
        },
    },
    mounted() {
        this.account = localStorage.getItem("account");
        this.getParks();
        window.addEventListener("resize", this.widthHandler);
    },
    methods: {
        applyFilter() {
            this.currentPage = 1;
            this.$root.$emit("bv::refresh::table", "log-table");

            //this.toast("En cours...", "En cours de développement", false);
        },
        async getLogs(ctx) {
            try {
                ["rack", "park", "code"].forEach((prop) => {
                    if (this.filter[prop] === "") delete this.filter[prop];
                });
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;

                const logs = await LogApi.findAccountLogs(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: this.filter,
                        order,
                    },
                    localStorage.getItem("account")
                );
                if (logs.status === 201) {
                    this.logs = logs.data.items;
                    this.totalRows = logs.data.meta.totalItems;
                    this.currentPage = logs.data.meta.currentPage;
                    return logs.data.items;
                } else {
                    this.toast("Erreur!", logs.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getParks() {
            try {
                const parks = await ParkApi.getAccountNameParks(
                    localStorage.getItem("account")
                );
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },

        clearFilter() {
            this.filter = { text: false };
        },
        async openLogModal(log) {
            this.log = log;
            this.modalTitle = "Consulter Log: " + log.id;
            this.$bvModal.show("modalLog");
        },
        widthHandler() {
            this.windowWidth = window.innerWidth;
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.widthHandler);
    },
};
</script>
