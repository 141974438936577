<template>
    <b-container fluid :class="'nobar-h100 body-' + account">
        <b-row fluid class="justify-content-center">
            <div class="card login-form col-sm-12 col-md-4 mt-5 mx-2">
                <div class="card-body p-3">
                    <div class="form-group  mx-3 pt-3">
                        <label for="email">Adresse e-mail</label>
                        <div>
                            <input
                                id="email"
                                type="email"
                                v-model="form.username"
                                class="form-control"
                                required
                                autofocus
                            />
                            <span class="invalid-feedback" role="alert"></span>
                        </div>
                    </div>
                    <div class="form-group  mx-3">
                        <label for="password">Mot de passe</label>
                        <div>
                            <input
                                v-on:keyup.enter="login"
                                id="password"
                                type="password"
                                v-model="form.password"
                                class="form-control"
                                required
                            />
                            <span class="invalid-feedback" role="alert"></span>
                        </div>
                    </div>
                    <div class="form-group text-center pt-3">
                        <button
                            type="submit"
                            @click.prevent="login"
                            class="btn"
                        >
                            Connexion
                        </button>
                    </div>
                </div>
            </div>
        </b-row>
    </b-container>
</template>

<script>
import UserApi from "../apis/User";
export default {
    data: () => ({
        errors: "",
        form: {
            username: "",
            password: "",
            device_name: "browser",
            account: "",
        },
    }),
    async beforeCreate() {
        const account = this.$route.params.account;
        const accountList = await UserApi.getAccounts();
        //console.log("Login on account: ", account, accountList);
        //TODO getAccounts
        if (!accountList.data.includes(account))
            this.$router.push({ name: "NotFound" });
    },
    mounted() {
        this.form.account = this.account;
    },
    computed: {
        account() {
            let account = this.$route.path.split("/")[1];
            return account;
        },
    },
    methods: {
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async login() {
            try {
                this.errors = "logging in...";
                const response = await UserApi.login(this.form);

                //console.log(response);

                this.$root.$emit("login", true);
                localStorage.setItem("token", response.data.access_token);
                localStorage.setItem("account", this.form.account);

                this.$store.state.access_token = response.data.access_token;
                this.$store.state.account = this.form.account;

                const auth = await UserApi.auth();
                this.$store.state.user = auth.data;

                this.$store.state.accountId = auth.data.account.id;
                localStorage.setItem("accountId", auth.data.account.id);

                if (auth.data.role === "admin") {
                    localStorage.setItem("status", auth.data.role);
                    this.$store.state.status = auth.data.role;
                }
                this.$router.push("/" + this.form.account);
            } catch (error) {
                this.errors = "error: " + error;
                if (error.response.status) {
                    this.toast(
                        "Erreur!",
                        "Mot de passe ou email incorrect",
                        true
                    );
                }
            }
        },
    },
};
</script>
