<template>
    <div class="bg-light h-100">
        <NavBar
            v-if="visible"
            :isLoggedIn="this.$store.getters.loggedIn"
            :isAdmin="this.$store.getters.isAdmin"
            :account="account"
        /><LoginBar v-else />
        <router-view :account="account" />
        <!-- <NavFooter v-if="visible" class="nav-footer" /> -->
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import LoginBar from "@/components/LoginBar.vue";
//import NavFooter from "@/components/NavFooter.vue";
export default {
    components: {
        NavBar,
        LoginBar,
        //NavFooter,
    },
    data: () => ({
        account: "",
    }),
    mounted() {
        this.account = this.$route.path.split("/")[1];
        this.$root.$on("login", () => {
            this.isLoggedIn = true;
            console.log("loggedin event");
        });
        this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
            if (modalId == "modalLock")
                this.$store.state.foundAndDisplayed = true;
        });
    },

    computed: {
        visible() {
            return !["Home", "NotFound", "Login", "SuperLogin"].includes(
                this.$route.name
            );
        },
    },
};
</script>
