<template>
  <b-container fluid class="w-100 sidebar-h100 toggle-wrap m-0 p-0">
    <div
      id="sidebar"
      class="text-center side-bar"
      :class="'side-bar-' + this.account"
    >
      <b-nav pill vertical>
        <b-nav-item :to="{ name: 'SuperDashboard' }">
          <b-icon-house class="left-icon" />
          Dashboard
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminJournal' }">
          <b-icon-journal class="left-icon" />
          Journal
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminLog' }">
          <b-icon-binoculars class="left-icon" />
          Logs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminCode' }">
          <b-icon-key class="left-icon" />
          Codes
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminUser' }">
          <b-icon-person class="left-icon" />
          Utilisateurs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminAccount' }">
          <b-icon-globe class="left-icon" />
          Comptes
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminPark' }">
          <b-icon-share class="left-icon" />
          Parcs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminPrice' }">
          <b-icon-cash class="left-icon" />
          Tarifs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminRack' }">
          <b-icon-option class="left-icon" />
          Arceaux
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminLock' }">
          <b-icon-unlock class="left-icon" />
          Serrures
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminGateway' }">
          <b-icon-wifi class="left-icon" />
          Gateways
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminTerminal' }">
          <b-icon-tablet class="left-icon" />
          Bornes
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminFirmware' }">
          <b-icon-puzzle class="left-icon" />
          Firmwares
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminRfid' }">
          <b-icon-broadcast class="left-icon" />
          Serveurs Rfid
        </b-nav-item>
      </b-nav>
    </div>
    <div
      id="topmenubar"
      :class="'text-center topmenu-bar side-bar-' + this.account"
    >
      <b-nav horizontal>
        <b-nav-item :to="{ name: 'SuperDashboard' }">
          <b-icon-house class="left-icon" />
          Dashboard
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminJournal' }">
          <b-icon-journal class="left-icon" />
          Journal
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminLog' }">
          <b-icon-binoculars class="left-icon" />
          Logs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminCode' }">
          <b-icon-key class="left-icon" />
          Codes
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminUser' }">
          <b-icon-person class="left-icon" />
          Utilisateurs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminAccount' }">
          <b-icon-globe class="left-icon" />
          Comptes
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminPark' }">
          <b-icon-share class="left-icon" />
          Parcs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminPrice' }">
          <b-icon-cash class="left-icon" />
          Tarifs
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminRack' }">
          <b-icon-option class="left-icon" />
          Arceaux
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminLock' }">
          <b-icon-unlock class="left-icon" />
          Serrures
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminGateway' }">
          <b-icon-wifi class="left-icon" />
          Gateways
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminTerminal' }">
          <b-icon-tablet class="left-icon" />
          Bornes
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminFirmware' }">
          <b-icon-puzzle class="left-icon" />
          Firmwares
        </b-nav-item>
        <b-nav-item :to="{ name: 'SuperAdminRfid' }">
          <b-icon-broadcast class="left-icon" />
          Rfid
        </b-nav-item>
      </b-nav>
    </div>
    <router-view class="flex-fill"></router-view>
  </b-container>
</template>

<script>
import User from "../apis/User";
export default {
  props: {
    account: String,
  },

  data() {
    return {
      isAdmin: false,
    };
  },
  async beforeMount() {
    try {
      const user = await User.auth();
      this.user = user;
      //console.log("auth: ", user);
    } catch (error) {
      let routeAccount = this.$route.path.split("/")[1];
      console.log(error.message, routeAccount);
      let account = localStorage.getItem("account")
        ? localStorage.getItem("account")
        : routeAccount;
      this.$router.push("/" + account + "/login");
    }
  },
  mounted() {
    this.isAdmin = this.$store.state.user
      ? this.$store.state.user.role == "admin"
      : false;
  },
};
</script>
