import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

import datePicker from "vue-bootstrap-datetimepicker";
import VueMoment from "vue-moment";
import moment from "moment";
import i18n from "./i18n";
import "leaflet/dist/leaflet.css";

//import VueHtmlToPaper from "vue-html-to-paper";

require("moment/locale/fr");

Vue.use(VueMoment, {
    moment,
});

Vue.filter("moment", function(value, format) {
    if (
        value === null ||
        value === undefined ||
        value == "" ||
        format === undefined
    ) {
        return "";
    }
    if (format === "from") {
        return moment(value).fromNow();
    }
    return moment(value).format(format);
});

//const account = this.$route.path.split("/")[1];
//vérifie le statut de connexion avant de rediriger vers une route

router.beforeEach((to, from, next) => {
    const loginPath = `/${store.state.account}/login`;
    //console.log(from);
    if (to.matched.some((record) => record.meta.requireAdmin)) {
        //console.log("AA");
        if (
            !(store.getters.isAdmin && store.getters.loggedIn) &&
            to.path !== loginPath
        ) {
            next({
                path: loginPath,
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else if (to.matched.some((record) => record.meta.requireAuth)) {
        //console.log("BB");
        if (!store.getters.loggedIn && to.path !== loginPath) {
            next({
                path: loginPath,
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else if (to.matched.some((record) => record.meta.requireSuperAdmin)) {
        //console.log("CC");
        /* console.log(
            store.getters.loggedIn,
            store.getters.account,
            store.getters.isAdmin
        ); */
        if (
            !(
                store.getters.loggedIn &&
                store.getters.account == "arceau" &&
                store.getters.isAdmin &&
                to.path !== loginPath
            )
        ) {
            next({
                path: loginPath,
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(datePicker);

/* Vue.use(VueHtmlToPaper, printOptions);
const printOptions = {
    //name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "/public/styles.css",
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
};
 */
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
