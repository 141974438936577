import Api from "./Api";
export default {
    findCodes(paginationOptions, findOptions) {
        return Api().post(
            "/codes/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getCode(id) {
        return Api().get("/codes/admin/" + id);
    },
    getCodes() {
        return Api().get("/codes/admin");
    },
    createCode(payload) {
        return Api().post("/codes/admin", payload);
    },
    updateCode(payload) {
        return Api().patch("/codes/admin/" + payload.id, payload);
    },
    deleteCode(id) {
        return Api().delete("/codes/admin/" + id);
    },

    clearAlert(lockId) {
        return Api().get("/codes/clear/" + lockId);
    },
    activateCode(id) {
        return Api().get("/codes/activate/" + id);
    },
    deactivateCode(id) {
        return Api().get("/codes/deactivate/" + id);
    },
    linkCode(codeId, rackId) {
        return Api().get("/codes/link/" + codeId + "/rack/" + rackId);
    },
    unlinkCode(codeId, rackId) {
        return Api().get("/codes/unlink/" + codeId + "/rack/" + rackId);
    },
    extendCode(codeId, payload) {
        return Api().post("/codes/extend/" + codeId, payload);
    },
    getParkFreeCodes(parkId) {
        return Api().get("/codes/park/" + parkId + "/free");
    },
    setAlwaysOpenUntil(lockId) {
        return Api().get("/codes/open/" + lockId);
    },

    // stats
    statsTypes(account, parkId, sinceDate) {
        return Api().get(
            `/codes/stats/types/${account}/${parkId}/${sinceDate}`
        );
    },
    statsData(account, parkId, sinceDate) {
        return Api().get(`/codes/stats/data/${account}/${parkId}/${sinceDate}`);
    },
    getAccessFailuresCount(account, parkId, sinceDate) {
        return Api().get(`/codes/failcount/${account}/${parkId}/${sinceDate}`);
    },
    getAccessFailures(account, parkId, sinceDate, page, rows) {
        return Api().get(
            `/codes/failures/${account}/${parkId}/${sinceDate}?page=${page}&rows=${rows}`
        );
    },
    getOccupancy(account, parkId, sinceDate) {
        return Api().get(`/codes/occupancy/${account}/${parkId}/${sinceDate}`);
    },
};
