import Api from "./Api";
export default {
  getWeekOccupancy(account, parkId, sinceDate) {
    return Api().get(`/stats/occupancy/${account}/${parkId}/${sinceDate}`);
  },

  getCurrentOccupancy(account, parkId) {
    return Api().get(`/stats/occupancy/${account}/${parkId}`);
  },
};
