<template>
    <b-container fluid class="p-3">
        <h3 class="mt-2">Gestion des Tarifs</h3>

        <div class="d-flex flex-wrap justify-content-between p-0  m-2">
            <b-form-group
                id="input-accunt"
                label="Compte :"
                label-for="account"
                class="mr-2"
                ><b-form-select
                    v-model="account.name"
                    :options="accounts"
                    value-field="name"
                    text-field="name"
                    @change="onChangeAccount"
                    value="Compte..."
                    id="account"
                    class="account-form-input"
                ></b-form-select
            ></b-form-group>
            <b-form-group
                id="input-park"
                label="Parc :"
                label-for="park"
                class="mr-2"
            >
                <b-form-select
                    id="park"
                    v-model="park"
                    :options="parks"
                    value-field="id"
                    text-field="name"
                    class="name-form-input"
                    @change="onChangePark"
                ></b-form-select>
            </b-form-group>
            <div class="pt-4 flex-grow-1 text-right">
                <b-button
                    class="mt-2"
                    variant="success"
                    @click="openNewModal"
                    :disabled="parkSelected"
                    >Ajouter un tarif
                </b-button>
            </div>
        </div>

        <b-table
            class="m-2 table-h100"
            :items="prices"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            responsive="sm"
            striped
            sort-icon-left
            sticky-header="calc(100vh - 380px)"
        >
            <template #cell(actions)="row" class="p-0">
                <b-button
                    variant="info"
                    size="sm"
                    @click="openUpdateModal(row.item)"
                    class="ml-1"
                >
                    <b-icon-pencil></b-icon-pencil>
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="warning"
                    @click="confirmDelete(row.item)"
                >
                    <b-icon-trash></b-icon-trash>
                </b-button>
            </template>
        </b-table>

        <b-modal
            id="modalPrice"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
            p
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Forfait :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-duration"
                        label="Durée (minutes):"
                        label-for="duration"
                    >
                        <b-form-input
                            id="duration"
                            v-model="form.duration"
                            type="number"
                            min="0"
                            step="any"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-price"
                        label="Prix achat (Euros):"
                        label-for="price"
                    >
                        <b-form-input
                            autocomplete="new-price"
                            id="price"
                            v-model="form.price"
                            type="number"
                            min="0"
                            step="any"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-exceed-price"
                        label="Prix dépassement (Euros):"
                        label-for="exceed-price"
                    >
                        <b-form-input
                            autocomplete="new-exceed-price"
                            id="exceed-price"
                            v-model="form.exceedPrice"
                            type="number"
                            min="0"
                            step="any"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $bvModal.hide('modalPrice');
                            getPrices();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewPriceForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-else
                        @click="submitUpdatePriceForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import PriceApi from "../../apis/AdminPrice.js";
import AccountApi from "@/apis/AdminAccount.js";
import ParkApi from "@/apis/AdminPark.js";

export default {
    data() {
        return {
            prices: [],
            price: {},
            form: {
                id: 0,
                name: "",
                duration: "",
                price: "",
                exceedPrice: "",
            },
            modalTitle: "",
            accounts: [],
            parks: [],
            account: {},
            park: null,
            sortBy: "duration",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true, label: "Forfait" },
                { key: "duration", sortable: true, label: "Durée" },
                { key: "price", sortable: true, label: "Prix Achat" },
                {
                    key: "exceedPrice",
                    sortable: true,
                    label: "Prix Dépassement",
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
        };
    },
    mounted() {
        this.getPrices();
        this.getAccounts();
    },
    computed: {
        parkSelected() {
            return !this.park;
        },
    },
    methods: {
        onChangeAccount() {
            this.park = null;
            this.prices = [];
            this.getParks();
        },
        onChangePark() {
            this.getPrices();
        },
        async getAccounts() {
            try {
                const accounts = await AccountApi.getAccounts();

                if (accounts.status === 200) {
                    this.accounts = accounts.data;
                } else {
                    this.toast("Erreur!", accounts.statusText, true);
                }
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
        },
        async getParks() {
            try {
                if (this.account.name) {
                    const parks = await ParkApi.getAccountNameParks(
                        this.account.name
                    );
                    this.parks = parks.data;
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getPrices() {
            if (this.park) {
                try {
                    const prices = await PriceApi.getParkPrices(this.park);

                    if (prices.status === 200 || prices.status === 304) {
                        this.prices = prices.data;
                    } else {
                        this.toast("Erreur!", prices.statusText, true);
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            }
        },
        openNewModal() {
            this.modalTitle = "Nouveau tarif";
            this.form = {
                id: 0,
                name: "",
                duration: "",
                price: "",
                exceedPrice: "",
            };
            this.$bvModal.show("modalPrice");
        },
        openUpdateModal(price) {
            this.modalTitle = "Editer tarif";
            this.form = price;
            this.$bvModal.show("modalPrice");
        },
        async confirmDelete(price) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression du prix : " +
                    price.name +
                    " (" +
                    price.price +
                    ")",
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await PriceApi.deletePrice(price.id);
                    this.toast("Message", "Tarif supprimé !", false);
                    this.getPrices();
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async submitUpdatePriceForm() {
            if (
                this.form.name &&
                this.form.duration &&
                this.form.price &&
                this.form.exceedPrice &&
                this.account.name &&
                this.park
            ) {
                try {
                    await PriceApi.updatePrice(this.park, this.form);
                    this.$bvModal.hide("modalPrice");
                    this.toast("Message", "Tarif modifié !", false);
                    this.getPrices();
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },
        async submitNewPriceForm() {
            if (
                this.form.name &&
                this.form.duration &&
                this.form.price &&
                this.form.exceedPrice &&
                this.account.name &&
                this.park
            ) {
                try {
                    const response = await PriceApi.createPrice(
                        this.park,
                        this.form
                    );
                    if (response.data.error) {
                        this.toast(
                            "Erreur!",
                            response.data.error.message,
                            true
                        );
                    } else {
                        this.getPrices();
                        this.$bvModal.hide("modalPrice");
                        this.toast("Message", "Tarif créé !", false);
                        this.form = {
                            id: 0,
                            name: "",
                            duration: "",
                            price: "",
                            exceedPrice: "",
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },

        async deletePrice(priceId) {
            try {
                await PriceApi.deletePrice(priceId);
                let deletedPriceIndex = this.prices.findIndex(
                    (deleted) => deleted.id == priceId
                );
                this.prices.splice(deletedPriceIndex, 1);
            } catch (error) {
                console.error("Error from deletePrice :", error);
            }
        },
    },
};
</script>
