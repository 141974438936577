<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des serrures</h3>
        <b-row>
            <b-form class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group id="input-id" label="ID :" label-for="id">
                    <b-form-input
                        id="id"
                        @keydown.enter="applyFilter"
                        v-model="$store.state.findLockId"
                        class="id-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-name" label="Nom :" label-for="name">
                    <b-form-input
                        id="name"
                        v-model="filter.name"
                        @keydown.enter="applyFilter"
                        class="name-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <div>
                    <b-button-group class="filter-form-input">
                        <b-button @click="clearFilter" variant="info">
                            <b-icon-x-circle-fill />
                        </b-button>
                        <b-button variant="info" @click="applyFilter">
                            Filtrer
                        </b-button>
                    </b-button-group>
                </div>
            </b-form>
            <div class="text-right col-xs-12 col-md-6">
                <b-button
                    variant="success"
                    @click="openNewModal"
                    class="button-form-input ml-2"
                    >Ajouter
                </b-button>
            </div>
        </b-row>
        <b-row no-gutters>
            <b-table
                id="lock-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getLocks"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                striped
                sticky-header="calc(100vh - 380px)"
                sort-icon-left
            >
                <template #cell(rack)="row">
                    <span v-if="row.item.rack">
                        {{ row.item.rack.name }} ({{ row.item.rack.id }})
                    </span>
                </template>
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openUpdateModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="warning"
                        @click="confirmDelete(row.item)"
                    >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalLock"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Nom :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-description"
                        label="Description :"
                        label-for="description"
                    >
                        <b-form-input
                            id="description"
                            v-model="form.description"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-rack"
                        label="Arceau :"
                        label-for="rack"
                        ><b-input-group>
                            <b-form-select
                                id="rack"
                                v-model="form.rack.id"
                                :options="racks"
                                value-field="id"
                                text-field="name"
                            ></b-form-select>
                            <template #append>
                                <b-button
                                    @click="form.rack = { id: 0, name: '' }"
                                    variant="warning"
                                    ><b-icon-eject
                                /></b-button>
                            </template>
                        </b-input-group>
                    </b-form-group>
                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-closed"
                            label="Fermée :"
                            label-for="closed"
                        >
                            <b-form-checkbox
                                id="closed"
                                v-model="form.closed"
                                name="check-button"
                                switch
                                disabled
                            >
                                {{ form.closed ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group
                            class="col-6"
                            id="input-locked"
                            label="Verrouillée :"
                            label-for="locked"
                        >
                            <b-form-checkbox
                                id="locked"
                                v-model="form.locked"
                                name="check-button"
                                switch
                                disabled
                            >
                                {{ form.locked ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>

                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-reachable"
                            label="Disponible :"
                            label-for="reachable"
                        >
                            <b-form-checkbox
                                id="reachable"
                                v-model="form.reachable"
                                name="check-button"
                                switch
                            >
                                {{ form.reachable ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-isActive"
                            label="Actif :"
                            label-for="isActive"
                        >
                            <b-form-checkbox
                                id="isActive"
                                v-model="form.isActive"
                                name="check-button"
                                switch
                            >
                                {{ form.isActive ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-version"
                            label="Version :"
                            label-for="version"
                        >
                            <b-form-input
                                id="version"
                                v-model="form.version"
                                required
                                readonly
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-target-version"
                            label="Version Cible :"
                            label-for="target-version"
                        >
                            <b-form-input
                                id="target-version"
                                v-model="form.targetVersion"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-lock-version"
                            label="Lock Version :"
                            label-for="lock-version"
                        >
                            <b-form-input
                                id="lock-version"
                                v-model="form.lockVersion"
                                required
                                readonly
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-target-lock-version"
                            label="Lock Version Cible :"
                            label-for="target-lock-version"
                        >
                            <b-form-input
                                id="target-lock-version"
                                v-model="form.targetLockVersion"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-form-group
                        id="input-traceability"
                        label="Traçabilité :"
                        label-for="traceability"
                    >
                        <b-form-textarea
                            id="traceability"
                            v-model="form.traceability"
                            rows="2"
                        ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $store.state.foundAndDisplayed = true;
                            $bvModal.hide('modalLock');
                            applyFilter();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewLockForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateLockForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import RackApi from "@/apis/AdminRack.js";
import LockApi from "@/apis/AdminLock.js";
export default {
    data() {
        return {
            racks: [],
            locks: [],
            form: {
                id: 0,
                name: "",
                description: "",
                closed: false,
                locked: false,
                reachable: true,
                isActive: true,
                version: 0,
                targetVersion: 0,
                lockVersion: 0,
                targetLockVersion: 0,
                traceability: "",
                rack: { id: 0, name: "" },
            },
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "name", sortable: true, label: "Nom" },
                { key: "description", sortable: true },
                /* {
                    key: "rack.name",
                    label: "Arceau",
                    sortKey: "rack",
                    sortable: false,
                }, */
                {
                    key: "rack",
                    label: "Arceau",
                },
                { key: "version", sortable: true, label: "Version" },
                { key: "lockVersion", sortable: true, label: "Lock Version" },
                { key: "traceability", sortable: true, label: "Traçabilité" },
                /* {
                    key: "closed",
                    label: "Fermée",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "locked",
                    label: "Verrouillée",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                }, */
                {
                    key: "reachable",
                    label: "Disponible",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 50,
            totalRows: null,
            filter: { name: "", id: null },
            previousOrder: {},
            previousRoute: null,
        };
    },
    computed: {},
    created() {
        this.$root.$on("findLockId", () => {
            console.log("emmitted");
        });
    },
    async mounted() {
        this.applyFilter();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.previousRoutePath = from.path;
        });
    },
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "lock-table");
        },
        clearFilter() {
            this.filter = {};
            this.$store.state.findLockId = null;
        },

        async getLocks(ctx) {
            try {
                let filter = {};
                if (this.filter.name) filter.name = this.filter.name;
                //if (this.filter.id) filter.id = this.filter.id;
                if (this.$store.state.findLockId)
                    filter.id = this.$store.state.findLockId;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const locks = await LockApi.findLocks(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );

                if (locks.status === 201) {
                    this.locks = locks.data.items;
                    this.totalRows = locks.data.meta.totalItems;
                    this.currentPage = locks.data.meta.currentPage;
                    if (
                        this.previousRoutePath !=
                            this.$router.currentRoute.path &&
                        this.locks.length == 1 &&
                        !this.$store.state.foundAndDisplayed
                    ) {
                        console.log(
                            this.$router.currentRoute.path,
                            this.previousRoutePath,
                            this.$store.state.foundAndDisplayed
                        );
                        this.previousRoutePath = this.$router.currentRoute.path;
                        this.openUpdateModal(this.locks[0]);
                    }
                    return locks.data.items;
                } else {
                    this.toast("Erreur!", locks.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getFreeRacks() {
            try {
                const racks = await RackApi.getFreeRacks();
                this.racks = racks.data;
                this.racks.map((r) => {
                    r.name = r.name + " (" + r.id + ")";
                });
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openNewModal() {
            await this.getFreeRacks();
            this.modalTitle = "Nouvelle Serrure";
            this.form = {
                id: 0,
                name: "",
                description: "",
                closed: false,
                locked: false,
                reachable: true,
                isActive: true,
                version: 0,
                targetVersion: 0,
                lockVersion: 0,
                targetLockVersion: 0,
                rack: { id: 0, name: "" },
            };
            this.$bvModal.show("modalLock");
        },
        async openUpdateModal(lock) {
            await this.getFreeRacks();

            this.modalTitle = "Editer Serrure " + lock.id;
            this.form = lock;
            if (!this.form.rack) this.form.rack = { id: 0, name: "" };
            else {
                this.racks.unshift(this.form.rack);
            }
            this.$bvModal.show("modalLock");
        },
        async confirmDelete(lock) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression de la serrure : " +
                    lock.name,
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await LockApi.deleteLock(lock.id);
                    this.toast("Message", "Serrure supprimée.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }

                this.$root.$emit("bv::refresh::table", "lock-table");
            }
        },

        async submitNewLockForm() {
            if (this.form.name && this.form.description) {
                try {
                    const response = await LockApi.createLock(this.form);
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$root.$emit("bv::refresh::table", "lock-table");
                        await this.getFreeRacks();
                        this.$bvModal.hide("modalLock");
                        this.toast("Message", "Serrure créée !", false);
                        this.form = {
                            id: 0,
                            name: "",
                            description: "",
                            closed: false,
                            locked: false,
                            reachable: false,
                            isActive: true,
                            version: "",
                            traceability: "",
                            rack: { id: 0, name: "" },
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom' et 'description' sont obligatoires",
                    true
                );
            }
        },

        async submitUpdateLockForm() {
            if (this.form.name && this.form.description) {
                try {
                    const response = await LockApi.updateLock(this.form);

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$store.state.foundAndDisplayed = true;

                        this.$bvModal.hide("modalLock");
                        this.toast("Message", "Serrure modifiée !", false);
                        await this.getFreeRacks();
                        this.$root.$emit("bv::refresh::table", "lock-table"); // TODO not necessary
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom' et 'description' sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
