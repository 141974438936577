import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        access_token: localStorage.getItem("token") || null,
        user: null,
        status: localStorage.getItem("status") || null,
        account: localStorage.getItem("account") || null,
        accountId: localStorage.getItem("accountId") || null,
        search: "",
        collapsed: true,
        occupancyHeight: 200,
        findLockId: null,
        foundAndDisplayed: false,
    },

    mutations: {
        setAccount(state, data) {
            state.account = data;
        },
        setAccountId(state, data) {
            state.accountId = data;
        },
        setToken(state, data) {
            state.access_token = data;
        },
        setStatus(state, data) {
            state.status = data;
        },
        setUser(state, data) {
            state.user = data;
        },
        setSearch(state, data) {
            state.search = data;
        },
        setCollapsed(state, data) {
            state.collapsed = data;
        },
        setOccupancyHeight(state, data) {
            state.occupancyHeight = data;
        },
        setFindLockId(state, data) {
            state.findLockId = data;
        },
    },

    getters: {
        account(state) {
            return state.account;
        },
        accountId(state) {
            return state.accountId;
        },
        loggedIn(state) {
            return state.access_token !== null;
        },
        isAdmin(state) {
            return state.status !== null;
        },
        search(state) {
            return state.search;
        },
        collapsed(state) {
            return state.collapsed;
        },
        occupancyHeight(state) {
            return state.occupancyHeight;
        },
        findLockId(state) {
            return state.findLockId;
        },
    },

    actions: {},
    modules: {},
});
