<template>
    <b-container fluid class="w-100 h100 toggle-wrap m-0 p-0">
        <div
            id="sidebar"
            :class="'text-center side-bar side-bar-' + this.account"
        >
            <b-nav vertical>
                <b-nav-item :to="{ name: 'Dashboard' }">
                    <b-icon-house class="left-icon" />
                    Dashboard
                </b-nav-item>

                <b-nav-item :to="{ name: 'AdminPark' }">
                    <b-icon-share class="left-icon" />
                    Parcs
                </b-nav-item>

                <b-nav-item :to="{ name: 'AdminJournal' }">
                    <b-icon-journal class="left-icon" />
                    Journal
                </b-nav-item>

                <b-nav-item :to="{ name: 'AdminCode' }">
                    <b-icon-key class="left-icon" />
                    Codes
                </b-nav-item>
                <a
                    href="#"
                    v-b-toggle.params-menu
                    class="nav-link"
                    v-if="isAdmin"
                >
                    <b-icon-gear class="left-icon" />Configuration
                    <b-icon-chevron-left class="when-closed right-icon" />
                    <b-icon-chevron-down class="when-opened right-icon" />
                </a>
                <b-collapse
                    id="params-menu"
                    v-if="isAdmin"
                    :visible="configVisible"
                >
                    <b-nav vertical>
                        <b-nav-item :to="{ name: 'AdminUser' }">
                            Utilisateurs
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'AdminPrice' }">
                            Tarifs
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'AdminParams' }">
                            Parcs
                        </b-nav-item>
                        <b-nav-item :to="{ name: 'AdminLog' }">
                            Historique
                        </b-nav-item>
                    </b-nav>
                </b-collapse>
            </b-nav>
        </div>
        <div
            id="topmenubar"
            :class="'text-center topmenu-bar side-bar-' + this.account"
        >
            <b-nav horizontal>
                <b-nav-item :to="{ name: 'Dashboard' }">
                    <b-icon-house class="left-icon" />
                    Dashboard
                </b-nav-item>

                <b-nav-item :to="{ name: 'AdminPark' }">
                    <b-icon-share class="left-icon" />
                    Parcs
                </b-nav-item>

                <b-nav-item :to="{ name: 'AdminJournal' }">
                    <b-icon-journal class="left-icon" />
                    Journal
                </b-nav-item>

                <b-nav-item :to="{ name: 'AdminCode' }">
                    <b-icon-key class="left-icon" />
                    Codes
                </b-nav-item>

                <b-nav-item v-if="isAdmin" :to="{ name: 'AdminUser' }">
                    <b-icon-person class="left-icon" />
                    Utilisateurs
                </b-nav-item>

                <b-nav-item v-if="isAdmin" :to="{ name: 'AdminPrice' }">
                    <b-icon-cash class="left-icon" />
                    Tarifs
                </b-nav-item>

                <b-nav-item v-if="isAdmin" :to="{ name: 'AdminParams' }">
                    <b-icon-gear class="left-icon" />
                    Parcs
                </b-nav-item>
                <b-nav-item v-if="isAdmin" :to="{ name: 'AdminLog' }">
                    <b-icon-binoculars class="left-icon" />
                    Historique
                </b-nav-item>
            </b-nav>
        </div>
        <router-view :account="account"></router-view>
    </b-container>
</template>

<script>
export default {
    props: {
        account: String,
    },
    data() {
        return {
            configVisible: false,
        };
    },
    mounted() {
        this.configVisible = [
            "AdminPrice",
            "AdminParams",
            "AdminUser",
            "AdminLog",
        ].includes(this.$route.name);
    },
    computed: {
        isAdmin() {
            return this.$store.state.user
                ? this.$store.state.user.role == "admin"
                : false;
        },
    },
};
</script>

<style scoped>
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>
