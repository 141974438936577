import Api from "./Api";
export default {
    getUsers() {
        return Api().get("/users/admin");
    },
    getAccountUsers(accountId) {
        return Api().get("/users/admin/account/" + accountId);
    },
    addUser(payload) {
        return Api().post("/users/admin/", payload);
    },
    updateUser(payload) {
        return Api().patch("/users/admin/" + payload.id, payload);
    },
    deleteUser(userId) {
        return Api().delete("/users/admin/" + userId);
    },
};
