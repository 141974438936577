<template>
    <b-container fluid class="content-h100  overflow-auto  p-3">
        Super Dashboard {{ loggedName }} {{ account }}
    </b-container>
</template>

<script>
import User from "../apis/User";
export default {
    data() {
        return {
            //isLoggedIn: true,
            //isAdmin: true,
            user: this.$store.state.user || null,
            langs: ["fr", "en"],
        };
    },
    props: {
        isLoggedIn: Boolean,
        isAdmin: Boolean,
        account: String,
    },
    computed: {
        loggedName() {
            if (this.$store.state.user) {
                return this.$store.state.user.name;
            } else {
                return "User";
            }
        },
    },
    methods: {
        changeLocale(lang) {
            this.$i18n.locale = lang;
        },
        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("status");
            this.$store.commit("setToken", null);
            this.$store.commit("setStatus", null);
            this.$store.commit("setUser", null);
            this.$router.push("/" + this.account + "/login");
        },
        getAuth() {
            this.$root.$on("login", () => {
                this.isLoggedIn = true;
            });
            //this.isLoggedIn = !!localStorage.getItem("token");
            if (this.isLoggedIn) {
                User.auth().then((response) => {
                    this.$store.commit("setUser", response.data);
                    this.$store.state.user = response.data;
                    if (response.data.role === "admin") {
                        localStorage.setItem("status", response.data.role);
                        this.$store.commit("setStatus", response.data.role);
                        this.$store.state.status = response.data.role;
                    }
                });
            }
        },
    },
    async beforeMount() {
        try {
            /* const code = "12ee56";
            const codeValues = [1, 2, 3, 4, "A", "B", "C"];
            var codeStr = "";
            for (var i = 0; i < code.length; i++) {
                codeStr += codeValues[parseInt(code[i], 10) - 1] || "?";
            }
            console.log("Code: " + codeStr) */

            /*

                if (AA.status === 201) {
                    
                } else {
                    this.toast("Erreur!", AA.statusText, true);
                }

                */

            const user = await User.auth();
            this.user = user;
            console.log("auth: ", user);
        } catch (error) {
            console.log(error.message);
            this.$router.push("/" + localStorage.getItem("account") + "/login");
        }
    },
    updated() {
        if (this.$props.isLoggedIn && !this.user) {
            this.getAuth();
        }
    },
};
</script>
