import Api from "./Api";
export default {
    findLogs(paginationOptions, findOptions) {
        return Api().post(
            "/logs/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    findAccountLogs(paginationOptions, findOptions, account) {
        return Api().post(
            "/logs/" +
                account +
                "/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getLog(id) {
        return Api().get("/logs/" + id);
    },
};
