<template>
  <b-container fluid class="content-h100  overflow-auto  p-3">
    <h3 class="mt-2 font-italic">Dashboard</h3>
    <b-row class="text-right mb-4">
      <b-col class="d-flex flex-row flex-wrap justify-content-end ">
        <div>
          <b-form-datepicker
            v-model="sinceDate"
            id="sinceDate"
            @input="handleSinceDate"
            hide-header
            label-help=""
          ></b-form-datepicker>
        </div>
        <div class="ml-4">
          <b-form-select
            id="park"
            v-model="parkId"
            :options="parks"
            value-field="id"
            text-field="name"
            required
            @change="handleParkSelection"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6" xs="12" class="mb-3" order="2" order-md="1">
        <visit-stats
          :account="account"
          :parkId="parkId"
          :sinceDate="sinceDate"
          :key="update"
        ></visit-stats>
      </b-col>
      <b-col md="6" xs="12" class="mb-3" order="1" order-md="2">
        <alerts
          :account="account"
          :parkId="parkId"
          :sinceDate="sinceDate"
          :key="update"
        ></alerts>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="4" xs="12" class="mb-3 px-4">
        <visit-type
          :account="account"
          :parkId="parkId"
          :key="update"
          :sinceDate="sinceDate"
        ></visit-type>
      </b-col>
      <b-col md="8" xs="12" class="mb-3">
        <occupancy
          :account="account"
          :parkId="parkId"
          :key="update"
          :sinceDate="sinceDate"
        ></occupancy>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Occupancy from "../components/widgets/Occupancy.vue";
import VisitType from "../components/widgets/VisitType.vue";
import Alerts from "../components/widgets/Alerts.vue";
import VisitStats from "../components/widgets/VisitStats.vue";
import ParkApi from "@/apis/AdminPark.js";
//import User from "../apis/User";
export default {
  components: { VisitType, VisitStats, Alerts, Occupancy },
  data() {
    return {
      parkId: 0,
      sinceDate: new Date(),
      parks: [],
      account: localStorage.getItem("account"),
      update: 0,
    };
  },
  computed: {
    loggedName() {
      if (this.$store.state.user) {
        return this.$store.state.user.name;
      } else {
        return "User";
      }
    },
  },
  methods: {
    handleParkSelection() {
      this.update += 1;
    },
    handleSinceDate() {
      this.update += 1;
    },
    async getParks() {
      try {
        const parks = await ParkApi.getAccountNameParks(this.account);
        this.parks = [
          {
            id: 0,
            name: "Tous les parcs",
          },
        ].concat(parks.data);
      } catch (err) {
        this.toast("Erreur!", err.message, true);
      }
    },
    toast(title, message, faulty = false) {
      this.$root.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-top-center",
        variant: faulty ? "danger" : "success",
        appendToast: true,
      });
    },
  },
  async mounted() {
    try {
      this.account = this.$store.state.account;
      this.getParks();
    } catch (error) {
      console.log(error.message);
      this.$router.push("/" + localStorage.getItem("account") + "/login");
    }
  },
};
</script>
