import Api from "./Api";
export default {
    findJournals(paginationOptions, findOptions) {
        return Api().post(
            "/journal/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    findAccountJournals(paginationOptions, findOptions, account) {
        return Api().post(
            "/journal/" +
                account +
                "/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getJournal(id) {
        return Api().get("/journal/" + id);
    },
    getRackJournal(rackId, page, rows) {
        return Api().get(`journal/rack/${rackId}?page=${page}&rows=${rows}`);
    },
};
