<template>
    <b-container fluid class="p-0 rack-status">
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
            integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
        />
        <div class="header d-flex flex-row justify-content-between">
            <div>Arceau #{{ rack.id }} <router-link :to="'/'+this.$store.getters.account+'/journal?rack='+rack.name">({{ rack.name }})</router-link></div>
            <div
                v-if="showClose == 1"
                class="mr-2"
                style="cursor:pointer"
                @click="$bvModal.hide('modalJournalRackStatus')"
            >
                <b-icon-x />
            </div>
        </div>
        <rack-lock-status :lock="rack.lock" />
        <div>
            Mise à jour :
            {{ rack.lock.updated | moment("DD/MM/YYYY à HH:mm") }}
        </div>

        <div>
            Parc :
            {{ getCode.park ? getCode.park.name : parkName }}
        </div>
        <div v-if="rack.code">Code : <router-link :to="'/'+this.$store.getters.account+'/journal?code='+getCode.codeNumber">#{{ getCode.codeNumber }}</router-link></div>
        <div v-else class="d-flex flex-row flex-nowrap">
            <span class="mr-1 mt-1 text-nowrap">Code : </span>
            <div v-if="isAttaching" class="ml-3">
                <b-input-group>
                    <b-form-select
                        id="rack"
                        v-model="selectedCodeId"
                        :options="codes"
                        value-field="id"
                        text-field="name"
                    >
                    </b-form-select>
                    <template #append>
                        <b-button-group>
                            <b-button
                                @click="submitAttachForm"
                                :disabled="selectedCodeId == 0"
                            >
                                <b-icon-check-circle-fill />
                            </b-button>
                        </b-button-group>
                    </template>
                </b-input-group>
            </div>
        </div>
        <div>
            Date d'arrivée :
            {{ getCode.arrivalDate | moment("DD/MM/YYYY à HH:mm") }}
        </div>
        <div v-if="!isExtending">
            Date de départ :
            {{ getCode.departureDate | moment("DD/MM/YYYY à HH:mm") }}
        </div>
        <div v-else class="d-flex flex-row flex-nowrap">
            <span class="mr-1 mt-1 text-nowrap">Date de départ :</span>
            <b-input-group
                ><date-picker
                    v-model="departureDate"
                    id="departureDate"
                    :config="options"
                />
                <template #append>
                    <b-button-group>
                        <b-button @click="handleExtendAction">
                            <b-icon-check-circle-fill />
                        </b-button>
                    </b-button-group>
                </template>
            </b-input-group>
        </div>
        <div>Contact: {{ getCode.contact }}</div>
        <div
            v-if="$moment(getCode.departureDate).isBefore()"
            :class="getCode.isRfid ? 'rfid-overdue' : 'overdue'"
        >
            Dépassement: {{ $moment(getCode.departureDate).toNow(true) }}<br />

            {{ getComplement }}
        </div>
        <div v-else class="intime">
            <div v-if="getCode.codeNumber" class="m-0 p-0">
                Temps restant :
                {{ $moment(getCode.departureDate).fromNow(true) }}
            </div>
        </div>
        <div class="footer">
            <div v-if="!reachable" class="text-danger">
                <strong>Arceau à vérifier ! </strong>
                <p>
                    Veuillez vérifier l'état de l'arceau avant d'effacer cette
                    alerte et de décider de la prochaine action.
                </p>
                <b-button variant="danger" @click="clearAlert"
                    >Effacer cette alerte</b-button
                >
            </div>
            <div>
                <b-button rounded @click="handleOpenAction">Ouvrir</b-button>
                <b-button v-if="rack.code" rounded @click="handleDetachAction"
                    >Libérer</b-button
                >
                <b-button
                    v-if="
                        this.rack.code &&
                            $moment(getCode.departureDate).isBefore()
                    "
                    rounded
                    @click="handleExtendButton"
                    >Prolonger</b-button
                >
                <b-button
                    v-if="!rack.code"
                    pill
                    @click="handleAttachAction"
                    class="mx-3 my-0"
                >
                    Associer
                </b-button>
                <b-button @click="handleLog">Historique</b-button>
            </div>
        </div>

        <b-modal
            scrollable
            centered
            id="modalRackLog"
            :title="
                'Historique de l\'arceau #' + rack.id + ' (' + rack.name + ')'
            "
            hide-backdrop
            size="lg"
            :header-class="'modal-header-' + $store.getters.account"
            content-class="shadow-lg"
            body-class="stripped m-2"
        >
            <lock-status :lock="rack.lock" />
            <table class="w-100 mt-2">
                <tr>
                    <th>Date</th>
                    <th>Succès</th>
                    <th>Action</th>
                </tr>
                <tr v-for="log in rackLog" :key="log.id">
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        {{ log.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                    </td>
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        <span v-if="log.error == 0">
                            <b-icon-check-square-fill
                                variant="success"
                                class="success-check"
                            />
                        </span>
                        <span v-else class="journal-status-error">{{
                            getStatus(log)
                        }}</span>
                    </td>
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        <span
                            :class="getActionClass(log)"
                            class="journal-action"
                            >{{ getText(log) }}</span
                        >
                    </td>
                </tr>
                <infinite-loading
                    slot="append"
                    @infinite="infiniteHandler"
                    force-use-infinite-wrapper="#modalRackLog___BV_modal_body_"
                >
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </table>

            <div class="font-italic mt-3" v-if="rackLog.length == 0">
                Pas d'historique
            </div>

            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button @click="$bvModal.hide('modalRackLog')"
                        >Fermer</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import {
    ActionText,
    JournalAction,
    ContactType,
    StatusText,
} from "./AdminJournal.vue";
import LockStatus from "./LockStatus.vue";
import RackLockStatus from "./RackLockStatus.vue";
import CodeApi from "@/apis/AdminCode.js";
import InfiniteLoading from "vue-infinite-loading";
import JournalApi from "@/apis/AdminLog.js";
export default {
    props: ["rack", "parkId", "parkName", "showClose"],

    components: { RackLockStatus, LockStatus, InfiniteLoading },
    data() {
        return {
            selectedCodeId: 0,
            codes: null,
            currentRackId: null,
            isExtending: false,
            isAttaching: false,
            departureDate: null,
            code: null,
            options: {
                format: "DD/MM/YYYY HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                toolbarPlacement: "top",
                showTodayButton: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
            },
            rackLog: [],
            page: 1,
            rows: 30,
        };
    },
    methods: {
        handleLog() {
            this.rackLog = [];
            this.page = 1;
            this.$bvModal.show("modalRackLog");
        },
        async infiniteHandler($state) {
            const delay = (ms) => new Promise((res) => setTimeout(res, ms));
            const req = await JournalApi.findLogs({limit:this.rows, page:this.page}, {order:{id:-1}, where:{rack:this.rack.name, text:true}});
            /*
                this.rack.id,
                this.page,
                this.rows
            );
            */
           console.log(req.data);
            if (req.data.items.length) {
                this.rackLog.push(...req.data.items);
                await delay(500);
                this.page += 1;
                $state.loaded();
            } else {
                $state.complete();
            }
        },
        async clearAlert() {
            try {
                const response = await CodeApi.clearAlert(this.rack.lock.id);
                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.$emit("refresh");
                    this.toast("Message", "Erreur effacée !", false);
                }
            } catch (error) {
                console.log(error);
                this.toast("Erreur!", error.message, true);
            }
        },
        async getCodes() {
            const res = await CodeApi.getParkFreeCodes(this.parkId);
            this.codes = res.data.length
                ? res.data
                : [{ id: 0, name: "Aucun code libre" }];
            //console.log(res.data);
        },
        async handleAttachAction() {
            this.currentRackId = this.rack.id;

            this.isAttaching = !this.isAttaching;
            this.selectedCodeId = 0;
            this.getCodes();
        },
        async submitAttachForm() {
            try {
                const response = await CodeApi.linkCode(
                    this.selectedCodeId,
                    this.rack.id
                );
                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.isAttaching = false;
                    //this.$root.$emit("bv::refresh::table", "code-table");
                    this.$emit("refresh");
                    this.toast("Message", "Arceau attaché au code !", false);
                }
            } catch (error) {
                console.log(error);
                this.toast("Erreur!", error.message, true);
            }
        },
        async handleOpenAction() {
            const h = this.$createElement;
            // Using HTML string
            const titleVNode = h("div", {
                domProps: { innerHTML: "Ouvrir" },
            });
            // More complex structure
            const messageVNode = h("div", { class: ["foobar"] }, [
                h("p", { class: ["text-center p-3"] }, [
                    `Veuillez confirmer la demande d'ouverture de l'arceau: #${this.rack.id} (${this.rack.name})`,
                ]),
                h("b-img", {
                    props: {
                        src: "/img/detach.png",
                        center: true,
                        fluid: true,
                    },
                }),
                //h("p", { class: ["text-center"] }, [h("b-spinner")]),
            ]);
            const confirmation = await this.$bvModal.msgBoxConfirm(
                [messageVNode],
                {
                    title: [titleVNode],
                    size: "md",
                    buttonSize: "md",
                    okVariant: "warning",
                    okTitle: "Ouvrir",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    hideBackdrop: true,
                    headerClass: "modal-header-" + this.$store.getters.account,
                    contentClass: "shadow-lg",
                }
            );
            if (confirmation) {
                try {
                    if (this.rack.code) {
                        const resUnlink = await CodeApi.unlinkCode(
                            this.rack.code.id,
                            this.rack.id
                        );
                        //console.log(resUnlink);
                        if (resUnlink.data == 1) {
                            this.toast("Message", "Arceau libéré !", false);
                            this.$emit("refresh");
                            this.rack.code = null;
                        } else {
                            this.toast("Erreur", "Arceau non libéré !", true);
                        }
                    }
                    const res = await CodeApi.setAlwaysOpenUntil(
                        this.rack.lock.id
                    );

                    //console.log(res);
                    if (res.data == 1) {
                        this.toast(
                            "Message",
                            "Arceau prêt pour ouverture !",
                            false
                        );
                        this.$emit("refresh");
                        this.rack.code = null;
                    } else {
                        this.toast(
                            "Erreur",
                            "Arceau non prêt pour ouverture !",
                            true
                        );
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            }
        },
        async handleDetachAction() {
            const h = this.$createElement;
            const titleVNode = h("div", {
                domProps: { innerHTML: "Liberer ?" },
            });
            const messageVNode = h("div", { class: ["foobar"] }, [
                h("p", { class: ["text-center p-3"] }, [
                    `Veuillez confirmer la demande de libération de l'arceau: #${this.rack.id} (${this.rack.name})`,
                ]),
                h("b-img", {
                    props: {
                        src: "/img/detach.png",
                        center: true,
                        fluid: true,
                    },
                }),
            ]);
            const confirmation = await this.$bvModal.msgBoxConfirm(
                [messageVNode],
                {
                    title: [titleVNode],
                    size: "md",
                    buttonSize: "md",
                    okVariant: "warning",
                    okTitle: "Liberer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    hideBackdrop: true,
                    headerClass: "modal-header-" + this.$store.getters.account,
                    contentClass: "shadow-lg",
                }
            );
            if (confirmation) {
                try {
                    const res = await CodeApi.unlinkCode(
                        this.rack.code.id,
                        this.rack.id
                    );
                    //console.log(res);
                    if (res.data == 1) {
                        this.toast("Message", "Arceau libéré !", false);
                        this.$emit("refresh");
                        this.rack.code = null;
                    } else {
                        this.toast("Erreur", "Arceau non libéré !", true);
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            }
        },

        handleExtendButton() {
            this.currentRackId = this.rack.id;
            this.isExtending = !this.isExtending;
            this.options.minDate = new Date();
            this.departureDate = this.$moment(new Date()).add(10, "minutes");
        },
        async handleExtendAction() {
            try {
                const res = await CodeApi.extendCode(this.rack.code.id, {
                    date: this.$moment(this.departureDate, "DD/MM/YYYY HH:mm"),
                });
                if (res.data == 1) {
                    this.toast("Prolongé", "Code prolongé !", false);
                    this.$emit("refresh");
                } else {
                    this.toast("Erreur", "Code non prolongé !", true);
                }

                this.isExtending = false;
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        getStatus(journal) {
            if (journal) return StatusText[journal.status];
        },
        getText(log) {
            if(log.text == "Status update") {
                return "Status: " + log.request.substring(log.request.indexOf("params:")+8);
            }
            return log.text;
        },
        getContactType(type) {
            return ContactType[type];
        },
        getAction(journal) {
            if (journal) return ActionText[journal.action];
        },

        getActionClass(journal) {
            let actionClass = "";
            if (journal) {
                switch (journal.action) {
                    case JournalAction.BUY:
                    case JournalAction.BUY_EXTRA:
                        actionClass = "action-buy";
                        break;
                    case JournalAction.REMOTE_OPEN:
                    case JournalAction.REMOTE_DETACH:
                    case JournalAction.VISITOR_EXTEND:
                    case JournalAction.ADMIN_EXTEND:
                    case JournalAction.ADMIN_ATTACH:
                    case JournalAction.ADMIN_DETACH:
                        actionClass = "action-detach";
                        break;
                    case JournalAction.ATTACH:
                        actionClass = "action-attach";
                        break;
                    case JournalAction.DETACH:
                        actionClass = "action-detach";
                        break;
                }
                return actionClass;
            }
        },
    },
    computed: {
        getComplement() {
            return !this.rack.code.isRfid ? "Complèment à payer." : "";
        },
        getCode() {
            //console.log(this.rack, this.rack.code ? this.rack.code : null);
            return this.rack.code
                ? this.rack.code
                : {
                      park: null,
                      codeNumber: null,
                      arrivalDate: null,
                      departureDate: null,
                  };
        },
        reachable() {
            //console.log("rack", this.rack);
            return this.rack.lock ? this.rack.lock.reachable : false;
        },
    },
    updated() {
        //console.log(this.rack);
        //console.log(this.parkId);
        if (this.rack.id != this.currentRackId) {
            this.isExtending = false;
            this.isAttaching = false;
        }
    },
    mounted() {
        //console.log(this.parkId);
    },
};
</script>
