import Api from "./Api";
export default {
    findFirmwares(paginationOptions, findOptions) {
        return Api().post(
            "/firmware/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getFirmware(id) {
        return Api().get("/firmware/" + id);
    },
    getFirmwares() {
        return Api().get("/firmware");
    },
    createFirmware(payload) {
        return Api().post("/firmware", payload);
    },
    updateFirmware(payload) {
        return Api().patch("/firmware/" + payload.id, payload);
    },
    deleteFirmware(id) {
        return Api().delete("/firmware/" + id);
    },
};
