<template>
    <div>
        <l-map
            ref="lmap"
            class="leaflet-map"
            :maxZoom="maxZoom"
            :minZoom="minZoom"
            :crs="crs"
            :zoom="zoom"
            :maxBounds="maxBounds()"
            @contextmenu="onRightClick"
        >
            <l-control>
                <span
                    role="button"
                    class="text-xl p-1 m-1"
                    style="z-index:300 "
                    @click="getRacks"
                    >Mise à jour: {{ $moment().format("HH:mm:ss") }}
                </span>
                <b-button
                    v-if="dragEnabled"
                    @click="dragEnabled = false"
                    variant="warning"
                    ><b-icon
                        icon="unlock-fill"
                        class="rounded"
                        variant="info"
                    ></b-icon></b-button
                ><b-button v-else @click="dragEnabled = true" variant="info">
                    <b-icon
                        icon="lock-fill"
                        class="rounded"
                        variant="warning"
                    ></b-icon
                ></b-button>
            </l-control>
            <l-control :position="'bottomleft'" class="custom-control-watermark"
                >{{ parkOptions.name }} - {{ parkOptions.accountDesc }}
            </l-control>
            <l-image-overlay
                :url="parkOptions.url"
                :bounds="parkOptions.bounds"
            ></l-image-overlay>
            <l-feature-group ref="features">
                <l-popup :class="'rack-status-' + this.parkOptions.account">
                    <rack-status
                        :rack="rack"
                        :parkId="parkId"
                        :parkName="parkOptions.name"
                        v-on:refresh="onPopupRefresh"
                        vonshowlog="openRackLog"
                        showClose="0"
                    ></rack-status>
                </l-popup>
            </l-feature-group>
            <l-marker
                :visible="dragEnabled"
                v-for="(rack, idx) in racks"
                :key="rack.name"
                :latLng="rack.latLng"
                :color="rack.color"
                :fillColor="rack.fillColor"
                :draggable="dragEnabled"
                @mousedown="startDrag(idx)"
                @update:lat-lng="endDrag"
            >
            </l-marker>
            <l-circle
                v-for="(rack, idx) in racks"
                :key="rack.id"
                :latLng="rack.latLng"
                :color="rack.color"
                :fillColor="rack.fillColor"
                :draggable="dragEnabled"
                @click="openPopUp(rack, idx)"
            >
                <l-tooltip class="l-tooltip" :options="{ opacity: '1' }">
                    <div class="d-flex flex-row justify-content-between">
                        <div>
                            <strong>Arceau #{{ rack.id }}</strong>
                        </div>
                        <div>{{ rack.name }}</div>
                    </div>
                    <rack-lock-status :lock="rack.lock" />
                </l-tooltip>
            </l-circle>
            <l-marker
                v-for="(rack) in racks"
                :key="rack.warmid"
                :visible="rack.hasIssue"
                :latLng="rack.latLng"
                :icon="icon"
            >
            </l-marker>
        </l-map>
        <b-modal
            size="md"
            centered_
            id="modalMoveRack"
            title="Sélectionnez l'arceau à déplacer"
            hide-backdrop
            :header-class="'modal-header-' + $store.getters.account"
            content-class="shadow-lg"
            body-class="stripped m-2"
        >
            <b-form>
                <b-form-group id="input-rack" label="Arceau :" label-for="rack"
                    ><b-input-group>
                        <b-form-select
                            id="rack"
                            v-model="moveRack.id"
                            :options="racks"
                            value-field="id"
                            text-field="display"
                        ></b-form-select>
                    </b-input-group>
                </b-form-group>
            </b-form>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-end">
                    <b-button
                        variant="info"
                        @click="$bvModal.hide('modalMoveRack')"
                        >Annuler
                    </b-button>
                    <b-button
                        class="ml-3"
                        variant="success"
                        @click="submitMoveRack"
                        >Valider
                    </b-button>
                </b-container>
            </template>
        </b-modal>

        <!--  <b-modal
            scrollable
            centered
            id="modalRackLog"
            :title="
                'Historique de l\'arceau #' + rack.id + ' (' + rack.name + ')'
            "
            hide-backdrop
            size="lg"
            :header-class="'modal-header-' + $store.getters.account"
            content-class="shadow-lg"
            body-class="stripped m-2"
        >
            <lock-status :lock="rack.lock" />
            <table class="w-100 mt-2">
                <tr>
                    <th>Date</th>
                    <th>Action</th>

                    <th colspan="2">
                        Contact
                    </th>
                    <th>Succès</th>
                </tr>
                <tr v-for="log in rackLog" :key="log.id">
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        {{ log.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
                    </td>
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        <span
                            :class="getActionClass(log)"
                            class="journal-action"
                            >{{ getAction(log) }}</span
                        >
                    </td>
                    <td class="pt-1">
                        <span v-if="log.contactType == 0">
                            <b-icon-credit-card
                                variant="info"
                                class="contact-type"
                            />
                        </span>
                        <span v-else-if="log.contactType == 1">
                            <b-icon-broadcast
                                variant="info"
                                class="success-check"
                            />
                        </span>
                        <span v-else-if="log.contactType == 2">
                            <b-icon-keyboard
                                variant="info"
                                class="contact-type"
                            />
                        </span>
                        <span v-else>{{ log.contactType }}</span>
                    </td>
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        {{ log.contact }}
                    </td>
                    <td class="px-1 pb-1 pt-2 text-nowrap">
                        <span v-if="log.status == 0">
                            <b-icon-check-square-fill
                                variant="success"
                                class="success-check"
                            />
                        </span>
                        <span v-else class="journal-status-error">{{
                            getStatus(log)
                        }}</span>
                    </td>
                </tr>
                <infinite-loading
                    slot="append"
                    @infinite="infiniteHandler"
                    force-use-infinite-wrapper="#modalRackLog___BV_modal_body_"
                >
                    <div slot="no-more"></div>
                    <div slot="no-results"></div>
                </infinite-loading>
            </table>

            <div class="font-italic mt-3" v-if="rackLog.length == 0">
                Pas d'historique
            </div>

            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button @click="$bvModal.hide('modalRackLog')"
                        >Fermer</b-button
                    >
                </b-container>
            </template>
        </b-modal> -->
    </div>
</template>

<script>
import ParkApi from "../apis/AdminPark";
import RackApi from "../apis/AdminRack.js";
import L from 'leaflet';
import { Icon } from "leaflet";
import { CRS } from "leaflet";
import {
    LMap,
    LImageOverlay,
    LFeatureGroup,
    LCircle,
    LPopup,
    LTooltip,
    LMarker,
    LControl,
} from "vue2-leaflet";
import RackStatus from "./RackStatus.vue";
import RackLockStatus from "./RackLockStatus.vue";
//import LockStatus from "./LockStatus.vue";
//import JournalApi from "@/apis/AdminJournal.js";
//import InfiniteLoading from "vue-infinite-loading";

/* import {
    ActionText,
    JournalAction,
    ContactType,
    StatusText,
} from "./AdminJournal.vue"; */

const RackColors = {
    RACK_FREE: "green",
    RACK_BUSY: "blue",
    CODE_EXPIRED: "darkorange",
    RACK_UNAVAILABLE: "red",
    CODE_RFID_EXPIRED: "darkorange",
};
export default {
    components: {
        LMap,
        LCircle,
        LImageOverlay,
        LFeatureGroup,
        LMarker,
        LTooltip,
        LPopup,
        LControl,
        RackStatus,
        //LockStatus,
        RackLockStatus,
        //InfiniteLoading,
    },
    props: ["parkId"],
    data() {
        return {
            parkOptions: {
                name: "",
                id: 0,
                account: "",
                url: "",
                bounds: [
                    [0, 0],
                    [0, 0],
                ],
            },
            dragEnabled: false,
            rack: { latLng: [], lock: {}, hasIssue:false },
            racks: [],
            crs: CRS.Simple,
            zoom: 0,
            maxZoom: 2,
            minZoom: -2,
            dragTimeout: null,
            dragging: false,
            moveRack: { id: 0, latLng: [0, 0] },
            caller: null,
            refreshTimeout: null,
            rackLog: [],
            page: 1,
            rows: 30,
            icon: L.icon({
                iconUrl: '/alert.png',
                iconSize: [32, 32],
                iconAnchor: [16, 37]
            }),
        };
    },
    methods: {
        /* async infiniteHandler($state) {
            const delay = (ms) => new Promise((res) => setTimeout(res, ms));

            const req = await JournalApi.getRackJournal(
                this.rack.id,
                this.page,
                this.rows
            );
            if (req.data.length) {
                this.rackLog.push(...req.data);
                await delay(500);
                this.page += 1;
                $state.loaded();
            } else {
                $state.complete();
            }
        },
        getStatus(journal) {
            if (journal) return StatusText[journal.status];
        },
        getContactType(type) {
            return ContactType[type];
        },
        getAction(journal) {
            if (journal) return ActionText[journal.action];
        },
        getActionClass(journal) {
            let actionClass = "";
            if (journal) {
                switch (journal.action) {
                    case JournalAction.BUY:
                    case JournalAction.BUY_EXTRA:
                        actionClass = "action-buy";
                        break;
                    case JournalAction.REMOTE_OPEN:
                    case JournalAction.REMOTE_DETACH:
                    case JournalAction.VISITOR_EXTEND:
                    case JournalAction.ADMIN_EXTEND:
                    case JournalAction.ADMIN_ATTACH:
                    case JournalAction.ADMIN_DETACH:
                        actionClass = "action-detach";
                        break;
                    case JournalAction.ATTACH:
                        actionClass = "action-attach";
                        break;
                    case JournalAction.DETACH:
                        actionClass = "action-detach";
                        break;
                }
                return actionClass;
            }
        },
        async getRackLog() {
            const req = await JournalApi.getRackJournal(
                this.rack.id,
                this.page,
                this.rows
            );
            //console.log(req.data);
            this.rackLog = req.data;
        },
        openRackLog() {
            this.rackLog = [];
            this.page = 1;
            this.$bvModal.show("modalRackLog");
        }, */
        maxBounds() {
            const offset = 1000;
            return [
                [
                    this.parkOptions.bounds[0][0] - offset,
                    this.parkOptions.bounds[0][1] - offset,
                ],
                [
                    this.parkOptions.bounds[1][0] + offset,
                    this.parkOptions.bounds[1][1] + offset,
                ],
            ];
        },
        onRightClick(e) {
            if (this.dragEnabled) {
                this.$bvModal.show("modalMoveRack");
                this.moveRack.latLng = [e.latlng.lat, e.latlng.lng];
                //console.log("moveRack", this.moveRack);
            }
        },
        async submitMoveRack() {
            const rack = this.racks.find((r) => r.id == this.moveRack.id);
            rack.latLng = this.moveRack.latLng;
            //console.log("rack", rack);
            await this.saveLatLngRack(rack);
            this.$bvModal.hide("modalMoveRack");
        },
        async getRacks() {
            try {
                const racks = await RackApi.getParkRacks(this.parkId);

                if (racks.status === 200) {
                    this.racks = [];
                    racks.data.forEach((rack) => {
                        let color = RackColors.RACK_FREE;
                        if (rack.code) {
                            color = this.$moment(
                                rack.code.departureDate
                            ).isBefore()
                                ? rack.code.isRfid
                                    ? RackColors.CODE_RFID_EXPIRED
                                    : RackColors.CODE_EXPIRED
                                : RackColors.RACK_BUSY;
                            rack.code.park = { name: this.parkOptions.name };
                        }
                        rack.hasIssue = false;
                        
                        if(rack.lock.closed != rack.lock.locked) {
                            rack.hasIssue = true;                            
                        }
                        if(!rack.lock.closed && rack.code) {
                            rack.hasIssue = true;                            
                        }
                        if(rack.lock.closed && !rack.code) {
                            rack.hasIssue = true;                            
                        }
                        if (rack.lock.reachable === false) {
                            color = RackColors.RACK_UNAVAILABLE;                            
                        }
                        
                        this.racks.push({
                            name: rack.name,
                            display: "#" + rack.id + " (" + rack.name + ")",
                            id: rack.id,
                            warmid: "warm_"+rack.id,
                            latLng: rack.latLng,
                            hasIssue: rack.hasIssue,
                            radius: 10,
                            color: color,
                            fillColor: color,
                            code: rack.code,
                            lock: rack.lock,
                        });
                    });
                } else {
                    this.stopRefreshTimer();
                    this.toast("Erreur!", racks.statusText, true);
                }
            } catch (err) {
                this.stopRefreshTimer();
                this.toast("Erreur", err.message, true);
            }
        },
        async getPark() {
            try {
                const park = await ParkApi.getPark(this.parkId);
                //console.log(park);
                if (park.status === 200) {
                    const bounds = [[0, 0]].concat([
                        park.data.size.map((e) => parseInt(e)),
                    ]);

                    this.parkOptions = {
                        name: park.data.name,
                        id: park.data.id,
                        account: park.data.account.name,
                        accountDesc: park.data.account.description,
                        url:
                            process.env.VUE_APP_BASE_URL +
                            "/parks/parkimages/" +
                            park.data.url,
                        bounds: bounds,
                    };
                } else {
                    this.stopRefreshTimer();
                    this.toast("Erreur!", park.statusText, true);
                }
            } catch (err) {
                this.stopRefreshTimer();
                this.toast("Erreur", err.message, true);
            }
        },
        async saveLatLngRack(rack) {
            const saved = await RackApi.updateRack({
                id: rack.id,
                latLng: rack.latLng,
            });
            this.toast("Message", saved.data);
            this.dragging = false;
        },
        endDrag(latLng) {
            if (this.dragging) {
                //console.log(latLng);
                this.rack.latLng = [latLng.lat, latLng.lng];
                clearTimeout(this.dragTimeout);
                this.dragTimeout = setTimeout(() => {
                    this.saveLatLngRack(this.rack);
                }, 1000);
            }
        },
        startDrag(index) {
            this.dragging = true;
            this.rack = this.racks[index];
        },
        async onPopupRefresh() {
            const rackId = this.rack.id;
            await this.getRacks();
            this.rack = this.racks.filter((r) => r.id == rackId)[0];
            this.$refs.features.mapObject.openPopup(this.rack.latLng);
        },
        openPopUp(rack) {
            //console.log("rack", rack);

            this.rack = rack;
            this.$refs.features.mapObject.openPopup(rack.latLng);
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        startRefreshTimer() {
            this.refreshTimeout = setInterval(() => {
                this.getRacks();
            }, 120000);
        },
        stopRefreshTimer() {
            clearInterval(this.refreshTimeout);
        },
    },
    async mounted() {
        await this.getPark();
        if (this.$refs.lmap) {
            this.$refs.lmap.fitBounds(this.parkOptions.bounds);
        }
        await this.getRacks();
        this.startRefreshTimer();

        delete Icon.Default.prototype._getIconUrl;
        Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        });
    },
    updated() {
        //console.log("update", this.rack);
    },
    beforeDestroy() {
        this.stopRefreshTimer();
    },
};
</script>
