import Api from "./Api";
export default {
    getRfidServers() {
        return Api().get("/rfid");
    },
    getAccountRfidServers(id) {
        return Api().get("/rfid/account/" + id);
    },
    getRfidServer(id) {
        return Api().get("/rfid/" + id);
    },
    addRfidServer(payload) {
        return Api().post("/rfid", payload);
    },
    updateRfidServer(payload) {
        return Api().patch("/rfid/" + payload.id, payload);
    },
    deleteRfidServer(id) {
        return Api().delete("/rfid/" + id);
    },
};
