<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Journal</h3>
        <b-row>
            <b-col
                xs="12"
                md="12"
                class="d-flex flex-row toggle-wrap"
                id="log-search-form"
            >
                <b-form-group
                    id="account-name"
                    label="Compte :"
                    class="mr-2"
                    label-for="account"
                >
                    <b-form-select
                        v-model="filter.account"
                        :options="accounts"
                        value-field="id"
                        text-field="name"
                        id="account"
                        class="log-list-form-input"
                        @change="handleAccount"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    id="input-park"
                    label="Parc :"
                    label-for="park"
                    class="mr-2"
                >
                    <b-form-select
                        id="park"
                        v-model="filter.park"
                        :options="parks"
                        value-field="id"
                        text-field="name"
                        class="log-list-form-input"
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    id="rack-name"
                    label="Arceau :"
                    label-for="rack"
                    class="mr-2"
                >
                    <b-form-input
                        id="rack"
                        v-model="filter.rack"
                        class="log-text-form-input"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="code-name"
                    label="Code :"
                    label-for="code"
                    class="mr-2"
                >
                    <b-form-input
                        id="code"
                        v-model="filter.code"
                        class="code-form-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="contact-name"
                    label="Contact :"
                    label-for="contact"
                    class="mr-2"
                >
                    <b-form-input
                        id="contact"
                        v-model="filter.contact"
                        class="log-text-form-input"
                    ></b-form-input>
                </b-form-group>

                <b-button-group class="filter-form-input">
                    <b-button @click="clearFilter" variant="info">
                        <b-icon-x-circle-fill />
                    </b-button>
                    <b-button variant="info" @click="applyFilter">
                        Filtrer
                    </b-button>
                </b-button-group>
            </b-col>
        </b-row>
        <b-row no-gutters>
            <b-table
                :style="{ 'max-width': maxWidth }"
                id="journal-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getJournals"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                striped
                sort-icon-left
                sticky-header="calc(100vh - 380px)"
            >
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openJournalModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-eye />
                    </b-button>
                </template>
                <template #cell(details)="row">
                    {{ getDetails(row.item) }}
                </template>
                <template #cell(action)="row">
                    <span
                        :class="getActionClass(row.item)"
                        class="journal-action"
                        >{{ getAction(row.item) }}</span
                    >
                </template>
                <template #cell(park)="row">
                    <router-link :to="'/arceau/park/'+row.item.park.id">{{row.item.park.name}}</router-link>
                </template>
                <template #cell(status)="row">
                    <span v-if="row.item.status == 0">
                        <b-icon-check-square-fill
                            variant="success"
                            class="success-check"
                        />
                    </span>
                    <span v-else class="journal-status-error">{{
                        getStatus(row.item)
                    }}</span>
                </template>
                <template #cell(contactType)="row">
                    <span v-if="row.item.contactType == 0">
                        <b-icon-credit-card
                            variant="info"
                            class="contact-type"
                        />
                    </span>
                    <span v-else-if="row.item.contactType == 1">
                        <b-icon-broadcast
                            variant="info"
                            class="success-check"
                        />
                    </span>
                    <span v-else-if="row.item.contactType == 2">
                        <b-icon-keyboard variant="info" class="contact-type" />
                    </span>
                    <span v-else>{{ row.item.contactType }}</span>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalJournal"
            :title="modalTitle"
            size="lg"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container fluid>
                <table stripped id="journal-modal" class="w-100">
                    <tr>
                        <th>Date:</th>
                        <td>
                            {{
                                journal.created_at
                                    | moment(" DD/MM/YYYY  HH:mm:ss.SSS")
                            }}
                        </td>
                    </tr>
                    <tr>
                        <th>Contact:</th>
                        <td>{{ journal.contact }}</td>
                    </tr>
                    <tr>
                        <th>Type:</th>
                        <td>
                            <b-icon-credit-card
                                v-if="journal.contactType == 0"
                                variant="info"
                                class="contact-type"
                            />
                            <b-icon-broadcast
                                v-else-if="journal.contactType == 1"
                                variant="info"
                                class="contact-type"
                            />
                            <b-icon-keyboard
                                v-else-if="journal.contactType == 2"
                                variant="info"
                                class="contact-type"
                            />
                            <span class="ml-2">{{
                                getContactType(journal.contactType)
                            }}</span>
                        </td>
                    </tr>
                    <tr>
                        <th>Action:</th>
                        <td>
                            <span
                                :class="getActionClass(journal)"
                                class="journal-action"
                                >{{ getAction(journal) }}</span
                            >
                        </td>
                    </tr>
                    <tr>
                        <th>Status:</th>
                        <td v-if="journal.status == 0">
                            <b-icon-check-square-fill
                                variant="success"
                                class="success-check mr-2"
                            />
                            {{ getStatus(journal) }}
                        </td>
                        <td v-else>
                            <span class="journal-status-error">{{
                                getStatus(journal)
                            }}</span>
                        </td>
                    </tr>
                    <tr v-if="journal.account">
                        <th>Compte:</th>
                        <td>
                            {{ journal.account.name }} ({{
                                journal.account.id
                            }})
                        </td>
                    </tr>
                    <tr v-if="journal.park">
                        <th>Parc:</th>
                        <td>{{ journal.park.name }} ({{ journal.park.id }})</td>
                    </tr>
                    <tr v-if="journal.rack">
                        <th>Arceau:</th>
                        <td>{{ journal.rack.name }} ({{ journal.rack.id }})</td>
                    </tr>
                    <tr v-if="journal.code">
                        <th>Code:</th>
                        <td>
                            {{ journal.code.codeNumber }} ({{
                                journal.code.id
                            }}) ({{ journal.code.isRfid ? "RFID" : "Borne" }}) -
                            {{
                                journal.code.arrivalDate
                                    | moment(" DD/MM/YYYY  HH:mm")
                            }}
                            -
                            {{
                                journal.code.departureDate
                                    | moment(" DD/MM/YYYY  HH:mm")
                            }}
                        </td>
                    </tr>
                    <tr>
                        <th>Détails:</th>
                        <td>{{ getDetails(journal) }}</td>
                    </tr>
                    <tr v-if="journal.price">
                        <th>Coût</th>
                        <td>{{ journal.price }} &euro;</td>
                    </tr>
                </table>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="$bvModal.hide('modalJournal')"
                        >Fermer</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import AccountApi from "@/apis/AdminAccount.js";
import ParkApi from "@/apis/AdminPark.js";
import JournalApi from "@/apis/AdminJournal.js";

import {
    ActionText,
    JournalAction,
    ContactType,
    StatusText,
} from "../AdminJournal.vue";

export default {
    data() {
        return {
            journal: {},
            journals: [],
            parks: [],
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                {
                    key: "created_at",
                    sortable: true,
                    tdClass: "text-nowrap",
                    label: "Date",
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                },
                {
                    key: "action",
                    sortable: true,
                    sortByFormatted: true,
                    label: "Action",
                },
                {
                    key: "contactType",
                    formatter: (value) => {
                        return this.getContactType(value);
                    },
                    sortable: true,
                    sortByFormatted: true,
                    tdClass: "text-right",
                    label: "Type",
                },
                { key: "contact", sortable: true, label: "Contact" },
                { key: "code.codeNumber", sortable: true, label: "Code" },
                { key: "park", sortable: true, label: "Park" },
                { key: "rack.name", sortable: true, label: "Rack" },

                {
                    key: "status",

                    sortable: true,
                    sortByFormatted: true,
                    label: "Succès",
                },
                {
                    key: "details",
                    label: "Détails",
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
            filter: {},
            previousOrder: {},
            accounts: [],
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        maxWidth() {
            return this.windowWidth > 575
                ? `${this.windowWidth - 262}px`
                : "100%";
        },
    },
    mounted() {
        if(this.$route.query.rack)
            this.filter.rack = this.$route.query.rack;
        if(this.$route.query.code)
            this.filter.code = this.$route.query.code;
        this.getAccounts();
        this.getParks();
        this.$root.$on("search", () => {
            this.applyFilter();
        });
        window.addEventListener("resize", this.widthHandler);
    },
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "journal-table");
        },
        async getJournals(ctx) {
            try {

                let filter = Object.fromEntries(Object.entries(this.filter).filter(([_, v]) => v != ""));
                if(filter.account) {
                    filter.account = {id: this.filter.account};
                }
                if(filter.park) {
                    filter.park = {id: filter.park};
                }
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const journals = await JournalApi.findJournals(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );
                if (journals.status === 201) {
                    this.journals = journals.data.items;
                    this.totalRows = journals.data.meta.totalItems;
                    this.currentPage = journals.data.meta.currentPage;
                    return journals.data.items;
                } else {
                    this.toast("Erreur!", journals.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async getAccounts() {
            try {
                const accounts = await AccountApi.getAccounts();
                this.accounts = accounts.data;
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
        },
        async getParks() {
            try {
                const parks = this.filter.account
                    ? await ParkApi.getAccountParks(this.filter.account)
                    : await ParkApi.getParks();
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async handleAccount() {
            delete this.filter.park;
            await this.getParks();
        },
        clearFilter() {
            this.filter = {};
            this.handleAccount();
        },
        openJournalModal(journal) {
            this.journal = journal;
            this.modalTitle = "Consulter Journal: " + journal.id;
            this.$bvModal.show("modalJournal");
        },
        widthHandler() {
            this.windowWidth = window.innerWidth;
        },
        getDetails(journal) {
            let details = journal.status ? "Tentative de " : "";
            const rack = journal.rack
                ? `${journal.rack.name} (${journal.rack.id})`
                : "?";
            const code = journal.code
                ? ` ${journal.code.codeNumber} (${journal.code.id})`
                : journal.contact;
            switch (journal.action) {
                case JournalAction.BUY:
                    details += `Achat du code ${
                        journal.code.codeNumber
                    }, valide jusqu'au ${this.$moment(
                        journal.code.departureDate
                    ).format("DD/MM/YYYY  HH:mm")} `;
                    break;
                case JournalAction.BUY_EXTRA:
                    details += `Achat de prolongation du code ${
                        journal.code.codeNumber
                    }, valide jusqu'au ${this.$moment(
                        journal.code.departureDate
                    ).format("DD/MM/YYYY  HH:mm")} `;
                    break;
                case JournalAction.ADMIN_DETACH:
                    details += `Libération de l'arceau ${rack} du parc ${journal.park.name}`;
                    break;
                case JournalAction.ADMIN_ATTACH:
                    details += `Association du code ${code} avec l'arceau ${rack} du parc ${journal.park.name}`;
                    break;
                case JournalAction.ATTACH:
                    details += `Verrouillage de l'arceau ${rack} à l'aide du code ${code}`;
                    break;
                case JournalAction.DETACH:
                    details += `Déverrouillage de l'arceau ${rack} à l'aide du code ${code}`;
                    break;
                case JournalAction.VISITOR_EXTEND:
                case JournalAction.ADMIN_EXTEND:
                    details += `Prolongation du code ${code} associé à l'arceau ${rack}`;
                    break;
                case JournalAction.REMOTE_DETACH:
                    details += `Déverrouillage de l'arceau ${rack} depuis le mode Ouverture à distance`;
                    break;
                case JournalAction.REMOTE_OPEN:
                    details += `Passage de l'arceau ${rack} en mode Ouverture à distance`;
                    break;
            }
            return details;
        },
        getStatus(journal) {
            if (journal) return StatusText[journal.status];
        },
        getContactType(type) {
            return ContactType[type];
        },
        getAction(journal) {
            if (journal) return ActionText[journal.action];
        },
        getActionClass(journal) {
            let actionClass = "";
            if (journal) {
                switch (journal.action) {
                    case JournalAction.BUY:
                    case JournalAction.BUY_EXTRA:
                        actionClass = "action-buy";
                        break;
                    case JournalAction.REMOTE_OPEN:
                    case JournalAction.REMOTE_DETACH:
                    case JournalAction.VISITOR_EXTEND:
                    case JournalAction.ADMIN_EXTEND:
                    case JournalAction.ADMIN_ATTACH:
                    case JournalAction.ADMIN_DETACH:
                        actionClass = "action-detach";
                        break;
                    case JournalAction.ATTACH:
                        actionClass = "action-attach";
                        break;
                    case JournalAction.DETACH:
                        actionClass = "action-detach";
                        break;
                }
                return actionClass;
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.widthHandler);
    },
};
</script>
