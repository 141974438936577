<template>
    <b-container fluid class="p-0 rack-status">
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
            integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
        />
        <div class="header">Code #{{ code.codeNumber }}</div>
        <div>
            Parc :
            {{ code.park ? code.park.name : "" }}
        </div>
        <div v-if="code.rack.id">Arceau : {{ code.rack.name }}</div>
        <div v-else class="d-flex flex-row flex-nowrap">
            <span class="mr-1 mt-1 text-nowrap">Arceau :</span>
            <div v-if="isAttaching" class="ml-3">
                <b-input-group>
                    <b-form-select
                        id="rack"
                        v-model="selectedRackId"
                        :options="racks"
                        value-field="id"
                        text-field="name"
                    >
                    </b-form-select>
                    <template #append>
                        <b-button-group>
                            <b-button @click="isAttaching = false">
                                <b-icon-x-circle-fill />
                            </b-button>
                            <b-button @click="submitAttachForm">
                                <b-icon-check-circle-fill />
                            </b-button>
                        </b-button-group>
                    </template>
                </b-input-group>
            </div>
            <b-button
                v-else-if="code.isActive"
                pill
                @click="handleAttachAction"
                class="mx-3 my-0"
            >
                Associer
            </b-button>
        </div>
        <div>Contact: {{ code.contact }}</div>
        <div>
            Date d'arrivée :
            {{ code.arrivalDate | moment("DD/MM/YYYY à HH:mm") }}
        </div>
        <div v-if="!isExtending">
            Date de départ :
            {{ code.departureDate | moment("DD/MM/YYYY à HH:mm") }}
            <b-button
                pill
                @click="handleExtendButton"
                v-if="$moment(code.departureDate).isBefore() && code.isActive"
                class="ml-3"
            >
                Prolonger
            </b-button>
        </div>
        <div v-else class="d-flex flex-row flex-nowrap">
            <span class="mr-1 mt-1 text-nowrap">Date de départ :</span>
            <b-input-group class="mr-3">
                <date-picker
                    v-model="departureDate"
                    id="departureDate"
                    :config="options"
                ></date-picker>
                <template #append>
                    <b-button-group>
                        <b-button @click="isExtending = false">
                            <b-icon-x-circle-fill />
                        </b-button>
                        <b-button @click="handleExtendAction">
                            <b-icon-check-circle-fill />
                        </b-button>
                    </b-button-group>
                </template>
            </b-input-group>
        </div>
        <div v-if="code.isActive">
            <div v-if="rack">
                <div
                    v-if="$moment(code.departureDate).isBefore()"
                    :class="code.isRfid ? 'rfid-overdue' : 'overdue'"
                >
                    Dépassement: {{ $moment(code.departureDate).toNow(true)
                    }}<br />
                    {{ getComplement }}
                </div>
                <div v-else class="intime text-center">
                    <div v-if="code.codeNumber" class="m-0 p-0">
                        Temps restant :
                        {{ $moment(code.departureDate).fromNow(true) }}
                    </div>
                </div>
                <div class="footer">
                    <div v-if="reachable">
                        <b-button rounded @click="handleOpenAction">
                            Ouvrir
                        </b-button>
                        <b-button rounded @click="handleDetachAction">
                            Libérer
                        </b-button>
                    </div>
                    <div v-else class="text-danger">Arceau indiponible</div>
                </div>
            </div>
            <div v-else class="footer my-2">
                <div
                    v-if="$moment(code.departureDate).isBefore()"
                    class="overdue"
                >
                    <p>Code périmé.</p>
                </div>
                <div v-else>
                    <p>Code actif en attente de saisie sur un arceau.</p>
                </div>
                <b-button rounded @click="handleOpenAction">
                    Ouvrir
                </b-button>
                <b-button rounded @click="handleDeactivateAction">
                    Désactiver
                </b-button>
            </div>
        </div>
        <div v-else class="footer my-2 overdue">
            <p>Code Inactif</p>
            <b-button rounded @click="handleActivateAction">
                Activer
            </b-button>
        </div>
    </b-container>
</template>

<script>
import RackApi from "@/apis/AdminRack.js";
import CodeApi from "@/apis/AdminCode.js";
export default {
    props: ["code", "rack"],
    data() {
        return {
            isExtending: false,
            isAttaching: false,
            departureDate: null,
            options: {
                format: "DD/MM/YYYY HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                toolbarPlacement: "top",
                showTodayButton: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
            },
            selectedRackId: null,
            racks: [],
        };
    },
    methods: {
        async handleOpenAction() {
            const h = this.$createElement;
            // Using HTML string
            const titleVNode = h("div", {
                domProps: { innerHTML: "Ouvrir" },
            });
            // More complex structure
            const messageVNode = h("div", [
                h("p", { class: ["text-center p-3"] }, [
                    `Veuillez confirmer la demande d'ouverture de l'arceau: #${this.rack.id} (${this.rack.name})`,
                ]),
                h("b-img", {
                    props: {
                        src: "/img/detach.png",
                        center: true,
                        fluid: true,
                    },
                }),
                //h("p", { class: ["text-center"] }, [h("b-spinner")]),
            ]);
            const confirmation = await this.$bvModal.msgBoxConfirm(
                [messageVNode],
                {
                    title: [titleVNode],
                    size: "md",
                    buttonSize: "md",
                    okVariant: "warning",
                    okTitle: "Ouvrir",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    hideBackdrop: true,
                    headerClass: "modal-header-" + this.$store.getters.account,
                    contentClass: "shadow-lg",
                }
            );
            if (confirmation) {
                try {
                    if (this.rack.code) {
                        const resUnlink = await CodeApi.unlinkCode(
                            this.rack.code.id,
                            this.rack.id
                        );
                        //console.log(resUnlink);
                        if (resUnlink.data == 1) {
                            this.toast("Message", "Arceau libéré !", false);
                            this.$emit("refresh");
                            this.rack.code = null;
                        } else {
                            this.toast("Erreur", "Arceau non libéré !", true);
                        }
                    }
                    const res = await CodeApi.setAlwaysOpenUntil(
                        this.rack.lock.id
                    );
                    //console.log(res);
                    if (res.data == 1) {
                        this.toast(
                            "Message",
                            "Arceau prêt pour ouverture !",
                            false
                        );
                        this.$emit("refresh");
                    } else {
                        this.toast(
                            "Erreur",
                            "Arceau non prêt pour ouverture !",
                            true
                        );
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            }
        },

        async handleDetachAction() {
            const h = this.$createElement;
            // Using HTML string
            const titleVNode = h("div", {
                domProps: { innerHTML: "Liberer ?" },
            });
            // More complex structure
            const messageVNode = h("div", [
                h("p", { class: ["text-center p-3"] }, [
                    `Veuillez confirmer la demande de libération de l'arceau: #${this.rack.id} (${this.rack.name})`,
                ]),
                h("b-img", {
                    props: {
                        src: "/img/detach.png",
                        center: true,
                        fluid: true,
                    },
                }),
                //h("p", { class: ["text-center"] }, [h("b-spinner")]),
            ]);
            const confirmation = await this.$bvModal.msgBoxConfirm(
                [messageVNode],
                {
                    title: [titleVNode],
                    size: "md",
                    buttonSize: "md",
                    okVariant: "warning",
                    okTitle: "Liberer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    hideBackdrop: true,
                    headerClass: "modal-header-" + this.$store.getters.account,
                    contentClass: "shadow-lg",
                }
            );
            if (confirmation) {
                try {
                    // TODO send action to gateway
                    const res = await CodeApi.unlinkCode(
                        this.code.id,
                        this.rack.id
                    );
                    console.log(res);
                    if (res.data == 1) {
                        this.toast("Message", "Arceau libéré !", false);
                        this.$emit("refresh");
                    } else {
                        this.toast("Erreur", "Arceau non libéré !", true);
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            }
        },

        async handleAttachAction() {
            this.isAttaching = true;
            this.getRacks(this.code.park.id);
        },
        async getRacks(parkId) {
            try {
                const racks = await RackApi.getParkFreeRacks(parkId);
                this.racks = racks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },

        async submitAttachForm() {
            try {
                const response = await CodeApi.linkCode(
                    this.code.id,
                    this.selectedRackId
                );
                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.isAttaching = false;
                    //this.$root.$emit("bv::refresh::table", "code-table");
                    this.$emit("refresh");
                    this.toast("Message", "Code attaché a l'arceau !", false);
                }
            } catch (error) {
                console.log(error);
                this.toast("Erreur!", error.message, true);
            }
        },
        handleExtendButton() {
            this.isExtending = !this.isExtending;
            this.options.minDate = new Date();
            this.departureDate = this.$moment(new Date()).add(10, "minutes");
        },

        async handleActivateAction() {
            try {
                const response = await CodeApi.activateCode(this.code.id);
                console.log(response);
                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.$emit("refresh");

                    this.toast("Message", "Code activé !", false);
                }
            } catch (error) {
                console.log(error);
                this.toast("Erreur!", error.message, true);
            }
        },

        async handleDeactivateAction() {
            try {
                const response = await CodeApi.deactivateCode(this.code.id);
                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.$emit("refresh");

                    this.toast("Message", "Code activé !", false);
                }
            } catch (error) {
                console.log(error);
                this.toast("Erreur!", error.message, true);
            }
        },
        async handleExtendAction() {
            try {
                const res = await CodeApi.extendCode(this.code.id, {
                    date: this.$moment(this.departureDate, "DD/MM/YYYY HH:mm"),
                });
                if (res.data == 1) {
                    this.toast("Prolongé", "Code prolongé !", false);
                    this.$emit("refresh");
                } else {
                    this.toast("Erreur", "Code non prolongé !", true);
                }
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
            this.isExtending = false;
            this.$emit("refresh");
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
    },
    computed: {
        reachable() {
            //console.log("rack", this.rack);
            return this.rack.lock ? this.rack.lock.reachable : false;
        },
        getComplement() {
            return !this.code.isRfid ? "Complèment à payer." : "";
        },
    },
    xmounted() {
        console.log(this.rack);
        console.log(this.code);
    },
};
</script>
