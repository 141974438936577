<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des firmwares</h3>
        <b-row>
            <b-form class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group id="input-id" label="ID :" label-for="id">
                    <b-form-input
                        id="id"
                        v-model="filter.id"
                        class="id-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-name" label="Type :" label-for="name">
                    <b-form-select
                        id="type"
                        v-model="filter.type"
                        :options="types"
                        value-field="type"
                        text-field="name"
                        required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    id="input-hideIsProd"
                    label="En production :"
                    label-for="hideIsProd"
                    class="mx-2"
                >
                    <b-form-checkbox
                        id="hideIsProd"
                        v-model="filter.isProd"
                        name="check-button"
                        switch
                    >
                        {{ filter.isProd ? "Oui" : "Tous" }}
                    </b-form-checkbox>
                </b-form-group>

                <b-button-group class="filter-form-input">
                    <b-button
                        @click="
                            filter.id = null;
                            filter.type = null;
                        "
                        variant="info"
                    >
                        <b-icon-x-circle-fill />
                    </b-button>
                    <b-button variant="info" @click="applyFilter">
                        Filtrer
                    </b-button>
                </b-button-group>
            </b-form>
            <div class="text-right col-xs-12 col-md-6">
                <b-button
                    variant="success"
                    @click="openNewModal"
                    class="button-form-input ml-2"
                    >Ajouter
                </b-button>
            </div>
        </b-row>
        <b-row no-gutters>
            <b-table
                id="firmware-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getFirmwares"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                striped
                sticky-header="calc(100vh - 380px)"
                sort-icon-left
            >
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openUpdateModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="warning"
                        @click="confirmDelete(row.item)"
                    >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalFirmware"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-type"
                        label="Type :"
                        label-for="type"
                    >
                        <b-form-select
                            v-model="form.type"
                            :options="types"
                            value-field="type"
                            text-field="name"
                            required
                        ></b-form-select>
                    </b-form-group>

                    <b-row>
                        <b-form-group
                            id="input-version"
                            label="Version :"
                            label-for="version"
                            class="col-6"
                        >
                            <b-form-input
                                id="version"
                                v-model="form.version"
                                required
                            >
                            </b-form-input>
                        </b-form-group>

                        <b-form-group
                            class="col-6"
                            id="input-isProd"
                            label="En Production :"
                            label-for="isProd"
                        >
                            <b-form-checkbox
                                id="isProd"
                                v-model="form.isProd"
                                name="check-button"
                                switch
                            >
                                {{ form.isProd ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>

                    <b-form-group
                        id="input-releaseNotes"
                        label="Release Notes :"
                        label-for="releaseNotes"
                    >
                        <b-form-textarea
                            id="releaseNotes"
                            v-model="form.releaseNotes"
                            rows="4"
                        ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $bvModal.hide('modalFirmware');
                            applyFilter();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewFirmwareForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateFirmwareForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import FirmwareApi from "@/apis/AdminFirmware.js";
export default {
    data() {
        return {
            firmwares: [],
            form: {
                id: 0,
                type: "",
                version: "",
                isProd: true,
                releaseNotes: "",
            },
            types: [
                { type: 1, name: "Gateway" },
                { type: 2, name: "Keypad" },
                { type: 3, name: "Locker" },
            ],
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                {
                    key: "type",
                    sortable: true,
                    label: "Type",
                    formatter: (value) => {
                        return this.types.find((t) => t.type == value).name;
                    },
                },
                { key: "version", sortable: true, label: "Version" },
                { key: "releaseNotes", sortable: true, label: "Release Notes" },
                {
                    key: "isProd",
                    label: "En Prod",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
            filter: { type: "", id: null, isProd: true },
            previousOrder: {},
        };
    },
    computed: {},
    async mounted() {},
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "firmware-table");
        },
        async getFirmwares(ctx) {
            try {
                let filter = {};
                if (this.filter.type) filter.type = this.filter.type;
                if (this.filter.id) filter.id = this.filter.id;
                if (this.filter.isProd) filter.isProd = this.filter.isProd;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const firmwares = await FirmwareApi.findFirmwares(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );
                if (firmwares.status === 201) {
                    this.firmwares = firmwares.data.items;
                    this.totalRows = firmwares.data.meta.totalItems;
                    this.currentPage = firmwares.data.meta.currentPage;
                    return firmwares.data.items;
                } else {
                    this.toast("Erreur!", firmwares.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openNewModal() {
            this.modalTitle = "Nouveau Firmware";
            this.form = {
                id: 0,
                type: "",
                version: "",
                isProd: true,
                releaseNotes: "",
            };
            this.$bvModal.show("modalFirmware");
        },
        async openUpdateModal(firmware) {
            this.modalTitle = "Editer Firmware";
            this.form = firmware;
            this.$bvModal.show("modalFirmware");
        },
        async confirmDelete(firmware) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression du firmware : " +
                    firmware.name,
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await FirmwareApi.deleteFirmware(firmware.id);
                    this.toast("Message", "Firmware supprimée.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }

                this.$root.$emit("bv::refresh::table", "firmware-table");
            }
        },

        async submitNewFirmwareForm() {
            if (this.form.type && this.form.version) {
                try {
                    const response = await FirmwareApi.createFirmware(
                        this.form
                    );
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$root.$emit(
                            "bv::refresh::table",
                            "firmware-table"
                        );
                        this.$bvModal.hide("modalFirmware");
                        this.toast("Message", "Firmware créée !", false);
                        this.form = {
                            id: 0,
                            type: "",
                            version: "",
                            isProd: true,
                            releaseNotes: "",
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.response.data.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'type' et 'version' sont obligatoires",
                    true
                );
            }
        },

        async submitUpdateFirmwareForm() {
            if (this.form.type && this.form.version) {
                try {
                    const response = await FirmwareApi.updateFirmware(
                        this.form
                    );

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalFirmware");
                        this.toast("Message", "Firmware modifiée !", false);
                        this.$root.$emit(
                            "bv::refresh::table",
                            "firmware-table"
                        ); // TODO not necessary ?
                    }
                } catch (error) {
                    this.toast("Erreur!", error.response.data.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'type' et 'version' sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
