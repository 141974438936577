<template>
    <b-container fluid class="p-3 ">
        <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
            integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
        />
        <h3 class="mt-2">Gestion des codes</h3>
        <b-row>
            <b-form class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group id="input-id" label="ID :" label-for="id">
                    <b-form-input
                        id="id"
                        v-model="filter.id"
                        @keydown.enter="applyFilter"
                        class="id-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-code"
                    label="Code ou Contact:"
                    label-for="code"
                >
                    <b-form-input
                        id="code"
                        v-model="$store.state.search"
                        @keydown.enter="applyFilter"
                        class="name-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <div>
                    <b-button-group class="filter-form-input">
                        <b-button @click="clearFilter" variant="info">
                            <b-icon-x-circle-fill />
                        </b-button>
                        <b-button variant="info" @click="applyFilter">
                            Filtrer
                        </b-button>
                    </b-button-group>
                </div>
            </b-form>
            <div class="text-right col-xs-12 col-md-6">
                <b-button
                    variant="success"
                    @click="openNewModal"
                    class="button-form-input ml-2"
                    >Ajouter
                </b-button>
            </div>
        </b-row>

        <b-row no-gutters>
            <b-table
                id="code-table"
                class="m-2 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getCodes"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="sm"
                striped
                sticky-header="calc(100vh - 380px)"
                sort-icon-left
            >
                <template #cell(actions)="row" class="p-0 text-nowrap">
                    <b-button
                        variant="success"
                        size="sm"
                        @click="openActionsModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-lock v-if="row.item.rack.id == 0" />
                        <b-icon-unlock v-else />
                    </b-button>
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openUpdateModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="warning"
                        @click="confirmDelete(row.item)"
                    >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalActions"
            :title="actionTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-rack"
                        :label="actionLabel"
                        label-for="rack"
                    >
                        <b-input-group v-if="actionForm.actionFree">
                            <b-form-input
                                disabled
                                v-model="actionForm.rack.name"
                            ></b-form-input>
                        </b-input-group>
                        <b-input-group v-else>
                            <b-form-select
                                id="rack"
                                v-model="actionForm.rack.id"
                                :options="racks"
                                value-field="id"
                                text-field="name"
                                @change="actionFormRackChange"
                            ></b-form-select>
                            <!-- <template #append>
                                <b-button
                                    @click="
                                        actionForm.rack = {
                                            id: 0,
                                            name: '',
                                            lock: { id: 0 },
                                        }
                                    "
                                    variant="warning"
                                    ><b-icon-eject
                                /></b-button>
                            </template> -->
                        </b-input-group>

                        <b-row>
                            <strong class="col-xs-12 col-md-12 mt-3">
                                Serrure: {{ actionForm.rack.lock.name }}
                            </strong>
                        </b-row>
                        <b-row>
                            <b-form-group
                                class="col-6"
                                id="input-closed"
                                label="Fermée :"
                                label-for="closed"
                            >
                                <b-form-checkbox
                                    disabled
                                    id="closed"
                                    v-model="actionForm.rack.lock.closed"
                                    name="check-button"
                                    switch
                                >
                                    {{
                                        actionForm.rack.lock.closed
                                            ? "Oui"
                                            : "Non"
                                    }}
                                </b-form-checkbox>
                            </b-form-group>

                            <b-form-group
                                class="col-6"
                                id="input-locked"
                                label="Verrouillée :"
                                label-for="locked"
                            >
                                <b-form-checkbox
                                    disabled
                                    id="locked"
                                    v-model="actionForm.rack.lock.locked"
                                    name="check-button"
                                    switch
                                >
                                    {{
                                        actionForm.rack.lock.locked
                                            ? "Oui"
                                            : "Non"
                                    }}
                                </b-form-checkbox>
                            </b-form-group>
                        </b-row>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="$bvModal.hide('modalActions')"
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        :disabled="actionForm.rack.id == 0"
                        @click="submitActionForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>

        <b-modal
            centered_
            id="modalCode"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-account"
                        label="Compte :"
                        label-for="account"
                    >
                        <b-form-select
                            v-model="form.account.id"
                            :options="accounts"
                            value-field="id"
                            text-field="name"
                            @change="changeAccount"
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-park"
                        label="Parc :"
                        label-for="park"
                    >
                        <b-form-select
                            id="park"
                            v-model="form.park.id"
                            :options="parks"
                            value-field="id"
                            text-field="name"
                            required
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-codeNumber"
                        label="Code :"
                        label-for="codeNumber"
                    >
                        <b-form-input
                            id="codeNumber"
                            v-model="form.codeNumber"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-contact"
                        label="Contact :"
                        label-for="contact"
                    >
                        <b-form-input
                            id="contact"
                            v-model="form.contact"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <!-- <b-form-group
                        id="input-isEmailContact"
                        label="Contact type :"
                        label-for="isEmailContact"
                    >
                        <b-form-checkbox
                            id="isEmailContact"
                            v-model="form.isEmailContact"
                            name="check-button"
                            switch
                        >
                            {{ form.isEmailContact ? "E-mail" : "SMS" }}
                        </b-form-checkbox>
                    </b-form-group>
 -->
                    <b-form-group
                        id="input-arrivalDate"
                        label="Date Arrivée :"
                        label-for="arrivalDate"
                    >
                        <date-picker
                            v-model="form.arrivalDate"
                            id="arrivalDate"
                            :config="options"
                        ></date-picker>
                    </b-form-group>

                    <b-form-group
                        id="input-departureDate"
                        label="Date Départ :"
                        label-for="departureDate"
                    >
                        <date-picker
                            v-model="form.departureDate"
                            id="departureDate"
                            :config="options"
                        ></date-picker>
                    </b-form-group>
                    <b-row
                        ><b-col>
                            <b-form-group
                                id="input-isRfid"
                                label="Code type :"
                                label-for="isRfid"
                            >
                                <b-form-checkbox
                                    id="isRfid"
                                    v-model="form.isRfid"
                                    name="check-button"
                                    switch
                                >
                                    {{ form.isRfid ? "RFID" : "Code" }}
                                </b-form-checkbox>
                            </b-form-group> </b-col
                        ><b-col>
                            <b-form-group
                                id="input-isActive"
                                label="Actif :"
                                label-for="isActive"
                            >
                                <b-form-checkbox
                                    id="isActive"
                                    v-model="form.isActive"
                                    name="check-button"
                                    switch
                                >
                                    {{ form.isActive ? "Oui" : "Non" }}
                                </b-form-checkbox>
                            </b-form-group></b-col
                        ></b-row
                    >
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $bvModal.hide('modalCode');
                            applyFilter();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewCodeForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateCodeForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import ParkApi from "@/apis/AdminPark.js";
import RackApi from "@/apis/AdminRack.js";
import CodeApi from "@/apis/AdminCode.js";
import AccountApi from "@/apis/AdminAccount.js";
export default {
    data() {
        return {
            racks: [],
            codes: [],
            accounts: [],
            parks: [],
            actionForm: {
                actionFree: null,
                rack: {
                    id: 0,
                    name: "",
                    lock: { id: 0, name: "", closed: false, locked: false },
                },
                code: { id: 0, codeNumber: "" },
            },
            form: {
                id: 0,
                codeNumber: "",
                contact: "",
                isEmailContact: true,
                arrivalDate: null,
                departureDate: null,
                expirationDate: new Date(),
                isRfid: false,
                rfidDetails: "",
                isActive: true,
                park: { id: 0, name: "" },
                account: { id: 0, name: "" },
            },
            modalTitle: "",
            actionTitle: "",
            actionLabel: null,
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "codeNumber", sortable: true, label: "Code" },
                { key: "contact", sortable: true, label: "Contact" },
                /* {
                    key: "isEmailContact",
                    label: "Contact type",
                    formatter: (value) => {
                        return value ? "Email" : "SMS";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                }, */
                {
                    key: "arrivalDate",
                    label: "Date d'arrivée",
                    sortable: true,
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                },
                {
                    key: "departureDate",
                    label: "Date de départ",
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                    sortable: true,
                },
                /* {
                    key: "expirationDate",
                    label: "Date limite",
                    sortable: true,
                }, */
                {
                    key: "isRfid",
                    label: "Code type",
                    formatter: (value) => {
                        return value ? "RFID" : "Code";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                { key: "park.name", label: "Parc", sortable: false },
                { key: "account.name", label: "Compte", sortable: false },
                { key: "rack.name", label: "Arceau", sortable: false },
                /* { key: "rfidDetails", sortable: true, label: "Détails RFID" },
                 */ {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
            filter: { codeNumber: "", id: null },
            previousOrder: {},
            options: {
                format: "DD/MM/YYYY HH:mm",
                useCurrent: false,
                showClear: true,
                showClose: true,
                toolbarPlacement: "top",
                showTodayButton: true,
                icons: {
                    time: "far fa-clock",
                    date: "far fa-calendar",
                    up: "fas fa-arrow-up",
                    down: "fas fa-arrow-down",
                    previous: "fas fa-chevron-left",
                    next: "fas fa-chevron-right",
                    today: "fas fa-calendar-check",
                    clear: "far fa-trash-alt",
                    close: "far fa-times-circle",
                },
            },
        };
    },
    computed: {},
    async created() {
        this.$root.$on("search", () => {
            this.applyFilter();
        });
    },
    async beforeMount() {
        await this.getAccounts();
    },
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "code-table");
        },
        clearFilter() {
            this.filter = { codeNumber: "", id: null };
            this.$store.state.search = null;
        },
        async getCodes(ctx) {
            //console.log(ctx);
            try {
                let filter = {};
                if (this.$store.state.search)
                    filter.search = this.$store.state.search;
                if (this.filter.id) filter.id = this.filter.id;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const codes = await CodeApi.findCodes(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );
                if (codes.status === 201) {
                    this.codes = codes.data.items;
                    this.totalRows = codes.data.meta.totalItems;
                    this.currentPage = codes.data.meta.currentPage;
                    return codes.data.items;
                } else {
                    this.toast("Erreur!", codes.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getRacks(parkId) {
            //TODO filter by account
            try {
                const racks = await RackApi.getParkFreeRacks(parkId);
                this.racks = racks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getParks() {
            try {
                const parks = await ParkApi.getAccountParks(
                    this.form.account.id
                );
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getAccounts() {
            try {
                const accounts = await AccountApi.getAccounts();
                this.accounts = accounts.data;
            } catch (err) {
                this.toast("Erreur!", err, true);
            }
        },
        async changeAccount() {
            await this.getParks();
            this.form.park.id = 0;
            this.form.park.name = "";
            /* this.form.rack.id = 0;
            this.form.rack.name = ""; */
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openNewModal() {
            this.modalTitle = "Nouveau code";
            this.form = {
                id: 0,
                codeNumber: "",
                contact: "",
                isEmailContact: true,
                arrivalDate: null,
                departureDate: null,
                expirationDate: new Date(),
                isRfid: false,
                rfidDetails: "",
                isActive: true,
                park: { id: 0, name: "" },
                account: { id: 0, name: "" },
            };
            this.$bvModal.show("modalCode");
        },
        async openUpdateModal(code) {
            this.modalTitle = "Editer code";

            //console.log(code);
            this.form = code;
            await this.getParks();
            this.form.arrivalDate = this.$moment(code.arrivalDate);
            this.form.departureDate = this.$moment(code.departureDate);

            this.$bvModal.show("modalCode");
        },
        async openActionsModal(code) {
            //console.log(code);

            this.actionForm.code = code;
            const rack = await RackApi.getCodeRack(code.id);
            if (rack.data) {
                console.log("rack", rack);
                this.actionForm.rack = rack.data;
                this.actionTitle = "Libération";
                this.actionLabel = "Libérer le code de l'arceau :";
                this.actionForm.actionFree = true;
            } else {
                this.actionForm.rack = { id: 0, lock: {} };
                this.actionTitle = "Association";
                this.actionForm.actionFree = false;
                this.actionLabel = "Associer le code à l'arceau :";
            }

            this.getRacks(this.actionForm.code.park.id);
            this.$bvModal.show("modalActions");
        },
        async actionFormRackChange(rackId) {
            console.log(rackId, this.actionForm);
            const rack = await RackApi.getRack(rackId);
            if (rack.data) {
                console.log("rack", rack);
                this.actionForm.rack = rack.data;
                if (!rack.data.lock) {
                    this.actionForm.rack.lock = { id: 0, name: "" };
                }
            } else {
                this.actionForm.rack = { id: 0, lock: {} };
            }
        },
        async submitActionForm() {
            console.log(this.actionForm.rack.id);
            try {
                const response = this.actionForm.actionFree
                    ? await CodeApi.unlinkCode(
                          this.actionForm.code.id,
                          this.actionForm.rack.id
                      )
                    : await CodeApi.linkCode(
                          this.actionForm.code.id,
                          this.actionForm.rack.id
                      );
                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.$root.$emit("bv::refresh::table", "code-table");
                    this.$bvModal.hide("modalActions");
                    this.toast("Message", "Code action effectuée !", false);
                }
            } catch (error) {
                console.log(error);
                this.toast("Erreur!", error.message, true);
            }
        },
        async confirmDelete(code) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression du code : " +
                    code.codeNumber,
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await CodeApi.deleteCode(code.id);
                    this.toast("Message", "Code supprimé.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }

                this.$root.$emit("bv::refresh::table", "code-table");
            }
        },

        async submitNewCodeForm() {
            if (this.form.codeNumber && this.form.contact) {
                try {
                    /* this.form.arrivalDate = this.$moment(
                        this.form.arrivalDate,
                        "DD/MM/YYYY HH:mm"
                    );
                    this.form.departureDate = this.$moment(
                        this.form.departureDate,
                        "DD/MM/YYYY HH:mm"
                    ); */
                    const createCode = {
                        id: this.form.id,
                        codeNumber: this.form.codeNumber,
                        contact: this.form.contact,
                        isEmailContact: false,
                        arrivalDate: this.$moment(
                            this.form.arrivalDate,
                            "DD/MM/YYYY HH:mm"
                        ),
                        departureDate: this.$moment(
                            this.form.departureDate,
                            "DD/MM/YYYY HH:mm"
                        ),
                        expirationDate: new Date(),
                        isRfid: this.form.isRfid,
                        rfidDetails: this.form.rfidDetails,
                        isActive: this.form.isActive,
                        parkId: this.form.park.id,
                        accountId: this.form.account.id,
                    };
                    const response = await CodeApi.createCode(createCode);
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$root.$emit("bv::refresh::table", "code-table");
                        this.$bvModal.hide("modalCode");
                        this.toast("Message", "Code créé !", false);
                        this.form = {
                            id: 0,
                            codeNumber: "",
                            contact: "",
                            isEmailContact: true,
                            arrivalDate: null,
                            departureDate: null,
                            expirationDate: new Date(),
                            isRfid: false,
                            rfidDetails: "",
                            isActive: true,
                            park: { id: 0, name: "" },
                            account: { id: 0, name: "" },
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom' et 'contact' sont obligatoires",
                    true
                );
            }
        },

        async submitUpdateCodeForm() {
            //TODO eject rack on park change
            if (this.form.codeNumber && this.form.contact) {
                try {
                    /*  this.form.arrivalDate = this.$moment(
                        this.form.arrivalDate,
                        "DD/MM/YYYY HH:mm"
                    );
                    this.form.departureDate = this.$moment(
                        this.form.departureDate,
                        "DD/MM/YYYY HH:mm"
                    ); */
                    const updateCode = {
                        id: this.form.id,
                        codeNumber: this.form.codeNumber,
                        contact: this.form.contact,
                        isEmailContact: false,
                        arrivalDate: this.$moment(
                            this.form.arrivalDate,
                            "DD/MM/YYYY HH:mm"
                        ),
                        departureDate: this.$moment(
                            this.form.departureDate,
                            "DD/MM/YYYY HH:mm"
                        ),
                        expirationDate: new Date(),
                        isRfid: this.form.isRfid,
                        rfidDetails: this.form.rfidDetails,
                        isActive: this.form.isActive,
                        parkId: this.form.park.id,
                        accountId: this.form.account.id,
                    };
                    const response = await CodeApi.updateCode(updateCode);

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalCode");
                        this.toast("Message", "Code modifié !", false);
                        this.$root.$emit("bv::refresh::table", "code-table"); // TODO not necessary
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom' et 'contact' sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
