import axios from "axios";
import router from "../router/index";
import store from "../store/index";

let baseApi = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

baseApi.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (error.response && 401 === error.response.status) {
            if (
                router.currentRoute.path !==
                "/" + store.state.account + "/login"
            )
                router.push("/" + store.state.account + "/login");
            return Promise.resolve(error.response);
        } else {
            return Promise.reject(error);
        }
    }
);

let Api = function() {
    let token = localStorage.getItem("token");
    baseApi.defaults.headers.common["Accept"] = "*/*";
    if (token) {
        baseApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    return baseApi;
};

export default Api;
