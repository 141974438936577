import Api from "./Api";
export default {
    findLocks(paginationOptions, findOptions) {
        return Api().post(
            "/locks/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getLock(id) {
        return Api().get("/locks/admin/" + id);
    },
    findLock(id) {
        return Api().get("/locks/" + id);
    },
    getLocks() {
        return Api().get("/locks/admin");
    },
    getFreeLocks() {
        return Api().get("/locks/admin/free");
    },
    createLock(payload) {
        return Api().post("/locks/admin", payload);
    },
    updateLock(payload) {
        return Api().patch("/locks/admin/" + payload.id, payload);
    },
    deleteLock(id) {
        return Api().delete("/locks/admin/" + id);
    },
};
