<script>
//import User from "../apis/User";
//import VueCharts from "vue-chartjs";
import { Bar } from "vue-chartjs";
import CodeApi from "@/apis/AdminCode.js";

export default {
    props: ["sinceDate", "parkId", "account"],
    extends: Bar,
    async mounted() {
        // Overwriting base render method with actual data.
        const statsData = await CodeApi.statsTypes(
            this.account,
            this.parkId,
            this.$moment(this.sinceDate).format("YYYYMMDD")
        );
        const stats = statsData.data;
        let subscriberRatio = Math.round(
            (stats.subscribers / (stats.subscribers + stats.visitors)) * 100
        );
        let visitorRatio = 100 - subscriberRatio;

        this.renderChart(
            {
                labels: ["Visiteurs", "Abonnés"],
                datasets: [
                    {
                        label: " % ",
                        backgroundColor: ["#59a0cfa9", "#146aa3a9"],
                        borderColor: ["#59a0cf00", "#59a0cf00"],

                        borderWidth: 1,
                        data: [visitorRatio, subscriberRatio, 100],
                    },
                ],
                start: 0,
            },
            {
                animation: {
                    duration: 3000,
                    onComplete: function() {
                        let chartInstance = this.chart,
                            ctx = chartInstance.ctx;

                        ctx.font = "bold 14px Overpass";
                        ctx.textAlign = "center";
                        ctx.textBaseline = "bottom";

                        this.data.datasets.forEach(function(dataset, i) {
                            let meta = chartInstance.controller.getDatasetMeta(
                                i
                            );
                            meta.data.forEach(function(bar, index) {
                                let data = dataset.data[index];
                                ctx.fillText(data, bar._model.x, bar._model.y);
                            });
                        });
                    },
                },
                scales: {
                    yAxes: [
                        {
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
                indexAxis: "y",
                title: {
                    display: true,
                    text: "Visiteurs vs Abonnés",
                    fontSize: 18,
                    fontFamily: "Overpass",
                    fontStyle: "normal",
                },
                legend: {
                    display: true,
                    labels: {
                        fontColor: "#333333",
                    },
                },
            }
        );
    },
};
</script>
