<template>
  <b-container fluid class="d-flex flex-column" id="dashboardAlerts">
    <h5 class="pb-3 border-bottom border-dark text-dark">
      Alertes
    </h5>

    <div id="rfid-status" v-if="rfidServers.length">
      <b-row class="mb-2">
        <b-col class="alerts-title">Validation RFID indisponible</b-col>
        <b-col><span class="error-status">bloquant</span></b-col>
      </b-row>
      <b-row
        class="mt-2"
        v-for="(rfidServer, index) in rfidServers"
        :key="index"
      >
        <b-col>
          <span class="info-status">{{ rfidServer.name }}</span>
        </b-col>
        <b-col>
          <span
            id="rfid-seen"
            v-b-tooltip.hover
            :title="
              $moment(rfidServer.lastConnection).format(
                'dddd DD MMMM YYYY à HH:mm:ss'
              )
            "
          >
            {{ rfidServer.lastConnection | moment("from") }}
          </span>
        </b-col>
      </b-row>
    </div>

    <div id="gateway-status" v-if="gateways.length">
      <b-row class="mb-2">
        <b-col class="alerts-title">{{ gatewayAlertsTitle }}</b-col>
        <b-col><span class="error-status">bloquant</span></b-col>
      </b-row>
      <b-row v-for="gateway in gateways" :key="gateway.id" class="mt-2">
        <b-col>
          <span class="info-status">{{ gateway.name }}</span
          ><span class="info-status ml-1">{{ gateway.park }}</span>
        </b-col>
        <b-col>
          <span
            id="gateway-poll"
            v-b-tooltip.hover
            :title="
              $moment(gateway.lastSeen).format('dddd DD MMMM YYYY à HH:mm:ss')
            "
            >{{ gateway.lastSeen | moment("from") }}
          </span>
        </b-col>
      </b-row>
    </div>
    <div id="planted-status" v-if="planteds.length">
      <b-row class="mb-2">
        <b-col class="alerts-title">{{ plantedAlertsTitle }} </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <div v-for="pp in plantedParks" :key="pp.delay" class="mb-2">
            <span class="warning-status">
              {{ pp.name }}
            </span>
            <span class="warning-status ml-1">
              &gt; {{ pp.delay }} heures
            </span>
          </div>
        </b-col>
        <b-col class="text-right col-3">
          <b-icon-eye @click="openPlantedModal" class="alerts-warning-icon" />
        </b-col>
      </b-row>
    </div>
    <div id="failures-status" v-if="failuresCount">
      <b-row class="mb-2">
        <b-col class="alerts-title">{{ failuresAlertsTitle }} </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col><span class="error-status">Securité</span></b-col>
        <b-col class="text-right">
          <b-icon-eye @click="openFailuresModal" class="alerts-error-icon" />
        </b-col>
      </b-row>
    </div>

    <b-modal
      scrollable
      centered
      id="modalPlanted"
      title="Vélos ventouse"
      hide-backdrop
      size="lg"
      :header-class="'modal-header-' + $store.getters.account"
      content-class="shadow-lg"
      body-class="stripped m-2"
      ><table class=" w-100">
        <tr>
          <th class="px-1 pb-1 pt-2 text-nowrap">Parc</th>
          <th class="px-1 pb-1 pt-2 text-nowrap">Arceau</th>
          <th class="px-1 pb-1 pt-2 text-nowrap">Contact</th>
          <th class="px-1 pb-1 pt-2 text-nowrap">Arrivé le</th>
        </tr>
        <tr v-for="planted in planteds" :key="planted.id">
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ planted.park.name }}
          </td>
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ planted.name }}
          </td>
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ planted.code.contact }}
          </td>
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ planted.code.arrivalDate | moment("DD/MM/YYYY HH:mm") }}
          </td>
        </tr>
      </table>

      <template v-slot:modal-footer>
        <b-container fluid class="d-flex justify-content-center">
          <b-button @click="$bvModal.hide('modalPlanted')">Fermer</b-button>
        </b-container>
      </template>
    </b-modal>
    <b-modal
      scrollable
      centered
      id="modalFailures"
      title="Tentatives d'accès"
      hide-backdrop
      size="xl"
      content-class="shadow-lg"
      body-class="stripped m-2 h-100"
      :header-class="'modal-header-' + $store.getters.account"
      ><table class=" w-100">
        <tr>
          <th class="px-1 pb-1 pt-2 text-nowrap">Date</th>
          <th class="px-1 pb-1 pt-2 text-nowrap">Action</th>
          <th class="px-1 pb-1 pt-2 text-nowrap">Arceau</th>
          <th colspan="2" class="px-1 pb-1 pt-2 text-nowrap">
            Contact
          </th>
          <th class="px-1 pb-1 pt-2 text-nowrap">Status</th>
        </tr>
        <tr v-for="failure in failures" :key="failure.id">
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ failure.created_at | moment("DD/MM/YYYY HH:mm:ss") }}
          </td>
          <td class="px-1 pb-1 pt-2 text-nowrap">
            <span :class="getActionClass(failure)" class="journal-action">{{
              actionText(failure.action)
            }}</span>
          </td>
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ failure.rack.name }}
          </td>
          <td class="pt-1">
            <span v-if="failure.contactType == 0">
              <b-icon-credit-card variant="info" class="contact-type" />
            </span>
            <span v-else-if="failure.contactType == 1">
              <b-icon-broadcast variant="info" class="success-check" />
            </span>
            <span v-else-if="failure.contactType == 2">
              <b-icon-keyboard variant="info" class="contact-type" />
            </span>
            <span v-else>{{ failure.contactType }}</span>
          </td>
          <td class="px-1 pb-1 pt-2 text-nowrap">
            {{ failure.contact }}
          </td>

          <td class="px-1 pb-1 pt-2 text-nowrap">
            <span class="journal-status-error">{{
              statusText(failure.status)
            }}</span>
          </td>
        </tr>
        <infinite-loading
          slot="append"
          @infinite="infiniteHandler"
          force-use-infinite-wrapper="#modalFailures___BV_modal_body_"
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading>
      </table>

      <template v-slot:modal-footer>
        <b-container fluid class="d-flex justify-content-center">
          <b-button @click="$bvModal.hide('modalFailures')">Fermer</b-button>
        </b-container>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
//import User from "../apis/User";
import GatewayApi from "@/apis/AdminGateway.js";
import RackApi from "@/apis/AdminRack.js";
import CodeApi from "@/apis/AdminCode.js";
import InfiniteLoading from "vue-infinite-loading";

import {
  ActionText,
  ContactType,
  StatusText,
  JournalAction,
} from "../AdminJournal.vue";

export default {
  props: ["sinceDate", "parkId", "account"],
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      gateways: [],
      planteds: [],
      failures: [],
      plantedParks: [],
      rfidServers: [],
      page: 1,
      rows: 30,
      failuresCount: 0,
    };
  },
  async mounted() {
    const rfidServers = await GatewayApi.getRfidHandlerError(this.account);
    this.rfidServers = rfidServers.data;

    const response = this.parkId
      ? await GatewayApi.getParkGateways(this.parkId)
      : await GatewayApi.getGatewaysByAccountName(this.account);
    response.data.forEach((g) => {
      if (g.lastPolling) {
        var duration = this.$moment(new Date()).diff(
          this.$moment(g.lastPolling),
          "minutes"
        );
        if (duration > 2) {
          this.gateways.push({
            id: g.id,
            name: g.name,
            park: g.park.name,
            lastSeen: new Date(g.lastPolling),
          });
        }
      }
    });

    const plantedResponse = await RackApi.getPlanted(this.account, this.parkId);
    this.plantedParks = [];
    this.planteds = plantedResponse.data;

    this.planteds.forEach((p) => {
      var plantedPark = { name: p.park.name, delay: p.park.plantedDelay };
      var found = this.plantedParks.find((pp) => pp.name == p.park.name);
      if (!found) this.plantedParks.push(plantedPark);
    });
    //console.log(this.plantedParks);
    //console.log(plantedResponse.data);

    const failuresCountResponse = await CodeApi.getAccessFailuresCount(
      this.account,
      this.parkId,
      this.$moment(this.sinceDate).format("YYYYMMDD")
    );
    //console.log(failuresCountResponse);
    this.failuresCount = failuresCountResponse.data;
    /* 
        if (this.failuresCount) {
            const failuresResponse = await CodeApi.getAccessFailures(
                this.account,
                this.parkId,
                this.$moment(this.sinceDate).format("YYYYMMDD"),
                this.page,
                this.rows
            );
            this.failures = failuresResponse.data;
        } */

    //console.log(failuresResponse.data);
    //console.log(JournalAction);
  },
  methods: {
    async infiniteHandler($state) {
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      const failuresResponse = await CodeApi.getAccessFailures(
        this.account,
        this.parkId,
        this.$moment(this.sinceDate).format("YYYYMMDD"),
        this.page,
        this.rows
      );
      if (failuresResponse.data.length) {
        this.failures.push(...failuresResponse.data);
        await delay(500);
        this.page += 1;
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    openPlantedModal() {
      this.$bvModal.show("modalPlanted");
    },
    openFailuresModal() {
      this.$bvModal.show("modalFailures");
    },
    actionText(action) {
      return ActionText[action];
    },
    contactType(type) {
      return ContactType[type];
    },
    statusText(status) {
      return StatusText[status];
    },
    getActionClass(journal) {
      let actionClass = "";
      if (journal) {
        switch (journal.action) {
          case JournalAction.BUY:
          case JournalAction.BUY_EXTRA:
            actionClass = "action-buy";
            break;

          case JournalAction.VISITOR_EXTEND:
          case JournalAction.ADMIN_EXTEND:
          case JournalAction.ADMIN_ATTACH:
          case JournalAction.ADMIN_DETACH:
            actionClass = "action-detach";
            break;
          case JournalAction.ATTACH:
            actionClass = "action-attach";
            break;
          case JournalAction.DETACH:
            actionClass = "action-detach";
            break;
        }
        return actionClass;
      }
    },
  },
  computed: {
    gatewayAlertsTitle() {
      return this.gateways.length > 1
        ? "Gateways absentes: "
        : "Gateway absente: ";
    },
    plantedAlertsTitle() {
      return this.planteds.length > 1
        ? "Vélos ventouse: " + this.planteds.length + " vélos"
        : "Velos ventouse: " + this.planteds.length + " vélo";
    },
    failuresAlertsTitle() {
      return this.failuresCount > 1
        ? this.failuresCount + " tentatives d'accès"
        : this.failuresCount + " tentative d'accès";
    },
  },
};
</script>
