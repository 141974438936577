<template>
    <div class="lock-status">
        <div>
            <div class="mr-2"><strong>Serrure:</strong></div>
            <span>{{ lock.name }} ({{ lock.id }})</span>
        </div>
        <div>
            <div class="mr-2"><strong>Fermée:</strong></div>
            <b-icon-lock v-if="lock.closed" variant="warning" class="mr-2" />
            <b-icon-unlock v-else variant="success" class="mr-2" />
            {{ lock.closed ? "Oui" : "Non" }}
        </div>
        <div>
            <div class="mr-2"><strong>Vérrouillée:</strong></div>
            <span v-b-tooltip.hover title="En attente d'ouverture">
                <b-icon-alarm
                    v-if="lock.isPendingDetach"
                    variant="info"
                    class="mr-1"
                />
            </span>
            <span v-b-tooltip.hover title="En attente de fermeture">
                <b-icon-alarm-fill
                    v-if="lock.isPendingAttach"
                    variant="info"
                    class="mr-1"
                />
            </span>
            <b-icon-lock v-if="lock.locked" variant="warning" class="mr-2" />
            <b-icon-unlock v-else variant="success" class="mr-2" />
            {{ lock.locked ? "Oui" : "Non" }}
        </div>
    </div>
</template>
<script>
export default {
    props: ["lock"],
};
</script>
