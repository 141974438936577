<template>
    <b-container fluid class="p-3 "
        ><div class="d-flex flex-row flex-wrap justify-content-between">
            <h3 class="mt-2">
                {{ park.name }} - {{ park.account.description }}
            </h3>
            <div class="text-right m-2">
                <b-button variant="success" @click="openModalBulkCreate"
                    >Créer des arceaux et serrures
                </b-button>
            </div>
        </div>
        <park :parkId="$route.params.park" :key="updateKey"></park>

        <b-modal
            size="md"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
            id="modalBulkCreate"
            :title="park.name + ' : Création par lots'"
        >
            <b-container class="container">
                <bulk-create :park="park" ref="bulkCreate"></bulk-create>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="$bvModal.hide('modalBulkCreate')"
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        variant="success"
                        @click="submitBulkCreate"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import Park from "../park.vue";
import ParkApi from "../../apis/AdminPark";
import BulkCreate from "./SuperAdminBulkCreate.vue";

export default {
    components: {
        Park,
        BulkCreate,
    },
    data() {
        return {
            updateKey: 0,
            park: { account: {} },
        };
    },
    methods: {
        submitBulkCreate() {
            this.$refs.bulkCreate.submitBulkCreate();
            this.$bvModal.hide("modalBulkCreate");
            this.updateKey += 1;
        },
        async openModalBulkCreate() {
            this.$bvModal.show("modalBulkCreate");
        },
        async getPark(parkId) {
            try {
                const park = await ParkApi.getPark(parkId);

                if (park.status === 200) {
                    this.park = park.data;
                } else {
                    this.toast("Erreur!", park.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
    },
    beforeMount() {
        this.getPark(this.$route.params.park);
    },
};
</script>
