import Api from "./Api";
export default {
    findGateways(paginationOptions, findOptions) {
        return Api().post(
            "/gateway/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getGateway(id) {
        return Api().get("/gateway/admin/" + id);
    },
    getAccountGateways(id) {
        return Api().get("/gateway/account/" + id);
    },
    getGatewaysByAccountName(account) {
        return Api().get("/gateway/account/name/" + account);
    },
    getParkGateways(id) {
        return Api().get("/gateway/park/" + id);
    },
    getGateways() {
        return Api().get("/gateway");
    },
    createGateway(payload) {
        return Api().post("/gateway/admin", payload);
    },
    updateGateway(payload) {
        return Api().patch("/gateway/admin/" + payload.id, payload);
    },
    deleteGateway(id) {
        return Api().delete("/gateway/admin/" + id);
    },
    getRfidHandlerError(account) {
        return Api().get("/gateway/rfid/" + account);
    },
};
