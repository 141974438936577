<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des arceaux</h3>
        <b-row>
            <b-col md="6" xs="12" class="d-flex flex-row">
                <b-form-group id="input-id" label="ID :" label-for="id">
                    <b-form-input
                        id="id"
                        v-model="filter.id"
                        class="id-form-input mr-2"
                        @keydown.enter="applyFilter"
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="input-name" label="Nom :" label-for="name">
                    <b-form-input
                        id="name"
                        v-model="filter.name"
                        class="name-form-input mr-2"
                        @keydown.enter="applyFilter"
                    ></b-form-input>
                </b-form-group>
                <div>
                    <b-button-group class="filter-form-input">
                        <b-button @click="clearFilter" variant="info">
                            <b-icon-x-circle-fill />
                        </b-button>
                        <b-button variant="info" @click="applyFilter">
                            Filtrer
                        </b-button>
                    </b-button-group>
                </div>
            </b-col>
            <b-col md="6" xs="12" class="text-right">
                <b-button
                    variant="success"
                    @click="handleDeliveryNote"
                    class="button-form-input mr-2"
                    >Générer un bon de livraison
                </b-button>
                <b-button
                    variant="success"
                    @click="openNewModal"
                    class="button-form-input"
                    >Ajouter un Arceau
                </b-button>
            </b-col>
        </b-row>

        <b-row no-gutters>
            <b-table
                id="rack-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getRacks"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                striped
                sort-icon-left
                sticky-header="calc(100vh - 380px)"
            >
                <template #cell(lock)="row">
                    <b-link
                        v-if="row.item.lock"
                        @click="openRackLockModal(row.item.lock.id)"
                    >
                        {{ row.item.lock.name }} ({{ row.item.lock.id }})
                    </b-link>
                </template>
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openUpdateModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="warning"
                        @click="confirmDelete(row.item)"
                    >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalRack"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Nom :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-description"
                        label="Description :"
                        label-for="description"
                    >
                        <b-form-input
                            id="description"
                            v-model="form.description"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-account"
                        label="Compte :"
                        label-for="account"
                    >
                        <b-form-select
                            id="account"
                            v-model="form.account.id"
                            :options="accounts"
                            value-field="id"
                            text-field="name"
                            required
                            @change="onAccountChange"
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-park"
                        label="Parc :"
                        label-for="park"
                    >
                        <b-form-select
                            id="park"
                            v-model="form.park.id"
                            :options="parks"
                            value-field="id"
                            text-field="name"
                            required
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-lock"
                        label="Serrure :"
                        label-for="lock"
                        ><b-input-group>
                            <b-form-select
                                id="lock"
                                v-model="form.lock.id"
                                :options="locks"
                                value-field="id"
                                text-field="name"
                                required
                            ></b-form-select>
                            <template #append>
                                <b-button
                                    @click="form.lock = { id: 0, name: '' }"
                                    variant="warning"
                                    ><b-icon-eject
                                /></b-button>
                            </template>
                        </b-input-group>
                    </b-form-group>

                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-pos-l"
                            label="Position X :"
                            label-for="pos-l"
                        >
                            <b-form-input
                                id="pos-l"
                                v-model="form.latLng[0]"
                                required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group
                            class="col-6"
                            id="input-pos-h"
                            label="Position Y :"
                            label-for="pos-h"
                        >
                            <b-form-input
                                id="pos-h"
                                v-model="form.latLng[1]"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-form-group
                        id="input-isActive"
                        label="Actif :"
                        label-for="isActive"
                    >
                        <b-form-checkbox
                            id="isActive"
                            v-model="form.isActive"
                            name="check-button"
                            switch
                        >
                            {{ form.isActive ? "Oui" : "Non" }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button variant="info" @click="handleCancel"
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewRackForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateRackForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>

        <b-modal
            centered_
            id="modalDelivery"
            size="lg"
            title="Bon de livraison"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
            :scrollable="true"
        >
            <b-container class="container">
                <b-row>
                    <b-form-group
                        cols="3"
                        id="input-batch"
                        label="Numéro du lot :"
                        label-for="batchName"
                    >
                        <b-input-group>
                            <b-form-input
                                id="batchName"
                                v-model="batchNumber"
                                required
                                v-on:keyup.enter="getBatchRacks"
                            >
                            </b-form-input>
                            <template #append>
                                <b-button-group>
                                    <b-button @click="getBatchRacks">
                                        <b-icon-check-circle-fill />
                                    </b-button>
                                </b-button-group>
                            </template>
                        </b-input-group>
                    </b-form-group>
                </b-row>
                <delivery-note
                    id="printMe"
                    :racks="batchRacks"
                    :batchNumber="batchNumber"
                    :batchAccount="batchAccount"
                ></delivery-note>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="$bvModal.hide('modalDelivery')"
                        >Annuler</b-button
                    >

                    <b-button
                        v-print="printObj"
                        class="ml-3"
                        @click="printBatchDelivery()"
                        variant="success"
                        >Imprimer</b-button
                    >
                </b-container>
            </template>
        </b-modal>

        <b-modal
            centered_
            id="modalRackLock"
            :title="'Editer Serrure ' + lockForm.id"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Nom :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="lockForm.name"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-closed"
                            label="Fermée :"
                            label-for="closed"
                        >
                            <b-form-checkbox
                                id="closed"
                                v-model="lockForm.closed"
                                name="check-button"
                                switch
                                disabled
                            >
                                {{ lockForm.closed ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>

                        <b-form-group
                            class="col-6"
                            id="input-locked"
                            label="Verrouillée :"
                            label-for="locked"
                        >
                            <b-form-checkbox
                                id="locked"
                                v-model="lockForm.locked"
                                name="check-button"
                                switch
                                disabled
                            >
                                {{ lockForm.locked ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>

                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-reachable"
                            label="Disponible :"
                            label-for="reachable"
                        >
                            <b-form-checkbox
                                id="reachable"
                                v-model="lockForm.reachable"
                                name="check-button"
                                switch
                            >
                                {{ form.reachable ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-isActive"
                            label="Actif :"
                            label-for="isActive"
                        >
                            <b-form-checkbox
                                id="isActive"
                                v-model="lockForm.isActive"
                                name="check-button"
                                switch
                            >
                                {{ form.isActive ? "Oui" : "Non" }}
                            </b-form-checkbox>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-version"
                            label="Version :"
                            label-for="version"
                        >
                            <b-form-input
                                id="version"
                                v-model="lockForm.version"
                                required
                                readonly
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-target-version"
                            label="Version Cible :"
                            label-for="target-version"
                        >
                            <b-form-input
                                id="target-version"
                                v-model="lockForm.targetVersion"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-lock-version"
                            label="Lock Version :"
                            label-for="lock-version"
                        >
                            <b-form-input
                                id="lock-version"
                                v-model="lockForm.lockVersion"
                                required
                                readonly
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-target-lock-version"
                            label="Lock Version Cible :"
                            label-for="target-lock-version"
                        >
                            <b-form-input
                                id="target-lock-version"
                                v-model="lockForm.targetLockVersion"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-6"
                            id="input-lock-wifissid"
                            label="WIFI SSID :"
                            label-for="lock-wifissid"
                        >
                            <b-form-input
                                id="lock-wifissid"
                                v-model="lockForm.wifiSSID"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            class="col-6"
                            id="input-lock-wifipwd"
                            label="Lock WIFI PWD :"
                            label-for="lock-wifipwd"
                        >
                            <b-form-input
                                id="lock-wifipwd"
                                v-model="lockForm.wifiPWD"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-form-group
                        id="input-traceability"
                        label="Traçabilité :"
                        label-for="traceability"
                    >
                        <b-form-textarea
                            id="traceability"
                            v-model="lockForm.traceability"
                            rows="2"
                        ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $store.state.foundAndDisplayed = true;
                            $bvModal.hide('modalRackLock');
                            applyFilter();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        @click="submitUpdateLockForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import ParkApi from "@/apis/AdminPark.js";
import RackApi from "@/apis/AdminRack.js";
import LockApi from "@/apis/AdminLock.js";
import UserApi from "@/apis/AdminAccount.js";
import print from "vue-print-nb";

import DeliveryNote from "./DeliveryNote.vue";

export default {
    directives: {
        print,
    },
    components: {
        DeliveryNote,
    },
    data() {
        return {
            parks: [],
            locks: [],
            accounts: [],
            racks: [],
            form: {
                id: 0,
                name: "",
                description: "",
                isActive: true,
                latLng: [0, 0],
                account: { id: 0, name: "" },
                lock: { id: 0, name: "" },
                park: { id: 0, name: "" },
            },
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "name", sortable: true, label: "Nom" },
                { key: "description", sortable: true },
                { key: "account.name", label: "Compte", sortable: false },
                { key: "park.name", label: "Parc", sortable: false },
                { key: "lock", label: "Serrure" },
                //{ key: "lock.name", label: "Serrure", sortable: false },
                {
                    key: "latLng",
                    label: "Position plan",
                    sortable: false,
                    formatter: (value) => {
                        return value.toString();
                    },
                },
                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 50,
            totalRows: null,
            filter: { name: "", id: null },
            previousOrder: {},
            batchNumber: null,
            batchAccount: null,
            batchRacks: [],
            printObj: {
                id: "printMe",
                popTitle: "Bon de livraison U-Keys",
            },
            lockForm: {},
        };
    },
    async mounted() {
        const accounts = await UserApi.getAccounts();
        this.accounts = accounts.data;
    },
    methods: {
        findLocker(rack) {
            this.$store.state.findLockId = rack.lock.id;
            this.$store.state.foundAndDisplayed = false;

            const nextRoute = "/arceau/lock";
            if (this.$route.path != nextRoute) {
                this.$router.push(nextRoute);
            }
            //this.$root.$emit("findLockId", true);
        },
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "rack-table");
        },
        clearFilter() {
            this.filter = { name: "", id: null };
        },

        async onAccountChange() {
            this.form.park = { id: 0, name: "" };
            this.getParks();
        },
        async getParks() {
            try {
                const parks = await ParkApi.getAccountParks(
                    this.form.account.id
                );
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },

        async getRacks(ctx) {
            try {
                let filter = {};
                if (this.filter.name) filter.name = this.filter.name;
                if (this.filter.id) filter.id = this.filter.id;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;

                const racks = await RackApi.findRacks(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );

                if (racks.status === 201) {
                    this.racks = racks.data.items;
                    this.totalRows = racks.data.meta.totalItems;
                    this.currentPage = racks.data.meta.currentPage;
                    return racks.data.items;
                } else {
                    this.toast("Erreur!", racks.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getFreeLocks() {
            try {
                const locks = await LockApi.getFreeLocks();
                this.locks = locks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openNewModal() {
            await this.getFreeLocks();
            this.modalTitle = "Nouvel Arceau";
            this.form = {
                id: 0,
                name: "",
                description: "",
                isActive: true,
                latLng: [0, 0],
                account: { id: 0, name: "" },
                lock: { id: 0, name: "" },
                park: { id: 0, name: "" },
            };
            this.$bvModal.show("modalRack");
        },
        async openUpdateModal(rack) {
            await this.getFreeLocks();
            this.modalTitle = "Editer Arceau";
            this.form = rack;

            if (!this.form.lock) this.form.lock = { id: 0, name: "" };
            else this.locks.unshift(this.form.lock);

            if (!this.form.account) this.form.account = { id: 0, name: "" };
            if (!this.form.park) this.form.park = { id: 0, name: "" };
            if (!this.form.code) this.form.code = { id: 0, name: "" };

            this.getParks();
            console.log("rack :", rack);
            this.$bvModal.show("modalRack");
        },
        async confirmDelete(rack) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression de l'arceau : " + rack.name,
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await RackApi.deleteRack(rack.id);
                    this.toast("Message", "Arceau supprimé.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }
                this.applyFilter();
            }
        },

        async submitNewRackForm() {
            if (this.form.name && this.form.description) {
                try {
                    const response = await RackApi.createRack(this.form);
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalRack");
                        this.toast("Message", "Arceau créé !", false);
                        this.$root.$emit("bv::refresh::table", "rack-table");

                        this.form = {
                            id: 0,
                            name: "",
                            description: "",
                            isActive: true,
                            latLng: [0, 0],
                            account: { id: 0, name: "" },
                            lock: { id: 0, name: "" },
                            park: { id: 0, name: "" },
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom' et 'description' sont obligatoires",
                    true
                );
            }
        },

        async submitUpdateRackForm() {
            if (this.form.name && this.form.description) {
                try {
                    console.log(this.form.park);
                    const response = await RackApi.updateRack(this.form);

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalRack");
                        this.toast("Message", "Arceau modifié !", false);
                        this.$root.$emit("bv::refresh::table", "rack-table");
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'nom' et 'description' sont obligatoires",
                    true
                );
            }
        },
        handleCancel() {
            this.$bvModal.hide("modalRack");
            this.$root.$emit("bv::refresh::table", "rack-table");
        },
        handleDeliveryNote() {
            this.$bvModal.show("modalDelivery");
        },
        printBatchDelivery() {
            console.log(this.batchNumber);
        },
        async getBatchRacks() {
            const racks = await RackApi.getBatchRacks(this.batchNumber);
            this.batchRacks = racks.data;
            this.batchAccount = racks.data ? racks.data[0].account : "";
            console.log(this.batchRacks);
        },
        async openRackLockModal(lockId) {
            const lock = await LockApi.findLock(lockId);
            this.lockForm = lock.data;

            this.$bvModal.show("modalRackLock");
        },
        async submitUpdateLockForm() {
            try {
                const response = await LockApi.updateLock(this.lockForm);

                if (response.data.error) {
                    this.toast("Erreur!", response.data.error, true);
                } else {
                    this.$bvModal.hide("modalRackLock");
                    this.toast("Message", "Serrure modifiée !", false);
                    this.$root.$emit("bv::refresh::table", "lock-table");
                }
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
        },
    },
};
</script>
