<template>
  <b-container fluid class="p-3">
    <h3 class="mt-2 font-italic">Configuration des parcs</h3>

    <b-table
      class="table-h100 mt-5"
      :items="params"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive
      striped
      sort-icon-left
      sticky-header="calc(100vh - 380px)"
    >
      <template #cell(actions)="row" class="p-0">
        <b-button size="sm" @click="openQRModal(row.item)" class="ml-1">
          <b-icon-upc-scan />
        </b-button>
        <b-button size="sm" @click="openUpdateModal(row.item)" class="ml-1">
          <b-icon-pencil></b-icon-pencil>
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modalParam"
      title="Edition paramètres"
      centered
      hide-backdrop
      content-class="shadow-lg"
      :header-class="'modal-header-' + account.name"
    >
      <b-container class="container">
        <b-row class="my-2">
          <b-col>
            <b-form-group id="input-name" label="Nom :" label-for="name" inline>
              <b-form-input
                id="name"
                v-model="param.name"
                class="mr-2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-group
              id="input-description"
              label="Description :"
              label-for="description"
              inline
            >
              <b-form-input
                id="description"
                v-model="param.description"
                class="mr-2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-group
              id="input-bankingContractCB"
              label="Contrat bancaire CB :"
              label-for="bankingContractCB"
              inline
            >
              <b-form-input
                id="bankingContractCB"
                v-model="param.bankingContractCB"
                class="mr-2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-group
              id="input-bankingContractSC"
              label="Contrat bancaire SC :"
              label-for="bankingContractSC"
            >
              <b-form-input
                id="bankingContractSC"
                v-model="param.bankingContractSC"
                class="mr-2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-group
              id="input-tolerance"
              label="Délai de tolérance (minutes) :"
              label-for="tolerance"
            >
              <b-form-input
                id="tolerance"
                v-model="param.toleranceDelay"
                required
                type="number"
                min="0"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-2">
          <b-col>
            <b-form-group
              id="input-planted"
              label="Délai max de stationnement (heures) :"
              label-for="planted"
            >
              <b-form-input
                id="planted"
                v-model="param.plantedDelay"
                required
                type="number"
                min="0"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <template v-slot:modal-footer>
        <b-container fluid class="d-flex justify-content-center">
          <b-button
            class="ml-3 modal-button"
            @click="
              $bvModal.hide('modalParam');
              getParams();
            "
            >Annuler</b-button
          >
          <b-button
            class="ml-3 modal-button"
            @click="saveParams"
            variant="success"
            >Valider</b-button
          >
        </b-container>
      </template>
    </b-modal>

    <b-modal
      id="modalQR"
      title="QR Code pour App Paiement"
      centered
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
      :header-class="'modal-header-' + account.name"
    >
      <b-container class="container text-center mb-3">
        <div class="h3 p-4">{{ param.name }}</div>
        <div class="">
          <qrcode-vue :value="pwaUrl" :size="300" level="H"></qrcode-vue>
        </div>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import UserApi from "../apis/User";
import AccountApi from "../apis/AdminAccount";
import ParkApi from "../apis/AdminPark";

import QrcodeVue from "qrcode.vue";
//import Editor from "@tinymce/tinymce-vue";
export default {
  components: { QrcodeVue },
  data() {
    return {
      account: {},
      param: {
        id: null,
        name: null,
        description: null,
        bankingContractCB: null,
        bankingContractSC: null,
        toleranceDelay: null,
        plantedDelay: null,
      },
      params: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true, label: "Nom" },
        { key: "description", sortable: true, label: "Description" },
        {
          key: "bankingContractCB",
          sortable: false,
          label: "Contrat bancaire CB",
        },
        {
          key: "bankingContractSC",
          sortable: false,
          label: "Contrat bancaire SC",
        },
        { key: "toleranceDelay", sortable: false, label: "Tolérance" },
        {
          key: "plantedDelay",
          sortable: false,
          label: "Max stationnement",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "table-actions-td",
          thClass: "table-actions-th",
        },
      ],
      /* apikeyEditor: "rlaezvirgvckny818izxeltiqv7nfcz4gg4sxyqy685v4a0n",
            initEditor: {
                height: 400,
                menubar: false,
                plugins: [
                    "advlist autolink lists link image charmap",
                    "searchreplace visualblocks code fullscreen",
                    "print preview anchor insertdatetime media",
                    "paste code help wordcount table",
                ],
                toolbar:
                    "undo redo | formatselect | bold italic | \
                                alignleft aligncenter alignright | \
                                bullist numlist outdent indent | code help",
            }, */
    };
  },
  methods: {
    async getParams() {
      try {
        if (this.account.name) {
          const parks = await ParkApi.getParksParams(this.account.name);
          this.params = parks.data;
        }
      } catch (err) {
        this.toast("Erreur!", err.message, true);
      }
    },
    openUpdateModal(park) {
      this.param = park;
      this.$bvModal.show("modalParam");
    },
    openQRModal(park) {
      this.param = park;
      this.$bvModal.show("modalQR");
    },
    async saveParams() {
      try {
        await ParkApi.updatePark({
          id: this.param.id,
          name: this.param.name,
          description: this.param.description,
          bankingContractCB: this.param.bankingContractCB,
          bankingContractSC: this.param.bankingContractSC,
          toleranceDelay: this.param.toleranceDelay,
          plantedDelay: this.param.plantedDelay,
        });

        this.$bvModal.hide("modalParam");
        this.toast("Enregistré", "Enregistrement réussi.", false);
      } catch (error) {
        this.toast("Erreur!", error.message, true);
      }
    },
    toast(title, message, faulty = false) {
      this.$root.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-top-center",
        variant: faulty ? "danger" : "success",
        appendToast: true,
      });
    },
  },

  computed: {
    pwaUrl() {
      return process.env.VUE_APP_PWA_URL + "?p=" + this.param.id;
    },
  },
  async mounted() {
    const response = await UserApi.auth();
    const accountId = response.data.account.id;
    const account = await AccountApi.getAccount(accountId);
    this.account = account.data;
    this.getParams();
  },
};
</script>
