import Api from "./Api";
export default {
    findTerminals(paginationOptions, findOptions) {
        return Api().post(
            "/terminal/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getTerminal(id) {
        return Api().get("/terminal/" + id);
    },
    getTerminals() {
        return Api().get("/terminal");
    },
    createTerminal(payload) {
        return Api().post("/terminal", payload);
    },
    updateTerminal(payload) {
        return Api().patch("/terminal/" + payload.id, payload);
    },
    deleteTerminal(id) {
        return Api().delete("/terminal/" + id);
    },
};
