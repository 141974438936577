<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des parcs</h3>
        <div class="text-right m-2">
            <b-button variant="success" @click="openNewModal"
                >Ajouter un Parc
            </b-button>
        </div>

        <b-table
            class="m-2 col-12"
            :items="parks"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            responsive="md"
            striped
            xsmall
            sort-icon-left
            sticky-header="calc(100vh - 380px)"
            ><template #cell(name)="row" class="p-0">
                <b-link
                    :to="{
                        name: 'SuperAdminSinglePark',
                        params: { park: row.item.id },
                    }"
                    >{{ row.item.name }}
                </b-link>
            </template>
            <template #cell(actions)="row" class="p-0">
                <b-button
                    variant="success"
                    size="sm"
                    :to="{
                        name: 'SuperAdminSinglePark',
                        params: { park: row.item.id },
                    }"
                    class="ml-1"
                >
                    <b-icon-map></b-icon-map>
                </b-button>
                <b-button
                    variant="info"
                    size="sm"
                    @click="openUpdateModal(row.item)"
                    class="ml-1"
                >
                    <b-icon-pencil></b-icon-pencil>
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="warning"
                    @click="confirmDelete(row.item)"
                >
                    <b-icon-trash></b-icon-trash>
                </b-button>
            </template>
        </b-table>

        <b-modal
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
            id="modalPark"
            :title="modalTitle"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Nom :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-description"
                        label="Description :"
                        label-for="description"
                    >
                        <b-form-input
                            id="description"
                            v-model="form.description"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-row>
                        <b-col md="6" sm="12"
                            ><b-form-group
                                id="input-account"
                                label="Compte :"
                                label-for="account"
                            >
                                <b-form-select
                                    v-model="form.accountId"
                                    :options="accounts"
                                    value-field="id"
                                    text-field="name"
                                    required
                                    @change="getRfidServers"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" sm="12"
                            ><b-form-group
                                id="input-rfid-server"
                                label="Serveur RFID :"
                                label-for="rfid-server"
                            >
                                <b-form-select
                                    id="rfid-server"
                                    v-model="form.rfidServerId"
                                    :options="rfidServers"
                                    value-field="id"
                                    text-field="name"
                                ></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="6" sm="12">
                            <b-form-group
                                id="input-cba"
                                label="Contrat bancaire CB :"
                                label-for="cba"
                            >
                                <b-form-input
                                    id="cba"
                                    v-model="form.bankingContractCB"
                                    required
                                ></b-form-input>
                            </b-form-group> </b-col
                        ><b-col>
                            <b-form-group
                                id="input-cbb"
                                label="Contrat bancaire SC :"
                                label-for="cbb"
                            >
                                <b-form-input
                                    id="cbb"
                                    v-model="form.bankingContractSC"
                                    required
                                ></b-form-input>
                            </b-form-group> </b-col
                    ></b-row>
                    <b-row>
                        <b-col md="6" sm="12">
                            <b-form-group
                                id="input-tolerance"
                                label="Délai de tolérance (min) :"
                                label-for="tolerance"
                            >
                                <b-form-input
                                    id="tolerance"
                                    v-model="form.toleranceDelay"
                                    required
                                    type="number"
                                    min="0"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                id="input-planted"
                                label="Stationnement max (h) :"
                                label-for="planted"
                            >
                                <b-form-input
                                    id="planted"
                                    v-model="form.plantedDelay"
                                    required
                                    type="number"
                                    min="0"
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-form-group
                        id="input-url"
                        label="Image du plan :"
                        label-for="url"
                        ><b-form-input
                            id="url"
                            disabled
                            v-model="form.url"
                            required
                        ></b-form-input>
                        <file-pond
                            name="file"
                            ref="pond"
                            label-idle="Drop files here..."
                            allow-multiple="false"
                            allow-remove="true"
                            max-files="1"
                            accepted-file-types="image/*"
                            :files="files"
                            @init="handleFilePondInit"
                            @addfile="addFile"
                        />
                    </b-form-group>
                    <b-row>
                        <b-col md="6" sm="12">
                            <b-form-group
                                id="input-size-l"
                                label="Largeur du plan :"
                                label-for="size-l"
                            >
                                <b-form-input
                                    id="size-l"
                                    v-model="form.size[1]"
                                    type="number"
                                    min="0"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col md="6" sm="12">
                            <b-form-group
                                id="input-size-h"
                                label="Hauteur du plan :"
                                label-for="size-h"
                            >
                                <b-form-input
                                    id="size-h"
                                    v-model="form.size[0]"
                                    type="number"
                                    min="0"
                                    required
                                ></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $bvModal.hide('modalPark');
                            getParks();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewParkForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateParkForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilepondPluginImageSizeMetadata from "filepond-plugin-image-size-metadata";
import ParkApi from "@/apis/AdminPark.js";
import RfidServerApi from "../../apis/AdminRfidServer.js";
import UserApi from "@/apis/AdminAccount.js";

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilepondPluginImageSizeMetadata
);
export default {
    components: {
        FilePond,
    },
    data() {
        return {
            files: [],
            form: {
                id: 0,
                name: "",
                description: "",
                isActive: true,
                accountId: 0,
                url: "",
                size: [0, 0],
                bankingContractCB: "",
                bankingContractSC: "",
                toleranceDelay: 15,
                plantedDelay: 48,
                rfidServerId: 0,
            },
            rfidServers: [],
            accounts: [],
            modalTitle: "",
            parks: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true, label: "Nom" },
                { key: "description", sortable: true },
                { key: "account.name", label: "Compte", sortable: true },
                {
                    key: "rfidServer.name",
                    label: "Serveur Rfid",
                    sortable: true,
                },
                { key: "url", label: "Image du plan", sortable: false },
                {
                    key: "size",
                    label: "Dimensions plan",
                    sortable: false,
                    formatter: (value) => {
                        return value.toString();
                    },
                },
                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
        };
    },
    async mounted() {
        this.getParks();
        const accounts = await UserApi.getAccounts();
        this.accounts = accounts.data;
        this.getRfidServers();
    },
    methods: {
        async getRfidServers() {
            try {
                const rfidServers = await RfidServerApi.getAccountRfidServers(
                    this.form.accountId
                );
                if (rfidServers.status === 200) {
                    this.rfidServers = rfidServers.data;
                } else {
                    this.toast("Erreur!", rfidServers.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        addFile(error, file) {
            if (error === null) {
                this.form.size = [
                    file.getMetadata().size.height,
                    file.getMetadata().size.width,
                ];
            }
        },
        handleFilePondInit: async function() {
            //console.log("FilePond has initialized", this.$refs.pond);
            setOptions({
                server: {
                    url: process.env.VUE_APP_BASE_URL + "/parks/upload",
                    process: {
                        method: "POST",
                        withCredentials: false,
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                        timeout: 7000,
                        onload: (response) => {
                            this.form.url = response;
                            return response;
                        },
                    },
                    revert: (uniqueFileId, load, error) => {
                        console.log(uniqueFileId);
                        ParkApi.deleteParkImage(uniqueFileId);
                        load();
                        this.form.url = null;
                        error("delete image failure");
                    },
                },
            });
        },
        async getParks() {
            try {
                const parks = await ParkApi.getParks();
                if (parks.status === 200) {
                    this.parks = parks.data;
                } else {
                    this.toast("Erreur!", parks.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        openNewModal() {
            this.modalTitle = "Nouveau Parc";
            this.form = {
                id: 0,
                name: "",
                description: "",
                url: "",
                size: [0, 0],
                isActive: true,
                bankingContractCB: "",
                bankingContractSC: "",
                toleranceDelay: 15,
                plantedDelay: 48,
                rfidServerId: 0,
            };
            this.$bvModal.show("modalPark");
        },
        openUpdateModal(park) {
            this.modalTitle = "Editer Parc";
            this.form = park;
            this.form.accountId = park.account ? park.account.id : 0;
            this.form.rfidServerId = park.rfidServer ? park.rfidServer.id : 0;
            this.getRfidServers();

            this.$bvModal.show("modalPark");
        },
        async confirmDelete(park) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression du parc : " + park.name,
                {
                    title: "Supprimer ?",
                    size: "md",
                    buttonSize: "md",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2 justify-content-center",
                    hideHeaderClose: false,
                    centered: true,
                    hideBackdrop: true,
                    contentClass: "shadow-lg",
                }
            );
            if (confirmation) {
                try {
                    await ParkApi.deletePark(park.id);
                    this.toast("Message", "Parc supprimé.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }
                this.getParks();
            }
        },

        async submitNewParkForm() {
            console.log(this.form);
            if (
                this.form.name &&
                this.form.description &&
                this.form.url &&
                this.form.size[0] != 0 &&
                this.form.size[1] != 0 &&
                this.form.accountId &&
                this.form.rfidServerId
            ) {
                try {
                    const response = await ParkApi.createPark(this.form);
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        await this.getParks();
                        this.$bvModal.hide("modalPark");
                        this.toast("Message", "Parc créé !", false);
                        this.form = {
                            id: 0,
                            name: "",
                            description: "",
                            url: "",
                            size: [0, 0],
                            isActive: true,
                            bankingContractCB: "",
                            bankingContractSC: "",
                            toleranceDelay: 15,
                            plantedDelay: 48,
                            rfidServerId: 0,
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },

        async submitUpdateParkForm() {
            if (
                this.form.name &&
                this.form.description &&
                this.form.url &&
                this.form.size[0] != 0 &&
                this.form.size[1] != 0 &&
                this.form.accountId &&
                this.form.rfidServerId
            ) {
                try {
                    const response = await ParkApi.updatePark(this.form);

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalPark");
                        this.toast("Message", "Parc modifié !", false);
                        this.getParks(); //pas obligé ...
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
