<template>
    <b-container fluid :class="'topnav topnav-' + this.account">
        <b-navbar toggleable="lg" class="p-0">
            <b-navbar-brand :to="{ path: '/' }" class="logo"></b-navbar-brand>

            <b-navbar-toggle
                target="nav-collapse"
                @click="handleToggle"
            ></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto p-2">
                    <b-input-group>
                        <b-form-input
                            class="top-search-input"
                            placeholder="Code ou Contact"
                            v-on:keyup.enter="search"
                            v-model="$store.state.search"
                        />
                        <template #append>
                            <b-button @click="search" class="top-search-button"
                                ><b-icon-search
                            /></b-button>
                        </template>
                    </b-input-group>
                </b-navbar-nav>
                <b-navbar-nav class="p-2 ">
                    <b-nav-item-dropdown right>
                        <template v-if="!isLoggedIn" v-slot:button-content>
                            Connexion
                        </template>
                        <template v-else v-slot:button-content>
                            <span>{{ loggedName }}</span>
                            <span class="h3"
                                ><b-icon-person-fill></b-icon-person-fill
                            ></span>
                        </template>

                        <div v-if="!isLoggedIn">
                            <b-dropdown-item>
                                <router-link
                                    class="text-decoration-none text-dark"
                                    :to="{ name: 'Login' }"
                                    ><b-icon icon="box-arrow-in-right"></b-icon>
                                    Login
                                </router-link>
                            </b-dropdown-item>
                        </div>
                        <div v-if="isLoggedIn">
                            <b-dropdown-item @click.prevent="profile" href="#">
                                <b-icon icon="person-check"></b-icon>
                                Mon Profil
                            </b-dropdown-item>
                            <b-dropdown-item @click.prevent="logout" href="#">
                                <b-icon icon="box-arrow-right"></b-icon>
                                Logout
                            </b-dropdown-item>
                            <!-- <b-dropdown-item href="#">
                                <b-nav-item-dropdown right class="sm-drop-down">
                                    <template v-slot:button-content>
                                        {{ $i18n.locale }}
                                    </template>
                                    <b-dropdown-item
                                        v-model="$i18n.locale"
                                        v-for="(lang, i) in langs"
                                        :key="`Lang${i}`"
                                        @click="changeLocale(lang)"
                                    >
                                        {{ lang }}
                                    </b-dropdown-item>
                                </b-nav-item-dropdown>
                            </b-dropdown-item> -->
                        </div>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
                <!--  <b-navbar-nav
                    id="topsidebar"
                    :class="'text-center side-bar side-bar-' + this.account"
                >
                    <b-nav vertical>
                        <b-nav-item :to="{ name: 'Dashboard' }">
                            <b-icon-house class="left-icon" />
                            Dashboard
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'AdminPark' }">
                            <b-icon-share class="left-icon" />
                            Parcs
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'AdminJournal' }">
                            <b-icon-journal class="left-icon" />
                            Journal
                        </b-nav-item>

                        <b-nav-item :to="{ name: 'AdminCode' }">
                            <b-icon-key class="left-icon" />
                            Codes
                        </b-nav-item>
                        <a
                            href="#"
                            v-b-toggle.params-menu
                            class="nav-link"
                            v-if="isAdmin"
                        >
                            <b-icon-gear class="left-icon" />Configuration
                            <b-icon-chevron-left
                                class="when-closed right-icon"
                            />
                            <b-icon-chevron-down
                                class="when-opened right-icon"
                            />
                        </a>
                        <b-collapse
                            id="params-menu"
                            v-if="isAdmin"
                            :visible="configVisible"
                        >
                            <b-nav vertical>
                                <b-nav-item :to="{ name: 'AdminUser' }">
                                    Utilisateurs
                                </b-nav-item>

                                <b-nav-item :to="{ name: 'AdminPrice' }">
                                    Tarifs
                                </b-nav-item>

                                <b-nav-item :to="{ name: 'AdminParams' }">
                                    Paramètres
                                </b-nav-item>
                                <b-nav-item :to="{ name: 'AdminLog' }">
                                    Historique
                                </b-nav-item>
                            </b-nav>
                        </b-collapse>
                    </b-nav>
                </b-navbar-nav> -->
            </b-collapse>
        </b-navbar>
        <b-modal id="modalProfile" title="Mon profil">
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Nom :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-email"
                        label="E-mail :"
                        label-for="email"
                    >
                        <b-form-input
                            id="email"
                            v-model="form.email"
                            type="email"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-password"
                        label="Mot de passe :"
                        label-for="password"
                    >
                        <b-form-input
                            autocomplete="new-password"
                            id="password"
                            v-model="form.password"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-pwdConfirm"
                        label="Confirmation du mot de passe :"
                        label-for="pwdConfirm"
                    >
                        <b-form-input
                            autocomplete="new-password"
                            id="pwdConfirm"
                            v-model="form.pwdConfirm"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer
                ><small class="font-italic text-muted px-3">
                    Il vous sera demandé de vous reconnecter avec les nouvelles
                    informations afin de valider les changements.</small
                >
                <b-container fluid class="d-flex justify-content-end">
                    <b-button variant="info" @click="cancelUpdateUser"
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        @click="submitUpdateUser()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import UserApi from "../apis/User";
export default {
    data() {
        return {
            //isLoggedIn: true,
            //isAdmin: true,
            user: this.$store.state.user || null,
            langs: ["fr", "en"],
            searchText: "",
            form: {
                id: 0,
                name: "",
                email: "",
                password: "",
                pwdConfirm: "",
                role: "user",
                isActive: true,
            },
        };
    },
    props: {
        isLoggedIn: Boolean,
        isAdmin: Boolean,
        account: String,
    },
    computed: {
        loggedName() {
            if (this.$store.state.user) {
                return this.$store.state.user.name;
            } else {
                return "User";
            }
        },
    },
    methods: {
        handleToggle() {
            //var canSee = $("#nav-collapse").is(":visible");
            //alert(canSee);
        },
        profile() {
            this.$bvModal.show("modalProfile");
        },
        cancelUpdateUser() {
            this.$bvModal.hide("modalProfile");
            this.getAuth();
        },
        async submitUpdateUser() {
            if (this.form.name && this.form.email) {
                if (this.form.password == this.form.pwdConfirm) {
                    try {
                        await UserApi.saveUser(this.form.id, this.form);
                        this.$bvModal.hide("modalProfile");
                        this.toast("Message", "Profil modifié !", false);
                        this.logout();
                    } catch (error) {
                        this.toast("Erreur!", error.message, true);
                    }
                } else {
                    this.toast(
                        "Erreur!",
                        "Le mot de passe et la confirmation doivent être identiques",
                        true
                    );
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },
        search() {
            const nextRoute = this.account == "arceau" ? "code" : "journal";
            if (this.$route.path != "/" + this.account + "/" + nextRoute) {
                this.$router.push("/" + this.account + "/" + nextRoute);
            }
            this.$root.$emit("search", true);
        },
        changeLocale(lang) {
            this.$i18n.locale = lang;
        },
        logout() {
            localStorage.removeItem("token");
            localStorage.removeItem("status");
            this.$store.commit("setToken", null);
            this.$store.commit("setStatus", null);
            this.$store.commit("setUser", null);
            this.$router.push("/" + this.account + "/login");
        },
        getAuth() {
            //console.log("nb account: ", this.account);
            //this.isLoggedIn = !!localStorage.getItem("token");
            if (this.isLoggedIn) {
                UserApi.auth()
                    .then((response) => {
                        //console.log(response.data);
                        if (
                            this.account !== "arceau" &&
                            response.data.account &&
                            this.$route.params.account !==
                                response.data.account.name
                        ) {
                            console.log("logout from navbar");
                            this.logout();
                        }
                        this.form = response.data;
                        this.$store.commit("setUser", response.data);
                        this.$store.state.user = response.data;
                        localStorage.setItem(
                            "account",
                            response.data.account.name
                        );
                        if (response.data.role === "admin") {
                            localStorage.setItem("status", response.data.role);
                            this.$store.commit("setStatus", response.data.role);
                            this.$store.state.status = response.data.role;
                        }
                    })
                    .catch((reason) => {
                        console.log(
                            "reason for auth error in navbar : " + reason
                        );
                    });
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
    },
    mounted() {
        /*  this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
            console.log("collapseId:", collapseId);
            console.log("isJustShown:", isJustShown);
            this.$store.state.collapsed = !isJustShown;
        }); */
    },
    beforeMount() {
        this.getAuth();
    },
};
</script>
