import Api from "./Api";
export default {
    getPark(id) {
        return Api().get("/parks/admin/" + id);
    },
    getParks() {
        return Api().get("/parks/admin");
    },
    getAccountParks(accountId) {
        return Api().get("/parks/admin/account/" + accountId);
    },
    getAccountNameParks(account) {
        return Api().get("/parks/admin/account/name/" + account);
    },
    getParksParams(account) {
        return Api().get("/parks/admin/account/params/" + account);
    },
    getAccountParksWithStats(accountId) {
        return Api().get("/parks/admin/account/" + accountId + "/stats");
    },
    createPark(payload) {
        return Api().post("/parks/admin", payload);
    },
    updatePark(payload) {
        return Api().patch("/parks/admin/" + payload.id, payload);
    },
    deletePark(id) {
        return Api().delete("/parks/admin/" + id);
    },
    deleteParkImage(filename) {
        return Api().delete("/parks/upload/" + filename);
    },
    getParkImage(filename) {
        return Api().get("/parks/parkimages/" + filename);
    },
};
