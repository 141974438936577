<template>
    <b-container fluid :class="'loginbar-' + account">
        <b-navbar-brand
            :class="
                'd-flex align-items-end justify-content-end logo-' + account
            "
        >
            &nbsp;
        </b-navbar-brand>
    </b-container>
</template>

<script>
export default {
    data() {
        return {
            user: this.$store.state.user || null,
            langs: ["fr", "en"],
        };
    },
    props: {
        isLoggedIn: Boolean,
        isAdmin: Boolean,
    },
    computed: {
        account() {
            let path = this.$route.path.split("/");
            return path[1];
        },
    },
};
</script>
