<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Logs</h3>
        <b-row>
            <b-col
                xs="12"
                md="11"
                class="d-flex flex-row toggle-wrap"
                id="log-serach-form"
            >
                <b-form-group
                    id="account-name"
                    label="Compte :"
                    label-for="account"
                    class="mr-2"
                    ><b-form-select
                        v-model="filter.account"
                        :options="accounts"
                        value-field="id"
                        text-field="name"
                        id="account"
                        class="log-list-form-input"
                        @change="handleAccount"
                    ></b-form-select
                ></b-form-group>

                <b-form-group
                    id="input-park"
                    label="Parc :"
                    label-for="park"
                    class="mr-2"
                >
                    <b-form-select
                        id="park"
                        v-model="filter.park"
                        :options="parks"
                        value-field="id"
                        text-field="name"
                        class="log-list-form-input"
                        @change="getGateways"
                    ></b-form-select>
                </b-form-group>

                <b-form-group
                    id="input-gateway"
                    label="Gateway :"
                    label-for="gateway"
                    class="mr-2"
                >
                    <b-form-select
                        id="gateway"
                        v-model="filter.gateway"
                        :options="gateways"
                        value-field="id"
                        text-field="name"
                        class="log-list-form-input"
                    ></b-form-select>
                </b-form-group>

                <b-form-group
                    id="rack-name"
                    label="Arceau :"
                    label-for="rack"
                    class="mr-2"
                >
                    <b-form-input
                        id="rack"
                        v-model="filter.rack"
                        class="log-text-form-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="lock-name"
                    label="Serrure :"
                    label-for="lock"
                    class="mr-2"
                >
                    <b-form-input
                        id="lock"
                        v-model="filter.lock"
                        class="log-text-form-input"
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    id="code-name"
                    label="Code :"
                    label-for="code"
                    class="mr-2"
                >
                    <b-form-input
                        id="code"
                        v-model="filter.code"
                        class="code-form-input"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-hideStatusUpdate"
                    label="Status Updates :"
                    label-for="hideStatusUpdate"
                >
                    <b-form-checkbox
                        id="hideStatusUpdate"
                        v-model="filter.text"
                        name="check-button"
                        switch
                    >
                        {{ filter.text ? "Oui" : "Non" }}
                    </b-form-checkbox>
                </b-form-group>
                <b-button-group class="filter-form-input">
                    <b-button @click="clearFilter" variant="info">
                        <b-icon-x-circle-fill />
                    </b-button>
                    <b-button variant="info" @click="applyFilter">
                        Filtrer
                    </b-button>
                </b-button-group>
            </b-col>

            <b-col md="1" xs="12" class="text-right">
                <b-button
                    variant="info"
                    @click="showMore = !showMore"
                    class="button-form-input ml-2"
                >
                    <span v-if="showMore">-</span>
                    <span v-else>+</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row no-gutters>
            <b-table
                :style="{ 'max-width': maxWidth }"
                id="log-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getLogs"
                :fields="
                    (showMore ? fields.concat(moreFields) : fields).concat(
                        actionFields
                    )
                "
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                striped
                sort-icon-left
                sticky-header="calc(100vh - 380px)"
            >
                <template #cell(rack)="row">
                    <span v-if="row.item.rack">
                        {{ row.item.rack.name }} ({{ row.item.rack.id }})
                    </span>
                </template>
                <template #cell(lock)="row">
                    <b-link v-if="row.item.lock" @click="findLocker(row.item)">
                        {{ row.item.lock.name }} ({{
                            row.item.lock.id
                        }})</b-link
                    >
                </template>
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openLogModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-exclamation-circle />
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <b-form-group
                class="d-flex flex-row flex-nowrap"
                id="input-pagenumber"
                label="Aller à la page :"
                label-for="page-number"
                label-class="inline-label-text"
                ><b-form-input
                    id="page-number"
                    type="number"
                    v-model="manualPage"
                    class="page-form-input"
                    @keydown.enter="currentPage = manualPage"
                    @change="currentPage = manualPage"
                ></b-form-input
            ></b-form-group>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalLog"
            :title="modalTitle"
            size="lg"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container fluid>
                <table stripped id="log-table" class="w-100">
                    <tr>
                        <td>Date:</td>
                        <td>
                            {{
                                log.created_at
                                    | moment(" DD/MM/YYYY  HH:mm:ss.SSS")
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td>Log:</td>
                        <td>{{ log.text }}</td>
                    </tr>
                    <tr>
                        <td>Requête:</td>
                        <td>{{ log.request }}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>{{ log.error }}</td>
                    </tr>
                    <tr>
                        <td>Compte:</td>
                        <td v-if="log.account">
                            {{ log.account.name }} ({{ log.account.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Parc:</td>
                        <td v-if="log.park">
                            {{ log.park.name }} ({{ log.park.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Arceau:</td>
                        <td v-if="log.rack">
                            {{ log.rack.name }} ({{ log.rack.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Serrure:</td>
                        <td v-if="log.lock">
                            {{ log.lock.name }} ({{ log.lock.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Gateway:</td>
                        <td v-if="log.gateway">
                            {{ log.gateway.name }} ({{ log.gateway.id }})
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                    <tr>
                        <td>Code:</td>
                        <td v-if="log.code">
                            {{ log.code.codeNumber }} ({{ log.code.id }}) ({{
                                log.code.isRfid ? "RFID" : "Borne"
                            }}) -
                            {{
                                log.code.arrivalDate
                                    | moment(" DD/MM/YYYY  HH:mm")
                            }}
                            -
                            {{
                                log.code.departureDate
                                    | moment(" DD/MM/YYYY  HH:mm")
                            }}
                        </td>
                        <td v-else>N/A</td>
                    </tr>
                </table>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button variant="info" @click="$bvModal.hide('modalLog')"
                        >Fermer</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import AccountApi from "@/apis/AdminAccount.js";
import ParkApi from "@/apis/AdminPark.js";
import GatewayApi from "@/apis/AdminGateway.js";
import LogApi from "@/apis/AdminLog.js";

export default {
    data() {
        return {
            log: {},
            logs: [],
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                {
                    key: "created_at",
                    sortable: true,
                    label: "Date",
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                },
                { key: "text", sortable: true, label: "Log" },
                { key: "request", sortable: true, label: "Request" },
                {
                    key: "error",
                    label: "Status",
                    formatter: (value) => {
                        return value ? "KO: " + value : "OK";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
            ],
            moreFields: [
                {
                    key: "account.name",
                    label: "Compte",
                    filterByFormatted: true,
                },

                {
                    key: "park.name",
                    label: "Parc",
                    filterByFormatted: true,
                },
                { key: "rack", label: "Arceau" },

                /* {
                    key: "rack.name",
                    label: "Arceau",
                    filterByFormatted: true,
                }, */
                { key: "lock", label: "Serrure" },

                /* {
                    key: "lock.name",
                    label: "Serrure",
                    filterByFormatted: true,
                }, */
                {
                    key: "gateway.name",
                    label: "Gateway",
                    filterByFormatted: true,
                },
                {
                    key: "code.codeNumber",
                    label: "Code",
                    filterByFormatted: true,
                },
            ],
            actionFields: [
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 50,
            totalRows: null,
            filter: { text: false },
            previousOrder: {},
            showMore: true,
            accounts: [],
            parks: [],
            gateways: [],
            windowWidth: window.innerWidth,
            manualPage: null,
        };
    },
    computed: {
        maxWidth() {
            return this.windowWidth > 575
                ? `${this.windowWidth - 262}px`
                : "100%";
        },
    },
    mounted() {
        this.getAccounts();
        this.getParks();
        this.getGateways();
        window.addEventListener("resize", this.widthHandler);
    },
    methods: {
        findLocker(rack) {
            this.$store.state.findLockId = rack.lock.id;
            this.$store.state.foundAndDisplayed = false;

            const nextRoute = "/arceau/lock";
            if (this.$route.path != nextRoute) {
                this.$router.push(nextRoute);
            }
        },
        applyFilter() {
            this.currentPage = 1;
            this.$root.$emit("bv::refresh::table", "log-table");
        },
        async getLogs(ctx) {
            try {
                ["rack", "park", "lock", "gateway", "code"].forEach((prop) => {
                    if (this.filter[prop] === "") delete this.filter[prop];
                });
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const logs = await LogApi.findLogs(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: this.filter,
                        order,
                    }
                );
                if (logs.status === 201) {
                    this.logs = logs.data.items;
                    this.totalRows = logs.data.meta.totalItems;
                    this.currentPage = logs.data.meta.currentPage;
                    return logs.data.items;
                } else {
                    this.toast("Erreur!", logs.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },

        async getAccounts() {
            try {
                const accounts = await AccountApi.getAccounts();
                this.accounts = accounts.data;
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
        },

        async getParks() {
            try {
                const parks = this.filter.account
                    ? await ParkApi.getAccountParks(this.filter.account)
                    : await ParkApi.getParks();
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getGateways() {
            try {
                const gateways = this.filter.park
                    ? await GatewayApi.getParkGateways(this.filter.park)
                    : this.filter.account
                    ? await GatewayApi.getAccountGateways(this.filter.account)
                    : await GatewayApi.getGateways();
                this.gateways = gateways.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async handleAccount() {
            delete this.filter.park;
            delete this.filter.gateway;
            await this.getParks();
            await this.getGateways();
        },
        clearFilter() {
            this.filter = { text: false };
            this.handleAccount();
        },
        async openLogModal(log) {
            this.log = log;
            this.modalTitle = "Consulter Log: " + log.id;
            this.$bvModal.show("modalLog");
        },
        widthHandler() {
            this.windowWidth = window.innerWidth;
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.widthHandler);
    },
};
</script>
