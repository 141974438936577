<template>
    <div>
        <div id="printMe">
            <div class="text-center">
                <img src="/img/arceau/logo-ns.png" style="width:300px;" />
            </div>
            <div class="w-100 text-nowrap mt-3 d-flex justify-content-between">
                <span class="h4"><strong>Bon de livraison</strong></span>
                <span class="h4"
                    >Date : {{ date | moment("DD MMMM YYYY") }}</span
                >
            </div>
            <div class="w-100 text-nowrap mb-3 d-flex justify-content-between">
                <span class="h4">{{ batchAccount }}</span>
                <span class="h4">Lot #{{ batchNumber }}</span>
            </div>

            <table width="100%">
                <tr>
                    <th class="namecell">ID Arceau</th>
                    <th class="checkcell text-center">Version</th>
                    <th class="checkcell text-center">Test RFID</th>
                    <th class="checkcell text-center">Test CODE</th>
                    <th>Observations</th>
                </tr>
                <tr v-for="rack in racks" :key="rack.id">
                    <td>#{{ rack.id }} ({{ rack.name }})</td>
                    <td class="text-center">{{ rack.version }}</td>
                    <td class="text-center">{{ rack.rfidTest }}</td>
                    <td class="text-center">{{ rack.codeTest }}</td>
                    <td>&nbsp;</td>
                </tr>
            </table>

            <div class="text-center mt-5">
                <small>
                    The Keys - 5 rue de la butte rouge - ZI les Marcots- Bât
                    Rastello - 95480 Pierrelaye - tel +33 (0)1 48 24 14 82.<br />
                    SAS au capital de 12 000 € - RCS Paris B 808 664 312<br />
                    SIRET : 808 664 312 00010 -TVA : FR 56 808 664 312 - Code
                    APE : 2572Z
                </small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["racks", "batchNumber", "batchAccount"],
    data() {
        return {
            racksResults: [],
            date: new Date(),
        };
    },
    async mounted() {
        //console.log("mounted", this.racks);
    },
};
</script>
<style scoped>
th,
td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
}
.namecell {
    width: 300px;
}
.checkcell {
    width: 120px;
}
</style>
