<template>
  <b-container fluid class="p-3">
    <h3 class="mt-2">Gestion des serveurs Rfid</h3>

    <div class="d-flex flex-wrap justify-content-end p-0  m-2">
      <b-col md="6" class="text-left p-0 m-0">
        <!--b-input-group class="mb-2">
                    <b-form-input
                        @input="filter"
                        v-model="searchedRfidServer"
                        type="text"
                        class="name-form-input mr-2"
                        placeholder="Rechercher un utilisateur"
                    />
                    <b-input-group-append class="x-button">
                        <b-button variant="info" class="" @click="reset"
                            ><b-icon-x-circle-fill
                        /></b-button>
                    </b-input-group-append>
                </b-input-group-->
      </b-col>
      <b-col md="3" class="text-right p-0 m-0">
        <b-button variant="success" @click="openNewModal"
          >Ajouter un serveur Rfid
        </b-button>
      </b-col>
    </div>

    <b-table
      class="m-2 table-h100"
      :items="rfidServers"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive="sm"
      striped
      sort-icon-left
      sticky-header
      ><template #cell(status)="row">
        <span v-if="row.item.status == 0">
          <b-icon-check-square-fill variant="success" class="success-check" />
        </span>
        <span v-else class="journal-status-error">DOWN</span>
      </template>
      <template #cell(actions)="row" class="p-0">
        <b-button
          variant="info"
          size="sm"
          @click="openUpdateModal(row.item)"
          class="ml-1"
        >
          <b-icon-pencil></b-icon-pencil>
        </b-button>
        <b-button
          class="ml-1"
          size="sm"
          variant="warning"
          @click="confirmDelete(row.item)"
        >
          <b-icon-trash></b-icon-trash>
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="modaRfidServer"
      :title="modalTitle"
      header-class="modal-header-arceau"
      centered
      hide-backdrop
      content-class="shadow-lg"
    >
      <b-container class="container">
        <b-form>
          <b-form-group id="input-name" label="Nom :" label-for="name">
            <b-form-input id="name" v-model="form.name"></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-description"
            label="Description :"
            label-for="description"
          >
            <b-form-input
              id="description"
              v-model="form.description"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-account" label="Compte :" label-for="account">
            <b-form-select
              v-model="form.accountId"
              :options="accounts"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-type" label="Type :" label-for="type">
            <b-form-input id="type" v-model="form.type" required></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-ipAddress"
            label="Adresse IP :"
            label-for="ipAddress"
          >
            <b-form-input
              autocomplete="new-type"
              id="ipAddress"
              v-model="form.ipAddress"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-ipPort" label="Port :" label-for="ipPort">
            <b-form-input
              id="ipPort"
              v-model="form.ipPort"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-isActive"
            label="Actif :"
            label-for="isActive"
          >
            <b-form-checkbox
              id="isActive"
              v-model="form.isActive"
              name="check-button"
              switch
            >
              {{ form.isActive ? "Oui" : "Non" }}
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </b-container>
      <template v-slot:modal-footer>
        <b-container fluid class="d-flex justify-content-center">
          <b-button
            variant="info"
            @click="
              $bvModal.hide('modaRfidServer');
              getRfidServers();
            "
            >Annuler</b-button
          >
          <b-button
            class="ml-3"
            v-if="form.id == 0"
            @click="submitNewRfidServerForm()"
            variant="success"
            >Valider</b-button
          >
          <b-button
            class="ml-3"
            v-else
            @click="submitUpdateRfidServerForm()"
            variant="success"
            >Valider</b-button
          >
        </b-container>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import RfidServerApi from "../../apis/AdminRfidServer.js";
import AccountApi from "@/apis/AdminAccount.js";

export default {
  data() {
    return {
      accounts: [],
      rfidServers: [],
      filteredRfidServers: [],
      searchedRfidServer: "",
      currentRfidServer: "",
      form: {
        id: 0,
        name: "",
        description: "",
        type: "",
        ipAddress: "0.0.0.0",
        ipPort: 0,
        isActive: true,
        accountId: 2,
      },
      modalTitle: "",
      sortBy: "name",
      sortDesc: false,
      fields: [
        { key: "name", sortable: true, label: "Nom" },
        { key: "description", sortable: true, label: "Description" },
        { key: "type", sortable: true, label: "Type" },
        { key: "ipAddress", sortable: true, label: "Adresse IP" },
        { key: "ipPort", sortable: true, label: "Port" },
        { key: "status", sortable: true, label: "Status" },
        {
          key: "lastConnection",
          sortable: true,
          label: "Dernière connexion",
          formatter: (value) => {
            return this.$moment(value).format("DD/MM/YYYY HH:mm:ss");
          },
        },
        { key: "accountName", sortable: false, label: "Compte" },

        {
          key: "isActive",
          label: "Actif",
          formatter: (value) => {
            return value ? "Oui" : "Non";
          },
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "table-actions-td",
          thClass: "table-actions-th",
        },
      ],
    };
  },
  mounted() {
    this.getRfidServers();
    this.getAccounts();
  },
  methods: {
    async getAccounts() {
      try {
        const accounts = await AccountApi.getAccounts();

        if (accounts.status === 200) {
          this.accounts = accounts.data;
        } else {
          this.toast("Erreur!", accounts.statusText, true);
        }
      } catch (err) {
        this.toast("Erreur!", err, true);
      }
    },
    async getRfidServers() {
      try {
        const rfidServers = await RfidServerApi.getRfidServers();
        if (rfidServers.status === 200) {
          this.rfidServers = rfidServers.data;
        } else {
          this.toast("Erreur!", rfidServers.statusText, true);
        }
      } catch (err) {
        this.toast("Erreur!", err, true);
      }
    },
    openNewModal() {
      this.modalTitle = "Nouveau Serveur Rfid";
      this.form = {
        id: 0,
        name: "",
        description: "",
        type: "",
        ipAddress: "0.0.0.0",
        ipPort: 0,
        isActive: true,
        accountId: null,
      };
      this.$bvModal.show("modaRfidServer");
    },
    openUpdateModal(rfidServer) {
      this.modalTitle = "Editer Serveur Rfid";
      this.form = rfidServer;
      this.$bvModal.show("modaRfidServer");
    },
    async confirmDelete(rfidServer) {
      const confirmation = await this.$bvModal.msgBoxConfirm(
        `Veuillez confirmer la suppression du serveur Rfid : ${rfidServer.name} (${rfidServer.id})`,
        {
          title: "Supprimer ?",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: false,
        }
      );
      if (confirmation) {
        await RfidServerApi.deleteRfidServer(rfidServer.id);
        this.getRfidServers();
      }
    },
    toast(title, message, faulty = false) {
      this.$root.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-top-center",
        variant: faulty ? "danger" : "success",
        appendToast: true,
      });
    },
    async submitUpdateRfidServerForm() {
      if (
        this.form.name &&
        this.form.description &&
        this.form.type &&
        this.form.ipAddress &&
        this.form.ipPort &&
        this.form.accountId
      ) {
        try {
          const response = await RfidServerApi.updateRfidServer(this.form);

          if (response.data.error) {
            this.toast("Erreur!", response.data.error, true);
          } else {
            this.$bvModal.hide("modaRfidServer");
            this.toast("Message", "Serveur Rfid modifié !", false);
            this.getRfidServers();
          }
        } catch (error) {
          this.toast("Erreur!", error.message, true);
        }
      } else {
        this.toast("Erreur!", "Tous les champs sont obligatoires", true);
      }
    },
    async submitNewRfidServerForm() {
      if (
        this.form.name &&
        this.form.description &&
        this.form.type &&
        this.form.ipAddress &&
        this.form.ipPort &&
        this.form.accountId
      ) {
        try {
          const response = await RfidServerApi.addRfidServer(this.form);

          if (response.data.error) {
            this.toast("Erreur!", response.data.error, true);
          } else {
            this.getRfidServers();
            this.$bvModal.hide("modaRfidServer");
            this.toast("Message", "Serveur Rfid créé !", false);
            this.form = {
              id: 0,
              name: "",
              description: "",
              type: "",
              ipAddress: "0.0.0.0",
              ipPort: 0,
              isActive: true,
              accountId: null,
            };
          }
        } catch (error) {
          this.toast("Erreur!", error.message, true);
        }
      } else {
        this.toast("Erreur!", "Tous les champs sont obligatoires", true);
      }
    },
  },
};
</script>
