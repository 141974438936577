<template>
    <b-container fluid class="p-3">
        <h3 class="mt-2 font-italic">Gestion des utilisateurs</h3>
        <b-row class="mr-2">
            <b-col class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group id="input-code">
                    <b-input-group>
                        <b-form-input
                            @input="filter"
                            v-model="searchedUser"
                            type="text"
                            placeholder="Rechercher un utilisateur"
                            v-on:keyup.enter="applyFilter"
                        />
                        <template #append>
                            <b-button @click="reset" class="top-search-button">
                                <b-icon-x-circle-fill />
                            </b-button>
                        </template>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col md="6" xs="12" class="text-right p-0 m-0">
                <div>
                    <b-button
                        variant="success"
                        @click="openNewModal"
                        class="text-nowrap"
                        >Ajouter un Utilisateur
                    </b-button>
                </div>
            </b-col>
        </b-row>
        <!--         <div class="d-flex flex-wrap justify-content-end p-0  m-2">
            <b-col md="6" class="text-center p-0 m-0">
                <b-input-group class="mb-2">
                    <b-form-input
                        @input="filter"
                        v-model="searchedUser"
                        type="text"
                        placeholder="Rechercher un utilisateur"
                    />
                    <b-input-group-append class="x-button">
                        <b-button variant="info" class="" @click="reset"
                            ><strong>x</strong></b-button
                        >
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col md="3" class="text-right p-0 m-0">
                <b-button variant="success" @click="openNewModal"
                    >Ajouter un Utilisateur
                </b-button>
            </b-col>
        </div> -->

        <b-table
            class="m-2 table-h100"
            :items="filteredUsers"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            responsive="sm"
            striped
            sort-icon-left
            sticky-header
        >
            <template #cell(actions)="row" class="p-0">
                <b-button
                    variant="info"
                    size="sm"
                    @click="openUpdateModal(row.item)"
                    class="ml-1"
                >
                    <b-icon-pencil></b-icon-pencil>
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="warning"
                    @click="confirmDelete(row.item)"
                >
                    <b-icon-trash></b-icon-trash>
                </b-button>
            </template>
        </b-table>

        <b-modal
            id="modalUser"
            :title="modalTitle"
            centered
            hide-backdrop
            content-class="shadow-lg"
            :header-class="'modal-header-' + $store.getters.account"
        >
            <b-container class="container">
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Username :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-email"
                        label="Email :"
                        label-for="email"
                    >
                        <b-form-input
                            id="email"
                            v-model="form.email"
                            type="email"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-password"
                        label="Password :"
                        label-for="password"
                    >
                        <b-form-input
                            autocomplete="new-password"
                            id="password"
                            v-model="form.password"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-pwdConfirm"
                        label="Confirmation Password :"
                        label-for="pwdConfirm"
                    >
                        <b-form-input
                            autocomplete="new-password"
                            id="pwdConfirm"
                            v-model="form.pwdConfirm"
                            type="password"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-role"
                        label="Role :"
                        label-for="userRole"
                    >
                        <b-form-select
                            id="userRole"
                            v-model="form.role"
                            :options="roles"
                            required
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group
                        id="input-isActive"
                        label="Actif :"
                        label-for="isActive"
                    >
                        <b-form-checkbox
                            id="isActive"
                            v-model="form.isActive"
                            name="check-button"
                            switch
                        >
                            {{ form.isActive ? "Oui" : "Non" }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        @click="
                            $bvModal.hide('modalUser');
                            getUsers();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewUserForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-else
                        @click="submitUpdateUserForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import UserApi from "../apis/AdminUser.js";
import AuthApi from "../apis/User.js";

export default {
    data() {
        return {
            users: [],
            filteredUsers: [],
            searchedUser: "",
            currentUser: "",
            form: {
                id: 0,
                name: "",
                email: "",
                password: "",
                pwdConfirm: "",
                role: "user",
                isActive: true,
            },
            accountId: 0,
            roles: ["user", "admin"],
            modalTitle: "",
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true, label: "Nom" },
                { key: "email", sortable: true, label: "E-mail" },

                /* {
                    key: "account.name",
                    label: "Account",
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                }, */
                { key: "role", sortable: true, label: "Role" },
                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
        };
    },
    mounted() {
        this.getUsers();
    },
    methods: {
        async getUsers() {
            try {
                const response = await AuthApi.auth();
                if (response.status === 200) {
                    const accountId = response.data.account.id;
                    this.accountId = accountId;

                    const users = await UserApi.getAccountUsers(accountId);
                    if (users.status === 200) {
                        this.filteredUsers = users.data;
                        this.users = users.data;
                    } else {
                        this.toast("Erreur!", users.statusText, true);
                    }
                } else {
                    this.toast("Erreur!", response.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        openNewModal() {
            this.modalTitle = "Nouvel Utilisateur";
            this.form = {
                id: 0,
                name: "",
                email: "",
                password: "",
                pwdConfirm: "",
                role: "user",
                isActive: true,
            };
            this.$bvModal.show("modalUser");
        },
        openUpdateModal(user) {
            console.log("user update", user);
            this.modalTitle = "Editer Utilisateur";
            this.form = user;
            this.$bvModal.show("modalUser");
        },
        async confirmDelete(user) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression de l'utilisateur : " +
                    user.name +
                    " (" +
                    user.email +
                    ")",
                {
                    title: "Supprimer ?",
                    size: "md",
                    buttonSize: "md",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                await UserApi.deleteUser(user.id);
                this.getUsers();
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async submitUpdateUserForm() {
            if (this.form.name && this.form.email && this.form.role) {
                if (this.form.password == this.form.pwdConfirm) {
                    try {
                        this.form.accountId = this.accountId;
                        await UserApi.updateUser(this.form);
                        this.$bvModal.hide("modalUser");
                        this.toast("Message", "Utilisateur modifié !", false);
                        this.getUsers();
                    } catch (error) {
                        this.toast("Erreur!", error.message, true);
                    }
                } else {
                    this.toast(
                        "Erreur!",
                        "Le mot de passe et la confirmation doivent être identiques",
                        true
                    );
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },
        async submitNewUserForm() {
            if (
                this.form.name &&
                this.form.email &&
                this.form.password &&
                this.form.pwdConfirm &&
                this.form.role
            ) {
                if (this.form.password == this.form.pwdConfirm) {
                    try {
                        this.form.accountId = this.accountId;
                        await UserApi.addUser(this.form);
                        this.$bvModal.hide("modalUser");
                        this.toast("Message", "Utilisateur Créé !", false);
                        this.getUsers();
                        this.form = {
                            id: 0,
                            name: "",
                            email: "",
                            password: "",
                            pwdConfirm: "",
                            role: "user",
                            isActive: true,
                        };
                    } catch (error) {
                        this.toast("Erreur!", error.message, true);
                    }
                } else {
                    this.toast(
                        "Erreur!",
                        "Le mot de passe et la confirmation doivent être identiques",
                        true
                    );
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },
        /*async updUser(idx) {
            try {
                const updUser = await AdminUser.updateUser(this.users[idx]);
            } catch (err) {
                console.error("Error from updUser :", error);
            }
        },*/
        setCurrentUser(user) {
            this.currentUser = user;
        },
        async deleteUser(userId) {
            try {
                //const delUser = await AdminUser.deleteUser(userId);
                let deletedUserIndex = await this.users.findIndex(
                    (deleted) => deleted.id == userId
                );
                await this.users.splice(deletedUserIndex, 1);
            } catch (error) {
                console.error("Error from deleteUser :", error);
            }
        },
        async filter() {
            if (this.searchedUser && this.searchedUser.length > 1) {
                const regex = new RegExp(this.searchedUser, "i");
                this.filteredUsers = this.users.filter((user) => {
                    return (
                        user.name.match(regex) ||
                        user.email.match(regex) ||
                        user.account.name.match(regex)
                    );
                });
            } else {
                this.filteredUsers = this.users;
            }
        },
        reset() {
            this.searchedUser = "";
            this.filteredUsers = this.users;
        },
    },
};
</script>
