import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import NotFound from "../views/404.vue";
import SuperDashboard from "../views/SuperDashboard.vue";
import Admin from "../views/Admin.vue";
import SuperAdmin from "../views/SuperAdmin.vue";
import SuperAdminAccount from "../components/superAdmin/SuperAdminAccount.vue";
import SuperAdminUser from "../components/superAdmin/SuperAdminUser.vue";
import SuperAdminPark from "../components/superAdmin/SuperAdminPark.vue";
import SuperAdminSinglePark from "../components/superAdmin/SuperAdminSinglePark.vue";
import AdminSinglePark from "../components/AdminSinglePark.vue";
import SuperAdminRack from "../components/superAdmin/SuperAdminRack.vue";
import SuperAdminPrice from "../components/superAdmin/SuperAdminPrice.vue";
import SuperAdminLock from "../components/superAdmin/SuperAdminLock.vue";
import SuperAdminGateway from "../components/superAdmin/SuperAdminGateway.vue";
import SuperAdminFirmware from "../components/superAdmin/SuperAdminFirmware.vue";
import SuperAdminCode from "../components/superAdmin/SuperAdminCode.vue";
import SuperAdminLog from "../components/superAdmin/SuperAdminLog.vue";
import SuperAdminJournal from "../components/superAdmin/SuperAdminJournal.vue";
import AdminCode from "../components/AdminCode.vue";
import AdminPark from "../components/AdminPark.vue";
import AdminUser from "../components/AdminUser.vue";
import AdminParams from "../components/AdminParams.vue";
import AdminPrice from "../components/AdminPrice.vue";
import AdminLog from "../components/AdminLog.vue";
import AdminJournal from "../components/AdminJournal.vue";
import SuperAdminTerminal from "../components/superAdmin/SuperAdminTerminal.vue";
import SuperAdminRfid from "../components/superAdmin/SuperAdminRfid.vue";

Vue.use(VueRouter);
var account = localStorage.getItem("account") || "not_found";
const routes = [
    {
        path: "/",
        redirect: "/" + account,
        name: "Home",
    },
    {
        path: "/arceau",
        component: SuperAdmin,
        meta: { requireSuperAdmin: true },
        children: [
            {
                path: "",
                name: "SuperDashboard",
                component: SuperDashboard,
            },
            {
                name: "SuperAdminUser",
                path: "user",
                component: SuperAdminUser,
            },
            {
                name: "SuperAdminAccount",
                path: "account",
                component: SuperAdminAccount,
            },
            {
                name: "SuperAdminPark",
                path: "park",
                component: SuperAdminPark,
            },
            {
                name: "SuperAdminSinglePark",
                path: "park/:park",
                component: SuperAdminSinglePark,
            },
            {
                name: "SuperAdminPrice",
                path: "price",
                component: SuperAdminPrice,
            },
            {
                name: "SuperAdminRack",
                path: "rack",
                component: SuperAdminRack,
            },
            {
                name: "SuperAdminLock",
                path: "lock",
                component: SuperAdminLock,
            },
            {
                name: "SuperAdminCode",
                path: "code",
                component: SuperAdminCode,
            },
            {
                name: "SuperAdminGateway",
                path: "gateway",
                component: SuperAdminGateway,
            },
            {
                name: "SuperAdminFirmware",
                path: "firmware",
                component: SuperAdminFirmware,
            },
            {
                name: "SuperAdminLog",
                path: "log",
                component: SuperAdminLog,
            },
            {
                name: "SuperAdminJournal",
                path: "journal",
                component: SuperAdminJournal,
            },
            {
                name: "SuperAdminTerminal",
                path: "terminal",
                component: SuperAdminTerminal,
            },
            {
                name: "SuperAdminRfid",
                path: "rfid",
                component: SuperAdminRfid,
            },
        ],
    },
    {
        path: "/:account/login",
        name: "Login",
        component: Login,
    },

    {
        path: "/:account",
        component: Admin,
        children: [
            {
                path: "",
                name: "Dashboard",
                component: Dashboard,
            },
            {
                name: "AdminPark",
                path: "park",
                component: AdminPark,
            },
            {
                name: "AdminSinglePark",
                path: "park/:park",
                component: AdminSinglePark,
            },
            {
                name: "AdminCode",
                path: "code",
                component: AdminCode,
            },
            {
                path: "user",
                name: "AdminUser",
                component: AdminUser,
                meta: { requireAdmin: true },
            },
            {
                name: "AdminPrice",
                path: "price",
                component: AdminPrice,
                meta: { requireAdmin: true },
            },
            {
                name: "AdminParams",
                path: "cg",
                component: AdminParams,
                meta: { requireAdmin: true },
            },
            {
                name: "AdminLog",
                path: "log",
                component: AdminLog,
            },
            {
                name: "AdminJournal",
                path: "journal",
                component: AdminJournal,
            },
        ],
    },
    {
        path: "*",
        name: "NotFound",
        component: NotFound,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
