<template>
    <div :class="'nobar-h100 body-' + account">
        <b-container
            ><b-row>
                <b-col
                    ><h3 class="text-center bg-white m-5 p-5">
                        {{ $t("error.not_found") }}
                    </h3></b-col
                >
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    computed: {
        account() {
            let account = this.$route.path.split("/")[1];
            return account;
        },
    },
};
</script>
