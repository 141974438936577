<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des comptes</h3>
        <div class="text-right m-2">
            <b-button variant="success" @click="openNewModal"
                >Ajouter un Compte
            </b-button>
        </div>

        <b-table
            class="m-2 table-h100"
            :items="accounts"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            responsive="sm"
            striped
            sort-icon-left
            sticky-header
        >
            <template #cell(actions)="row" class="p-0">
                <b-button
                    variant="info"
                    size="sm"
                    @click="openUpdateModal(row.item)"
                    class="ml-1"
                >
                    <b-icon-pencil></b-icon-pencil>
                </b-button>
                <b-button
                    class="ml-1"
                    size="sm"
                    variant="warning"
                    @click="confirmDelete(row.item)"
                >
                    <b-icon-trash></b-icon-trash>
                </b-button>
            </template>
        </b-table>

        <b-modal
            centered_
            id="modalAccount"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container fluid>
                <b-form>
                    <b-form-group
                        id="input-name"
                        label="Nom :"
                        label-for="name"
                    >
                        <b-form-input
                            id="name"
                            v-model="form.name"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-description"
                        label="Description :"
                        label-for="description"
                    >
                        <b-form-input
                            id="description"
                            v-model="form.description"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group
                        id="input-isActive"
                        label="Actif :"
                        label-for="isActive"
                    >
                        <b-form-checkbox
                            id="isActive"
                            v-model="form.isActive"
                            name="check-button"
                            switch
                        >
                            {{ form.isActive ? "Oui" : "Non" }}
                        </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        @click="
                            $bvModal.hide('modalAccount');
                            getAccounts();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewAccountForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateAccountForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import AccountApi from "@/apis/AdminAccount.js";

export default {
    data() {
        return {
            activeEditor: false,
            form: {
                id: 0,
                name: "",
                description: "",
                isActive: true,
            },
            modalTitle: "",
            accounts: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true, label: "Nom" },
                { key: "description", sortable: true },

                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
        };
    },
    mounted() {
        this.getAccounts();

        this.$nextTick(() => {
            this.activeEditor = true;
        });
    },
    destroyed() {
        this.activeEditor = false;
    },
    methods: {
        async getAccounts() {
            try {
                const response = await AccountApi.getAccounts();
                if (response.status === 200) {
                    this.accounts = response.data;
                } else {
                    this.toast("Erreur!", response.statusText, true);
                }
            } catch (error) {
                this.toast("Erreur!", error.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },

        openCGModal(title, content) {
            this.modalCGTitle = title;
            this.CGform = content;
            this.$bvModal.show("modalCG");
        },
        openNewModal() {
            this.modalTitle = "Nouveau Compte";
            this.form = {
                id: 0,
                name: "",
                description: "",
                cguText: "cguText",
                cgvText: "cgvText",
                isActive: true,
            };
            this.$bvModal.show("modalAccount");
        },
        openUpdateModal(account) {
            this.modalTitle = "Editer Compte";
            this.form = account;
            this.$bvModal.show("modalAccount");
        },
        async confirmDelete(account) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression du compte : " + account.name,
                {
                    title: "Supprimer ?",
                    size: "md",
                    buttonSize: "md",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2 justify-content-center",
                    hideHeaderClose: false,
                    centered: true,
                    hideBackdrop: true,
                    contentClass: "shadow-lg",
                }
            );
            if (confirmation) {
                try {
                    await AccountApi.deleteAccount(account.id);
                    this.toast("Message", "Compte supprimé.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }
                this.getAccounts();
            }
        },

        submitNewAccountForm() {
            if (this.form.name && this.form.description) {
                AccountApi.addAccount(this.form)
                    .then((response) => {
                        if (response.data.error) {
                            this.toast("Erreur!", response.data.error, true);
                        } else {
                            const new_account = response.data.new_account;
                            const newAccount = {
                                id: new_account.id,
                                name: new_account.name,
                                description: new_account.description,
                                isActive: new_account.isActive,
                            };
                            this.accounts.push(newAccount);
                            this.$bvModal.hide("modalAccount");
                            this.toast("Message", "Compte créé !", false);
                            this.form.id = 0;
                            this.form.name = "";
                            this.form.description = "";
                            this.form.isActive = true;
                        }
                    })
                    .catch((error) => {
                        this.toast("Erreur!", error.message, true);
                    });
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },

        submitUpdateAccountForm() {
            if (this.form.name && this.form.description) {
                AccountApi.updateAccount(this.form)
                    .then((response) => {
                        if (response.data.error) {
                            this.toast("Erreur!", response.data.error, true);
                        } else {
                            this.$bvModal.hide("modalAccount");
                            this.toast("Message", "Compte modifié !", false);
                            this.getAccounts();
                        }
                    })
                    .catch((error) => {
                        this.toast("Erreur!", error.message, true);
                    });
            } else {
                this.toast(
                    "Erreur!",
                    "Tous les champs sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
