import Api from "./Api";
export default {
    getAccounts() {
        return Api().get("/accounts/admin");
    },
    addAccount(payload) {
        return Api().post("/accounts/admin", payload);
    },
    updateAccount(payload) {
        return Api().patch("/accounts/admin/" + payload.id, payload);
    },
    deleteAccount(accountId) {
        return Api().delete("/accounts/admin/" + accountId);
    },
    getAccount(accountId) {
        return Api().get("/accounts/admin/" + accountId);
    },
};
