<template>
    <b-container fluid class="p-3 "
        ><div class="d-flex flex-row flex-wrap justify-content-between">
            <h3 class="mt-2 font-italic">
                {{ park.name }} - {{ park.account.description }}
            </h3>
        </div>
        <park :parkId="$route.params.park"></park>
    </b-container>
</template>

<script>
import Park from "./park.vue";
import ParkApi from "../apis/AdminPark";

export default {
    components: {
        Park,
    },
    data() {
        return {
            park: { account: {} },
        };
    },
    methods: {
        async getPark(parkId) {
            try {
                const park = await ParkApi.getPark(parkId);
                this.park = park.data;
            } catch (err) {
                this.toast("Erreur", err.message, true);
            }
        },
    },
    async beforeMount() {
        await this.getPark(this.$route.params.park);
    },
};
</script>
