<template>
    <b-container fluid class="p-3 ">
        <b-form>
            <b-form-row
                ><b-form-group
                    class="col-xs-12 col-md-4"
                    id="input-quantity"
                    label="Quantité :"
                    label-for="quantity"
                >
                    <b-form-input
                        id="quantity"
                        v-model="form.quantity"
                        required
                        type="number"
                        min="0"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    class="col-xs-12 col-md-8"
                    id="input-batch"
                    label="Référence du lot :"
                    label-for="batch"
                >
                    <b-form-input
                        id="batch"
                        v-model="form.batch"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-form-row>

            <b-row>
                <h5 class="mt-2 col-xs-12 col-md-3 text-info">
                    Arceaux
                </h5>
                <!-- <span class="pt-2 col-xs-12 col-md-9 text-center text-info">
                    {{ rackResult }}
                </span> -->
            </b-row>
            <b-form-row>
                <b-form-group
                    class="col-xs-12 col-md-4"
                    id="input-prefix-rack"
                    label="Préfixe nom:"
                    label-for="prefix-rack"
                >
                    <b-form-input
                        id="prefix-rack"
                        v-model="form.prefixRack"
                        required
                    />
                </b-form-group>

                <b-form-group
                    class="col-xs-12 col-md-8"
                    id="input-description"
                    label="Description :"
                    label-for="description"
                >
                    <b-form-input
                        id="description"
                        v-model="form.rack.description"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-form-row>

            <b-row>
                <h5 class="mt-2 col-xs-12 col-md-3 text-info">
                    Serrures:
                </h5>
                <!--  <span class="pt-2 col-xs-12 col-md-9 text-center text-info">
                    {{ lockResult }}
                </span> -->
            </b-row>

            <b-form-row>
                <b-form-group
                    class="col-xs-12 col-md-4"
                    id="input-prefix-lock"
                    label="Préfixe nom :"
                    label-for="prefix-lock"
                >
                    <b-form-input
                        id="prefix-lock"
                        v-model="form.prefixLock"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    class="col-xs-12 col-md-8"
                    id="input-lock-description"
                    label="Description :"
                    label-for="lock-description"
                >
                    <b-form-input
                        id="lock-description"
                        v-model="form.lock.description"
                        required
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
            <b-form-row>
                <b-form-group
                    class="col-xs-12 col-md-4"
                    id="input-lock-version"
                    label="Version :"
                    label-for="lock-version"
                >
                    <b-form-input
                        id="lock-version"
                        v-model="form.lock.version"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    class="col-xs-12 col-md-8"
                    id="input-lock-traceability"
                    label="Traçabilité :"
                    label-for="lock-traceability"
                >
                    <b-form-textarea
                        id="lock-traceability"
                        v-model="form.lock.traceability"
                        required
                    ></b-form-textarea>
                </b-form-group>
            </b-form-row>
        </b-form>
    </b-container>
</template>

<script>
import RackApi from "../../apis/AdminRack";

export default {
    props: ["park"],
    components: {
        //Park,
    },
    data() {
        return {
            form: {
                batch: this.$moment(new Date()).format("YYMMDDHHmm"),
                quantity: 10,
                prefixRack: "A",
                prefixLock: "S",
                rack: { description: "Arceau" },
                lock: {
                    description: "Serrure",
                    version: "1.0",
                    traceability: "",
                },
            },
        };
    },
    methods: {
        async submitBulkCreate() {
            const created = await RackApi.createBulk({
                id: this.park.id,
                data: this.form,
            });
            console.log("creating", created);
        },
    },
    computed: {
        rackResult() {
            return (
                this.form.prefixRack +
                this.form.lot +
                "-1 ... " +
                this.form.prefixRack +
                this.form.lot +
                "-" +
                this.form.quantity
            );
        },
        lockResult() {
            return (
                this.form.prefixLock +
                this.form.lot +
                "-1 ... " +
                this.form.prefixLock +
                this.form.lot +
                "-" +
                this.form.quantity
            );
        },
    },
};
</script>
