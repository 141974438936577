<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2">Gestion des bornes</h3>
        <b-row>
            <b-form class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group
                    id="input-search"
                    label="Nom ou No de série :"
                    label-for="search"
                >
                    <b-form-input
                        id="search"
                        v-model="filter.search"
                        class="name-form-input mr-2"
                    ></b-form-input>
                </b-form-group>
                <b-button-group class="filter-form-input">
                    <b-button @click="clearFilter" variant="info">
                        <b-icon-x-circle-fill />
                    </b-button>
                    <b-button variant="info" @click="applyFilter">
                        Filtrer
                    </b-button>
                </b-button-group>
            </b-form>
            <div class="text-right col-xs-12 col-md-6">
                <b-button
                    variant="success"
                    @click="openNewModal"
                    class="button-form-input ml-2"
                    >Ajouter
                </b-button>
            </div>
        </b-row>
        <b-row no-gutters>
            <b-table
                id="terminal-table"
                class="my-3 mb-4 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getTerminals"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="md"
                sticky-header="calc(100vh - 380px)"
                striped
                sort-icon-left
            >
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        variant="info"
                        size="sm"
                        @click="openUpdateModal(row.item)"
                        class="ml-1"
                    >
                        <b-icon-pencil></b-icon-pencil>
                    </b-button>
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="warning"
                        @click="confirmDelete(row.item)"
                    >
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered_
            id="modalTerminal"
            :title="modalTitle"
            header-class="modal-header-arceau"
            centered
            hide-backdrop
            content-class="shadow-lg"
        >
            <b-container class="container">
                <b-form>
                    <b-row>
                        <b-form-group
                            class="col-12"
                            id="input-name"
                            label="Nom :"
                            label-for="name"
                        >
                            <b-form-input
                                id="name"
                                v-model="form.name"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-12"
                            id="input-serial"
                            label="No de série :"
                            label-for="serial"
                        >
                            <b-form-input
                                id="serial"
                                v-model="form.serialnumber"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group
                            class="col-xs-12 col-md-4"
                            id="input-account"
                            label="Compte :"
                            label-for="account"
                        >
                            <b-form-select
                                v-model="form.account.id"
                                :options="accounts"
                                value-field="id"
                                text-field="name"
                                @change="changeAccount"
                            ></b-form-select>
                        </b-form-group>

                        <b-form-group
                            class="col-xs-12 col-md-8"
                            id="input-park"
                            label="Parc :"
                            label-for="park"
                        >
                            <b-form-select
                                id="park"
                                v-model="form.park.id"
                                :options="parks"
                                value-field="id"
                                text-field="name"
                                required
                            ></b-form-select>
                        </b-form-group>
                    </b-row>

                    <b-row>
                        <b-form-group
                            class="col-12"
                            id="input-version"
                            label="Version :"
                            label-for="version"
                        >
                            <b-form-input
                                id="version"
                                v-model="form.version"
                                required
                            ></b-form-input>
                        </b-form-group>
                    </b-row>

                    <b-form-group
                        id="input-traceability"
                        label="Traçabilité :"
                        label-for="traceability"
                    >
                        <b-form-textarea
                            id="traceability"
                            v-model="form.traceability"
                            rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </b-form>
            </b-container>
            <template v-slot:modal-footer>
                <b-container fluid class="d-flex justify-content-center">
                    <b-button
                        variant="info"
                        @click="
                            $bvModal.hide('modalTerminal');
                            applyFilter();
                        "
                        >Annuler</b-button
                    >
                    <b-button
                        class="ml-3"
                        v-if="form.id == 0"
                        @click="submitNewTerminalForm()"
                        variant="success"
                        >Valider</b-button
                    >
                    <b-button
                        v-else
                        class="ml-3"
                        @click="submitUpdateTerminalForm()"
                        variant="success"
                        >Valider</b-button
                    >
                </b-container>
            </template>
        </b-modal>
    </b-container>
</template>

<script>
import AccountApi from "@/apis/AdminAccount.js";
import ParkApi from "@/apis/AdminPark.js";
import TerminalApi from "@/apis/AdminTerminal.js";
export default {
    data() {
        return {
            accounts: [],
            parks: [],
            terminals: [],
            form: {
                id: 0,
                name: "",
                serialnumber: "",
                version: 0,
                traceability: "",
                park: {},
                account: {},
            },
            modalTitle: "",
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "name", sortable: true, label: "Nom" },
                {
                    key: "serialnumber",
                    sortable: true,
                    label: "No de série",
                },
                { key: "version", sortable: true, label: "Version" },
                { key: "park.name", label: "Parc", sortable: false },
                { key: "account.name", label: "Account", sortable: false },

                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
            filter: { name: "", id: null },
            previousOrder: {},
        };
    },
    computed: {},
    async beforeMount() {
        await this.getAccounts();
    },
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "terminal-table");
        },
        clearFilter() {
            this.filter = {};
        },
        async getTerminals(ctx) {
            try {
                let filter = {};
                if (this.filter.search) filter.search = this.filter.search;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const terminals = await TerminalApi.findTerminals(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );
                if (terminals.status === 201) {
                    this.terminals = terminals.data.items;
                    this.totalRows = terminals.data.meta.totalItems;
                    this.currentPage = terminals.data.meta.currentPage;
                    return terminals.data.items;
                } else {
                    this.toast("Erreur!", terminals.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getParks() {
            try {
                const parks = await ParkApi.getAccountParks(
                    this.form.account.id
                );
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        async getAccounts() {
            try {
                const accounts = await AccountApi.getAccounts();
                this.accounts = accounts.data;
            } catch (err) {
                this.toast("Erreur!", err, true);
            }
        },
        async changeAccount() {
            await this.getParks();
            this.form.park.id = 0;
            this.form.park.name = "";
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openNewModal() {
            this.modalTitle = "Nouvelle Borne";
            this.form = {
                id: 0,
                name: "Borne",
                serialnumber: "",
                version: 0,
                targetVersion: 0,
                traceability: "",
                park: {},
                account: {},
            };
            this.$bvModal.show("modalTerminal");
        },
        async openUpdateModal(terminal) {
            console.log(terminal);
            this.modalTitle = "Editer Borne";
            this.form = terminal;
            await this.getParks();
            this.$bvModal.show("modalTerminal");
        },
        async confirmDelete(terminal) {
            const confirmation = await this.$bvModal.msgBoxConfirm(
                "Veuillez confirmer la suppression de la borne : " +
                    terminal.name,
                {
                    title: "Supprimer ?",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "Supprimer",
                    cancelTitle: "Annuler",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: false,
                }
            );
            if (confirmation) {
                try {
                    await TerminalApi.deleteTerminal(terminal.id);
                    this.toast("Message", "Borne supprimée.", false);
                } catch (err) {
                    this.toast("Erreur!", err.message, true);
                }

                this.$root.$emit("bv::refresh::table", "terminal-table");
            }
        },

        async submitNewTerminalForm() {
            if (
                this.form.name &&
                this.form.version != null &&
                this.form.serialnumber &&
                this.form.park.id &&
                this.form.account.id
            ) {
                try {
                    this.form.parkId = this.form.park.id;
                    this.form.accountId = this.form.account.id;
                    const response = await TerminalApi.createTerminal(
                        this.form
                    );
                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$root.$emit(
                            "bv::refresh::table",
                            "terminal-table"
                        );
                        this.$bvModal.hide("modalTerminal");
                        this.toast("Message", "Borne créée !", false);
                        this.form = {
                            id: 0,
                            name: "",
                            serialnumber: "",
                            version: 0,
                            traceability: "",
                            park: { id: 0, name: "" },
                            account: { id: 0, name: "" },
                        };
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'Nom', 'Version' , 'No de série', 'Parc' et 'Compte' sont obligatoires",

                    true
                );
            }
        },

        async submitUpdateTerminalForm() {
            if (
                this.form.name &&
                this.form.version != null &&
                this.form.serialnumber &&
                this.form.park.id &&
                this.form.account.id
            ) {
                try {
                    this.form.parkId = this.form.park.id;
                    this.form.accountId = this.form.account.id;
                    const response = await TerminalApi.updateTerminal(
                        this.form
                    );

                    if (response.data.error) {
                        this.toast("Erreur!", response.data.error, true);
                    } else {
                        this.$bvModal.hide("modalTerminal");
                        this.toast("Message", "Borne modifiée !", false);
                        this.$root.$emit(
                            "bv::refresh::table",
                            "terminal-table"
                        ); // TODO not necessary
                    }
                } catch (error) {
                    this.toast("Erreur!", error.message, true);
                }
            } else {
                this.toast(
                    "Erreur!",
                    "Les champs 'Nom', 'Version' , 'No de série', 'Parc' et 'Compte' sont obligatoires",
                    true
                );
            }
        },
    },
};
</script>
