<template>
  <b-container fluid class="p-3 ">
    <h3 class="mt-2 font-italic">Journal</h3>
    <b-row>
      <b-col class="col-xs-12 col-md-11 d-flex flex-row flex-wrap">
        <b-form-group id="input-code" label="Code ou Contact:" label-for="code">
          <b-input-group>
            <b-form-input
              id="code"
              v-model="$store.state.search"
              class="name-form-input"
              v-on:keyup.enter="applyFilter"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
            id="input-park"
            label="Parc :"
            label-for="park"
            class="mr-2"
        >
            <b-form-select
                id="park"
                v-model="filter.park"
                :options="parks"
                value-field="id"
                text-field="name"
                class="log-list-form-input"
            ></b-form-select>
        </b-form-group>
        <b-form-group
                    id="rack-name"
                    label="Arceau :"
                    label-for="rack"
                    class="mr-2"
                >
                    <b-form-input
                        id="rack"
                        v-model="filter.rack"
                        class="log-text-form-input"
                    ></b-form-input>
                </b-form-group>

              <b-button-group class="filter-form-input">
                <b-button @click="clearFilter" class="top-search-button">
                  <b-icon-x-circle-fill />
                </b-button>
                <b-button class="top-search-button" @click="applyFilter">
                  <b-icon-search />
                </b-button>
              </b-button-group>
      </b-col>
    </b-row>
    <b-row no-gutters>
      <b-table
        :style="{ 'max-width': maxWidth }"
        id="journal-table"
        class="my-3 mb-4 col-12"
        :per-page="perPage"
        :current-page="currentPage"
        :items="getJournals"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive="md"
        striped
        sort-icon-left
        sticky-header="calc(100vh - 380px)"
      >
        <template #cell(actions)="row" class="p-0">
          <b-button
            v-if="row.item.rack"
            size="sm"
            @click="handleRackStatus(row.item)"
          >
            <!-- <b-img width="25" height="23" src="/arceau.png" /> -->
            <b-icon-eye />
          </b-button>
          <!-- <b-button
                        class="ml-1"
                        size="sm"
                        @click="openJournalModal(row.item)"
                    >
                        <b-icon-eye />
                    </b-button> -->
        </template>
        <template #cell(details)="row">
          {{ getDetails(row.item) }}
        </template>
        <template #cell(park)="row">
            <router-link :to="'/'+row.item.account.name+'/park/'+row.item.park.id">{{row.item.park.name}}</router-link>
        </template>
        <template #cell(action)="row">
          <span :class="getActionClass(row.item)" class="journal-action">{{
            getAction(row.item)
          }}</span>
        </template>
        <template #cell(status)="row">
          <span v-if="row.item.status == 0">
            <b-icon-check-square-fill variant="success" class="success-check" />
          </span>
          <span v-else class="journal-status-error">{{
            getStatus(row.item)
          }}</span>
        </template>
        <template #cell(contactType)="row">
          <span v-if="row.item.contactType == 0">
            <b-icon-credit-card variant="info" class="contact-type" />
          </span>
          <span v-else-if="row.item.contactType == 1">
            <b-icon-broadcast variant="info" class="success-check" />
          </span>
          <span v-else-if="row.item.contactType == 2">
            <b-icon-keyboard variant="info" class="contact-type" />
          </span>
          <span v-else>{{ row.item.contactType }}</span>
        </template>
      </b-table>
    </b-row>
    <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
      <!-- <b-pagination-nav
                :number-of-pages="
                    totalRows ? Math.ceil(totalRows / perPage) : 1
                "
                :link-gen="
                    (pageNum) => (pageNum === 1 ? '?' : `?page=${pageNum}`)
                "
                v-model="currentPage"
                use-router
            ></b-pagination-nav>-->
      <div>Total: {{ totalRows }}</div>
    </div>

    <b-modal
      centered
      hide-backdrop
      content-class="shadow-lg"
      :header-class="'modal-header-' + $store.getters.account"
      id="modalJournal"
      :title="modalTitle"
      size="lg"
      body-class="m-2"
    >
      <b-container fluid>
        <table stripped id="journal-modal" class="w-100">
          <tr>
            <th>Date:</th>
            <td>
              {{ journal.created_at | moment(" DD/MM/YYYY  HH:mm:ss.SSS") }}
            </td>
          </tr>
          <tr>
            <th>Contact:</th>
            <td>{{ journal.contact }}</td>
          </tr>
          <tr>
            <th>Type:</th>
            <td>
              <b-icon-credit-card
                v-if="journal.contactType == 0"
                variant="info"
                class="contact-type"
              />
              <b-icon-broadcast
                v-else-if="journal.contactType == 1"
                variant="info"
                class="contact-type"
              />
              <b-icon-keyboard
                v-else-if="journal.contactType == 2"
                variant="info"
                class="contact-type"
              />
              <span class="ml-2">{{
                getContactType(journal.contactType)
              }}</span>
            </td>
          </tr>
          <tr>
            <th>Action:</th>
            <td>
              <span :class="getActionClass(journal)" class="journal-action">{{
                getAction(journal)
              }}</span>
            </td>
          </tr>
          <tr>
            <th>Status:</th>
            <td v-if="journal.status == 0">
              <b-icon-check-square-fill
                variant="success"
                class="success-check mr-2"
              />
              {{ getStatus(journal) }}
            </td>
            <td v-else>
              <span class="journal-status-error">{{ getStatus(journal) }}</span>
            </td>
          </tr>

          <tr v-if="journal.park">
            <th>Parc:</th>
            <td>{{ journal.park.name }} ({{ journal.park.id }})</td>
          </tr>
          <tr v-if="journal.rack">
            <th>Arceau:</th>
            <td>{{ journal.rack.name }} ({{ journal.rack.id }})</td>
          </tr>
          <tr v-if="journal.code">
            <th>Code:</th>
            <td>
              {{ journal.code.codeNumber }} ({{ journal.code.id }}) ({{
                journal.code.isRfid ? "RFID" : "Borne"
              }}) -
              {{ journal.code.arrivalDate | moment(" DD/MM/YYYY  HH:mm") }}
              -
              {{ journal.code.departureDate | moment(" DD/MM/YYYY  HH:mm") }}
            </td>
          </tr>
          <tr>
            <th>Détails:</th>
            <td>{{ getDetails(journal) }}</td>
          </tr>
          <tr v-if="journal.price">
            <th>Coût</th>
            <td>{{ journal.price }} &euro;</td>
          </tr>
        </table>
      </b-container>
      <template v-slot:modal-footer>
        <b-container fluid class="d-flex justify-content-center">
          <b-button @click="$bvModal.hide('modalJournal')">Fermer</b-button>
        </b-container>
      </template>
    </b-modal>

    <b-modal
      centered
      hide-backdrop
      :content-class="'shadow-lg rack-status-' + $store.getters.account"
      body-class="p-0 m-0"
      id="modalJournalRackStatus"
      size="md"
      hide-footer
      hide-header
    >
      <rack-status
        v-if="rack"
        :rack="rack"
        :parkId="rack.park.id"
        :parkName="rack.park.name"
        v-on:refresh="refreshRackStatus"
        showClose="1"
      ></rack-status>
    </b-modal>
  </b-container>
</template>

<script>
import RackStatus from "./RackStatus.vue";

import JournalApi from "@/apis/AdminJournal.js";
import RackApi from "@/apis/AdminRack.js";
import ParkApi from "@/apis/AdminPark.js";

export const JournalAction = {
  BUY: 0,
  BUY_EXTRA: 1,
  ATTACH: 2,
  DETACH: 3,
  ADMIN_DETACH: 4,
  ADMIN_ATTACH: 5,
  ADMIN_EXTEND: 6,
  VISITOR_EXTEND: 7,
  REMOTE_OPEN: 8,
  REMOTE_DETACH: 9,
};

export const ActionText = {
  0: "Achat",
  1: "Achat Suppl.",
  2: "Verrouillage",
  3: "Déverrouillage",
  4: "Libération",
  5: "Association",
  6: "Prolongation à distance",
  7: "Prolongation",
  8: "Ouverture à distance",
  9: "Déverrouillage à distance",
};

export const ContactType = {
  0: "Visiteur",
  1: "Abonné",
  2: "Admin",
};

export const StatusText = {
  0: "OK",
  1: "Arceau invalide",
  2: "Arceau libre",
  3: "Arceau occupé",
  4: "Code expiré",
  5: "Code erroné",
  6: "Arceau incorrect",
  7: "Code utilisé",
  8: "Parc incorrect",
  9: "Arceau en attente",
  10: "Carte refusée",
  11: "Arceau à vérifier",
  12: "Validation RFID indisponible",
};

export default {
  components: {
    RackStatus,
  },
  data() {
    return {
      rack: null,
      journal: {},
      journals: [],
      parks: [],
      modalTitle: "",
      modalRackStatusTitle: "",
      sortBy: "id",
      sortDesc: true,
      fields: [
        {
          key: "created_at",
          sortable: true,
          tdClass: "text-nowrap",
          label: "Date",
          formatter: (value) => {
            return this.$moment(value).format("DD/MM/YYYY HH:mm");
          },
        },
        {
          key: "action",
          sortable: true,
          sortByFormatted: true,
          label: "Action",
          formatter: (value) => {
            return this.getActionByVal(value);
          },
        },
        {
          key: "contactType",
          formatter: (value) => {
            return this.getContactType(value);
          },
          sortable: true,
          sortByFormatted: true,
          tdClass: "text-right",
          label: "Type",
        },
        { key: "contact", sortable: true, label: "Contact" },
        { key: "code.codeNumber", sortable: true, label: "Code" },
        { key: "park", sortable: true, label: "Park" },
        { key: "rack.name", sortable: true, label: "Rack" },

        {
          key: "status",

          sortable: true,
          sortByFormatted: true,
          label: "Succès",
        },
        {
          key: "details",
          label: "Détails",
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "table-actions-td",
          thClass: "table-actions-th",
        },
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: null,
      filter: {},
      previousOrder: {},
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    maxWidth() {
      return this.windowWidth > 575 ? `${this.windowWidth - 262}px` : "100%";
    },
  },
  mounted() {
    this.getParks();
    if(this.$route.query.rack)
      this.filter.rack = this.$route.query.rack;
    if(this.$route.query.code)
      this.filter.code = this.$route.query.code;
    console.log(this.filter);
    this.$root.$on("search", () => {
      this.applyFilter();
    });
    window.addEventListener("resize", this.widthHandler);
    //this.currentPage = this.$route.query.page || 1;
  },
  methods: {
    applyFilter() {
      this.$root.$emit("bv::refresh::table", "journal-table");
    },
    async getJournals(ctx) {
      try {
        let filter = this.filter;
        if (this.$store.state.search) filter.search = this.$store.state.search;
        if (this.filter.id) filter.id = this.filter.id;

        let order = {};
        order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
        if (ctx.sortBy == "") order = this.previousOrder;
        else this.previousOrder = order;
        const journals = await JournalApi.findAccountJournals(
          { limit: ctx.perPage, page: ctx.currentPage },
          {
            where: filter,
            order,
          },
          localStorage.getItem("account")
        );
        if (journals.status === 201) {
          this.journals = journals.data.items;
          this.totalRows = journals.data.meta.totalItems;
          this.currentPage = journals.data.meta.currentPage;
          return journals.data.items;
        } else {
          this.toast("Erreur!", journals.statusText, true);
        }
      } catch (err) {
        this.toast("Erreur!", err.message, true);
      }
    },
    toast(title, message, faulty = false) {
      this.$root.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-top-center",
        variant: faulty ? "danger" : "success",
        appendToast: true,
      });
    },
    clearFilter() {
      this.filter = {};
      this.$store.state.search = null;
    },
    openJournalModal(journal) {
      this.journal = journal;
      this.modalTitle = "Consulter Journal: " + journal.id;
      this.$bvModal.show("modalJournal");
    },
    async refreshRackStatus() {
      const rack = await RackApi.getRack(this.journal.rack.id);
      this.rack = rack.data;
    },
    handleRackStatus(journal) {
      this.journal = journal;
      this.openRackStatus();
    },
    async openRackStatus() {
      const rack = await RackApi.getRack(this.journal.rack.id);
      this.rack = rack.data;
      this.$bvModal.show("modalJournalRackStatus");
    },
    widthHandler() {
      this.windowWidth = window.innerWidth;
    },
    getDetails(journal) {
      let details = journal.status ? "Tentative de " : "";
      const rack = journal.rack
        ? `${journal.rack.name} (${journal.rack.id})`
        : "?";
      const code = journal.code
        ? ` ${journal.code.codeNumber} (${journal.code.id})`
        : journal.contact;
      switch (journal.action) {
        case JournalAction.BUY:
          details += `Achat du code ${
            journal.code.codeNumber
          }, valide jusqu'au ${this.$moment(journal.code.departureDate).format(
            "DD/MM/YYYY  HH:mm"
          )} `;
          break;
        case JournalAction.BUY_EXTRA:
          details += `Achat d'extention du code ${
            journal.code.codeNumber
          }, valide jusqu'au ${this.$moment(journal.code.departureDate).format(
            "DD/MM/YYYY  HH:mm"
          )} `;
          break;
        case JournalAction.ADMIN_DETACH:
          details += `Libération de l'arceau ${rack} du parc ${journal.park.name}`;
          break;
        case JournalAction.ADMIN_ATTACH:
          details += `Association du code ${code} avec l'arceau ${rack} du parc ${journal.park.name}`;
          break;
        case JournalAction.ATTACH:
          details += `Verrouillage de l'arceau ${rack} à l'aide du code ${code}`;
          break;
        case JournalAction.DETACH:
          details += `Déverrouillage de l'arceau ${rack} à l'aide du code ${code}`;
          break;
        case JournalAction.VISITOR_EXTEND:
        case JournalAction.ADMIN_EXTEND:
          details += `Prolongation du code ${code} associé à l'arceau ${rack}`;
          break;
        case JournalAction.REMOTE_DETACH:
          details += `Déverrouillage de l'arceau ${rack} depuis le mode Ouverture à distance`;
          break;
        case JournalAction.REMOTE_OPEN:
          details += `Passage de l'arceau ${rack} en mode Ouverture à distance`;
          break;
      }
      return details;
    },
    getStatus(journal) {
      if (journal) return StatusText[journal.status];
    },
    async getParks() {
            try {
                const parks = await ParkApi.getAccountParks(this.$store.getters.accountId);
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },

    getContactType(type) {
      return ContactType[type];
    },
    getAction(journal) {
      if (journal) return ActionText[journal.action];
    },
    getActionByVal(action) {
      if (action !== null) return ActionText[action];
    },
    getActionClass(journal) {
      let actionClass = "";
      if (journal) {
        switch (journal.action) {
          case JournalAction.BUY:
          case JournalAction.BUY_EXTRA:
            actionClass = "action-buy";
            break;
          case JournalAction.REMOTE_OPEN:
          case JournalAction.REMOTE_DETACH:
          case JournalAction.VISITOR_EXTEND:
          case JournalAction.ADMIN_EXTEND:
          case JournalAction.ADMIN_ATTACH:
          case JournalAction.ADMIN_DETACH:
            actionClass = "action-detach";
            break;
          case JournalAction.ATTACH:
            actionClass = "action-attach";
            break;
          case JournalAction.DETACH:
            actionClass = "action-detach";
            break;
        }
        return actionClass;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.widthHandler);
  },
};
</script>
