<template>
  <div id="visits-stats">
    <div class="stats-content">
      <table class="stripped w-100">
        <tr>
          <th>
            <h5>KPIs</h5>
          </th>
          <td width="120" align="center">Visiteurs</td>
          <td width="120" align="center">Abonnés</td>
        </tr>
        <tr>
          <td>Nombre de transactions</td>
          <td align="center">{{ transactions }}</td>
          <td w align="center">
            {{ subscriberTransactions }}
          </td>
        </tr>
        <tr>
          <td>Nombre de parkings utilisés / usager</td>
          <td align="center">{{ visitorParks }}</td>
          <td align="center">{{ subscriberParks }}</td>
        </tr>

        <tr>
          <td>Temps moyen de stationnement</td>
          <td align="center">{{ avgParking }} min</td>
          <td align="center">{{ subscriberAvgParking }} min</td>
        </tr>
        <tr>
          <td>Fréquence d'utilisation du service</td>
          <td align="center">{{ serviceUsage }} / sem</td>
          <td align="center">{{ subscriberServiceUsage }} / sem</td>
        </tr>
        <tr>
          <td>Ticket Moyen</td>
          <td align="center">{{ avgTicket }} &euro;</td>
          <td></td>
        </tr>
        <tr>
          <td>% langue française</td>
          <td align="center">{{ french }} %</td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
//import User from "../apis/User";
import CodeApi from "@/apis/AdminCode.js";

export default {
  props: ["sinceDate", "parkId", "account"],
  data() {
    return {
      transactions: "N/A",
      visitorParks: "N/A",
      avgTicket: "N/A",
      french: "N/A",
      avgParking: "N/A",
      serviceUsage: "N/A",
      subscriberTransactions: "N/A",
      subscriberParks: "N/A",
      subscriberAvgTicket: "N/A",
      subscriberAvgParking: "N/A",
      subscriberServiceUsage: "N/A",
    };
  },
  methods: {
    async getStats() {
      const statsData = await CodeApi.statsData(
        this.account,
        this.parkId,
        this.$moment(this.sinceDate).format("YYYYMMDD")
      );
      const stats = statsData.data;

      this.transactions = stats.transactions;
      this.avgTicket = this.$n(stats.avgTicket);
      this.subscriberTransactions = stats.subscriberTransactions;
      this.visitorParks = this.$n(stats.visitorParks) || 0;
      this.subscriberParks = this.$n(stats.subscriberParks) || 0;
      this.avgParking = this.$n(stats.avgParking) || 0;
      this.subscriberAvgParking = this.$n(stats.subscriberAvgParking) || 0;
      this.serviceUsage = this.$n(stats.usage) || 0;
      this.subscriberServiceUsage = this.$n(stats.subscriberUsage) || 0;
      this.french =
        stats.transactions != 0
          ? this.$n(
              Math.floor((stats.language / stats.transactions) * 100 * 100) /
                100
            )
          : 0;
    },
  },
  mounted() {
    this.getStats();
  },
};
</script>
