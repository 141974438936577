<template>
  <b-container fluid class="d-flex flex-column" id="dashboardAlerts">
    <h5 class="pb-3 border-bottom border-dark text-dark">
      Occupation
    </h5>
    <div class="occupancy-content">
      <table class="stripped w-100">
        <tr>
          <th class="text-left">Parc</th>
          <th>Vis.</th>
          <th>Abo.</th>
          <th>Tot.</th>
          <th>Lib.</th>
          <th>stats</th>
        </tr>
        <tr v-for="item of occupancy" :key="item.id">
          <td class="text-left">{{ item.name }}</td>
          <td>{{ item.visitors }}</td>
          <td>{{ item.subscribers }}</td>
          <td>{{ item.total }}</td>
          <td>{{ item.free }}</td>
          <td>
            <b-button
              class="btn p-1 "
              v-b-tooltip.hover
              :title="
                'Télécharger les statistiques de la semaine du ' +
                  from.format('dddd Do MMMM YYYY') +
                  ' au ' +
                  to.format('dddd Do MMMM YYYY')
              "
              @click="download(item)"
            >
              <b-icon-download
            /></b-button>
          </td>
        </tr>
      </table>
    </div>
  </b-container>
</template>

<script>
import StatsApi from "../../apis/Stats.js";

export default {
  props: ["sinceDate", "parkId", "account"],
  data() {
    return {
      occupancy: [],
      from: null,
      to: null,
    };
  },

  async mounted() {
    this.from = this.$moment(this.sinceDate).startOf("week");
    this.to = this.$moment(this.sinceDate).endOf("week");
    const occupancyQuery = await StatsApi.getCurrentOccupancy(
      this.account,
      this.parkId
    );
    this.occupancy = occupancyQuery.data;
  },
  methods: {
    async download(park) {
      const downloadQuery = await StatsApi.getWeekOccupancy(
        this.account,
        park.id,
        this.from.format("YYYYMMDD")
      );

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += downloadQuery.data;

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `Occupation du ${this.from.format(
          "dddd Do MMMM YYYY"
        )} au ${this.to.format("dddd Do MMMM YYYY")} - ${park.name}.csv`
      );
      link.click();
    },
  },
};
</script>
