<template>
    <b-container fluid class="p-3 content-h100  overflow-auto ">
        <h3 class="mt-2 font-italic">Parcs</h3>
        <b-row
            ><b-col class="d-flex flex-row flex-wrap">
                <b-link
                    class="card-park-link  m-2"
                    v-for="(park, index) in parks"
                    :key="index"
                    :to="{
                        name: 'AdminSinglePark',
                        params: { park: park.id },
                    }"
                >
                    <b-card
                        class="park-card"
                        :title="park.name"
                        :img-src="parkUrl(park.url)"
                        :img-alt="park.name"
                        img-top
                    >
                        <b-card-text class="text-info">
                            Nombre d'arceaux : {{ park.stats.rackCount }}<br />
                            Nombre d'arceaux occupés : {{ park.stats.codeCount
                            }}<br />
                            Nombre d'arceaux indisponibles :
                            {{ park.stats.unreachableCount }}<br />
                        </b-card-text>
                    </b-card> </b-link
            ></b-col>
        </b-row>
    </b-container>
</template>

<script>
import ParkApi from "@/apis/AdminPark.js";
//import UserApi from "@/apis/AdminAccount.js";
export default {
    data() {
        return {
            parks: [],
        };
    },
    async mounted() {
        this.getParks();
    },
    methods: {
        parkUrl(url) {
            return process.env.VUE_APP_BASE_URL + "/parks/parkimages/" + url;
        },
        async getParks() {
            try {
                const parks = await ParkApi.getAccountParksWithStats(
                    this.$store.getters.accountId
                );
                this.parks = parks.data;
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
    },
};
</script>
