import Api from "./Api";
export default {
    getPrices(accountId) {
        return Api().get("/prices/admin/" + accountId);
    },
    getParkPrices(parkId) {
        return Api().get("/prices/admin/park/" + parkId);
    },
    createPrice(parkId, payload) {
        return Api().post("/prices/admin/" + parkId, payload);
    },
    updatePrice(parkId, payload) {
        return Api().patch("/prices/admin/" + parkId, payload);
    },
    deletePrice(priceId) {
        return Api().delete("/prices/admin/" + priceId);
    },
};
