import Api from "./Api";
export default {
    findRacks(paginationOptions, findOptions) {
        return Api().post(
            "/racks/find" +
                `?limit=${paginationOptions.limit}&page=${paginationOptions.page}`,
            findOptions
        );
    },
    getRack(id) {
        return Api().get("/racks/admin/" + id);
    },
    getRacks() {
        return Api().get("/racks/admin");
    },
    getParkRacks(parkId) {
        return Api().get("/racks/admin/park/" + parkId);
    },
    getParkFreeRacks(parkId) {
        return Api().get("/racks/admin/parkfree/" + parkId);
    },
    getFreeRacks() {
        return Api().get("/racks/admin/free");
    },
    getCodeFreeRacks() {
        return Api().get("/racks/admin/codefree");
    },
    getCodeRack(codeId) {
        return Api().get("/racks/admin/code/" + codeId);
    },
    createRack(payload) {
        return Api().post("/racks/admin", payload);
    },
    updateRack(payload) {
        return Api().patch("/racks/admin/" + payload.id, payload);
    },
    deleteRack(id) {
        return Api().delete("/racks/admin/" + id);
    },
    createBulk(payload) {
        return Api().post("/racks/admin/park/" + payload.id, payload.data);
    },
    getPlanted(account, parkId) {
        return Api().get("/racks/planted/" + account + "?parkId=" + parkId);
    },
    getBatchRacks(batchNumber) {
        return Api().get("/racks/batch/" + batchNumber);
    },
};
