<template>
    <b-container fluid class="p-3 ">
        <h3 class="mt-2 font-italic">Codes</h3>
        <b-row>
            <b-col class="col-xs-12 col-md-6 d-flex flex-row">
                <b-form-group
                    id="input-code"
                    label="Code ou Contact:"
                    label-for="code"
                >
                    <b-input-group>
                        <b-form-input
                            id="code"
                            v-model="$store.state.search"
                            class="name-form-input"
                            v-on:keyup.enter="applyFilter"
                        />
                        <template #append>
                            <b-button
                                @click="applyFilter"
                                class="top-search-button"
                                ><b-icon-search
                            /></b-button>
                        </template>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row no-gutters>
            <b-table
                id="code-table"
                :style="{ 'max-width': maxWidth }"
                class="m-2 mb-4 xtable-h100 col-12"
                :per-page="perPage"
                :current-page="currentPage"
                :items="getCodes"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                responsive="sm"
                striped
                sort-icon-left
                sticky-header="calc(100vh - 380px)"
            >
                <template #cell(actions)="row" class="p-0">
                    <b-button
                        class="ml-1"
                        size="sm"
                        variant="info"
                        @click="openModalCode(row.item)"
                    >
                        <b-icon-eye />
                    </b-button>
                </template>
            </b-table>
        </b-row>
        <div class="d-flex flex-row justify-content-between flex-wrap mx-3">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
            ></b-pagination>
            <div>Total: {{ totalRows }}</div>
        </div>

        <b-modal
            centered
            hide-footer
            hide-header
            id="modalCode"
            title="modalTitle"
            hide-backdrop
            :content-class="
                'rack-status-modal shadow-lg rack-status-' +
                    $store.getters.account
            "
        >
            <b-button-close
                class="text-light m-3"
                @click="$bvModal.hide('modalCode')"
            />
            <code-status
                :rack="rack"
                :code="code"
                v-on:refresh="onPopupRefresh"
            ></code-status>
        </b-modal>
    </b-container>
</template>

<script>
import CodeApi from "@/apis/AdminCode.js";
import RackApi from "@/apis/AdminRack.js";
import CodeStatus from "./CodeStatus.vue";
export default {
    props: ["account"],
    components: {
        CodeStatus,
    },
    data() {
        return {
            code: {},
            codes: [],
            rack: {
                id: 0,
                name: "",
                lock: { id: 0, name: "", closed: false, locked: false },
                park: { name: "" },
            },
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id", sortable: true, label: "ID" },
                { key: "codeNumber", sortable: true, label: "Code" },
                { key: "contact", sortable: true, label: "Contact" },
                /* {
                    key: "isEmailContact",
                    label: "Contact type",
                    formatter: (value) => {
                        return value ? "Email" : "SMS";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                }, */
                {
                    key: "arrivalDate",
                    label: "Date d'arrivée",
                    sortable: true,
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                },
                {
                    key: "departureDate",
                    label: "Date de départ",
                    formatter: (value) => {
                        return this.$moment(value).format("DD/MM/YYYY HH:mm");
                    },
                    sortable: true,
                },
                /* {
                    key: "expirationDate",
                    label: "Date limite",
                    sortable: true,
                }, */
                {
                    key: "isRfid",
                    label: "Code type",
                    formatter: (value) => {
                        return value ? "RFID" : "Code";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                /* { key: "rfidDetails", sortable: true, label: "Détails RFID" },*/

                { key: "park.name", label: "Parc", sortable: false },
                { key: "rack.name", label: "Arceau", sortable: false },
                {
                    key: "isActive",
                    label: "Actif",
                    formatter: (value) => {
                        return value ? "Oui" : "Non";
                    },
                    sortable: true,
                    sortByFormatted: true,
                    filterByFormatted: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "table-actions-td",
                    thClass: "table-actions-th",
                },
            ],
            currentPage: 1,
            perPage: 10,
            totalRows: null,
            filter: { codeNumber: "", id: null },
            previousOrder: {},
            windowWidth: window.innerWidth,
            updating: 0,
        };
    },

    computed: {
        maxWidth() {
            return this.windowWidth > 575
                ? `${this.windowWidth - 262}px`
                : "100%";
        },
    },
    async mounted() {
        this.$root.$on("search", () => {
            this.applyFilter();
        });
        window.addEventListener("resize", this.widthHandler);
    },
    methods: {
        applyFilter() {
            this.$root.$emit("bv::refresh::table", "code-table");
        },
        async getCodes(ctx) {
            //console.log(ctx);
            try {
                const codeId = this.code ? this.code.id : null;
                let filter = {};
                if (this.$store.state.search)
                    filter.search = this.$store.state.search;
                if (this.filter.id) filter.id = this.filter.id;
                let order = {};
                order[ctx.sortBy] = ctx.sortDesc ? -1 : 1;
                if (ctx.sortBy == "") order = this.previousOrder;
                else this.previousOrder = order;
                const codes = await CodeApi.findCodes(
                    { limit: ctx.perPage, page: ctx.currentPage },
                    {
                        where: filter,
                        order,
                    }
                );
                if (codes.status === 201) {
                    this.codes = codes.data.items;
                    this.totalRows = codes.data.meta.totalItems;
                    this.currentPage = codes.data.meta.currentPage;
                    if (codeId)
                        this.code = this.codes.filter((c) => c.id == codeId)[0];
                    return codes.data.items;
                } else {
                    this.toast("Erreur!", codes.statusText, true);
                }
            } catch (err) {
                this.toast("Erreur!", err.message, true);
            }
        },
        toast(title, message, faulty = false) {
            this.$root.$bvToast.toast(message, {
                title: title,
                toaster: "b-toaster-top-center",
                variant: faulty ? "danger" : "success",
                appendToast: true,
            });
        },
        async openModalCode(code) {
            //console.log(code);
            this.code = code;
            const rack = await RackApi.getCodeRack(code.id);
            this.rack = rack.data;
            //console.log(rack);
            this.$bvModal.show("modalCode");
        },
        async onPopupRefresh() {
            this.applyFilter();
            const rack = await RackApi.getCodeRack(this.code.id);
            this.rack = rack.data;
        },
        widthHandler() {
            this.windowWidth = window.innerWidth;
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.widthHandler);
    },
};
</script>
